import {
  GET_FAVORITES_SUCCESS,
  GET_FAVORITES_IDS_SUCCESS
} from "./favorites.actions";
import initialState from "../../store/initialState";

export default (state = initialState.favorites, action) => {
  switch (action.type) {
    case GET_FAVORITES_SUCCESS:
      return {
        ...state,
        favouritesDetails: { ...action.payload }
      };
    case GET_FAVORITES_IDS_SUCCESS:
      return {
        ...state,
        favouritesIDs: [...action.payload.favouritesIDs]
      };
    default:
      return state;
  }
};

import React, { useState } from "react";
import { useSelector } from "react-redux";

import Styled from "./Styled";
import Peer2PeerSearch from "./Peer2peer.Search";
import Peer2PeerNewContent from "./Peer2peer.NewContent";
import Peer2PeerTags from "./Peer2peer.Tags";
import Peer2PeerPosts from "./Peer2peer.Posts/Peer2peer.Posts";
import { postsPendingCountSelector } from "../../services/peer2peer/peer2peer.selector";

const Peer2Peer = () => {
  const [selectedInterests, selectInterest] = useState([]);
  const pendingCount = useSelector(postsPendingCountSelector);
  return (
    <Styled.Page>
      <Styled.Main>
        <Peer2PeerSearch
          selectedInterests={selectedInterests}
          selectInterest={selectInterest}
        />
        <Peer2PeerPosts
          selectedInterests={selectedInterests}
          selectInterest={selectInterest}
        />
      </Styled.Main>
      <Styled.Aside>
        <Peer2PeerNewContent />
        {!!pendingCount && (
          <Styled.PostStatus>
            Your {pendingCount} post{pendingCount > 1 ? "s" : ""} on approving
            stage
          </Styled.PostStatus>
        )}
        <Peer2PeerTags
          selectedInterests={selectedInterests}
          selectInterest={selectInterest}
        />
      </Styled.Aside>
    </Styled.Page>
  );
};

export default Peer2Peer;

import { call, put, takeEvery } from "redux-saga/effects";
import { history, urlLocations } from "../../routes/urlLocation";
import { registrationRequest } from "./registration.api";
import {
  REGISTRATION,
  REGISTRATION_FAILURE,
  REGISTRATION_SUCCESS,
} from "./registration.actions";
import openNotification, {
  prepareErrorMessages,
} from "../../utils/notifications";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* registration({ payload }) {
  try {
    yield put(startLoaderAction());

    yield call(registrationRequest, { ...payload, username: payload.email });
    history.push(urlLocations.login);
    yield call(openNotification, {
      type: "success",
      message: "Success",
      description:
        "We have sent you a confirmation email.\n Please check your inbox",
    });
    yield put({ type: REGISTRATION_SUCCESS });
  } catch ({ response }) {
    yield put({
      type: REGISTRATION_FAILURE,
    });
    yield call(openNotification, {
      type: "error",
      message: "Error",
      description: prepareErrorMessages(response),
    });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* registrationSaga() {
  yield takeEvery(REGISTRATION, registration);
}

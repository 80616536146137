import React, { useState } from "react";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import {
  FileOutlined,
  UploadOutlined,
  HeartOutlined,
  HeartFilled,
  MessageOutlined,
  DownOutlined,
  SendOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Popover } from "antd";

import { ATTACHMENT_TYPES } from "./Peer2peer.Posts.constants";
import Styled from "../Styled";
import AvatarImg from "../../../assets/img/avatar.png";
import {
  togglePostLikeAction,
  addPostCommentAction,
} from "../../../services/peer2peer/peer2peer.actions";
import { getUserInfoSelector } from "../../../services/user/user.selector";

const TYPE_TO_VIEW = {
  video: (attachment) => (
    <video controls>
      <source src={attachment?.url} />
    </video>
  ),
  photo: (attachment) => <img src={attachment?.url} alt="" />,
  document: (attachment) => (
    <Styled.PostCardAttachmentFile>
      <Styled.PostCardAttachmentFileInfo>
        <Styled.PostCardAttachmentFileIcon>
          <FileOutlined />
        </Styled.PostCardAttachmentFileIcon>
        <Styled.PostCardAttachmentFileNames>
          <Styled.PostCardAttachmentFileName>
            {attachment?.name}
          </Styled.PostCardAttachmentFileName>
          <Styled.PostCardAttachmentFileExt>
            {attachment?.ext.substring(1)?.toUpperCase()}
          </Styled.PostCardAttachmentFileExt>
        </Styled.PostCardAttachmentFileNames>
      </Styled.PostCardAttachmentFileInfo>
      <Styled.PostCardAttachmentUpload target="_blank" href={attachment?.url}>
        <UploadOutlined />
      </Styled.PostCardAttachmentUpload>
    </Styled.PostCardAttachmentFile>
  ),
};

const renderAttachment = (attachment) => {
  if (ATTACHMENT_TYPES.video.includes(attachment?.mime)) {
    return TYPE_TO_VIEW.video(attachment);
  }
  if (ATTACHMENT_TYPES.photo.includes(attachment?.mime)) {
    return TYPE_TO_VIEW.photo(attachment);
  }
  return TYPE_TO_VIEW.document(attachment);
};

const RenderLikeAuthors = ({ likes }) => {
  return (
    <Styled.PostCardLikeAuthorList>
      {likes?.map((like) => {
        return (
          <Styled.PostCardLikeAuthorItem key={like?.id}>
            <Styled.PostCardLikeAuthorImg>
              <img src={like?.profilePic} alt="" />
            </Styled.PostCardLikeAuthorImg>
            <Styled.PostCardLikeAuthorName>
              {like?.firstName} {like?.lastName}
            </Styled.PostCardLikeAuthorName>
          </Styled.PostCardLikeAuthorItem>
        );
      })}
    </Styled.PostCardLikeAuthorList>
  );
};

const Peer2PeerPost = ({
  author,
  published_at,
  content,
  attachment,
  interest,
  likes,
  id,
  comments,
  selectInterest,
  selectedInterests,
  externalLink,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserInfoSelector);
  const isPostLiked = likes?.find((like) => like?.id === user?.id);
  const [message, setMessage] = useState("");
  const [isCommentsOpen, toggleComments] = useState(false);

  const handleTagClick = (id) => {
    if (selectedInterests.includes(id)) {
      const newIds = selectedInterests.filter((elId) => elId !== id);
      selectInterest(newIds);
      return;
    }
    selectInterest([...selectedInterests, id]);
  };

  return (
    <Styled.PostCard>
      <Styled.PostCardHeader>
        <Styled.PostCardAuthor>
          <Styled.PostCardAvatar>
            <img src={author?.profilePic || AvatarImg} alt="" />
          </Styled.PostCardAvatar>
          <Styled.AuthorInfo>
            <Styled.PostCardAuthorName>
              {author?.firstName}
              {"  "}
              {author?.lastName}
            </Styled.PostCardAuthorName>
            <Styled.PostCardHospital>
              {author?.hospital}
            </Styled.PostCardHospital>
          </Styled.AuthorInfo>
        </Styled.PostCardAuthor>
        <Styled.PostCardHeaderInfo>
          <Styled.PostCardHeaderDate>
            {moment(published_at).format("D MMMM YYYY")}
          </Styled.PostCardHeaderDate>
        </Styled.PostCardHeaderInfo>
      </Styled.PostCardHeader>
      <Styled.PostCardContent>{content}</Styled.PostCardContent>
      <Styled.PostCardAttachment>
        {!isEmpty(attachment?.[0]) && renderAttachment(attachment?.[0])}
      </Styled.PostCardAttachment>
      <Styled.PostCardTagRow>
        <Styled.Tag
          onClick={() => handleTagClick(interest?.id)}
          color={interest?.color}
        >
          {interest?.Name}
        </Styled.Tag>
        {externalLink && (
          <Styled.PostCardExternalLinkBtn target="_blank" href={externalLink}>
            Explore more <RightOutlined />
          </Styled.PostCardExternalLinkBtn>
        )}
      </Styled.PostCardTagRow>
      <Styled.PostCardFooter>
        <Styled.PostCardLikes>
          <Styled.PostCardLikeIcon
            onClick={() =>
              dispatch(
                togglePostLikeAction({
                  type: isPostLiked ? "unlike" : "like",
                  id,
                })
              )
            }
          >
            {isPostLiked ? <HeartFilled /> : <HeartOutlined />}
          </Styled.PostCardLikeIcon>
          <Styled.PostCardLikeCount>
            {likes?.length} Like{likes?.length === 1 ? "" : "s"}
          </Styled.PostCardLikeCount>
          <Popover
            content={<RenderLikeAuthors likes={likes} />}
            placement="bottom"
            trigger="click"
          >
            <Styled.PostCardLikeAuthors>
              {likes
                ?.filter((month, idx) => idx < 2)
                ?.map((like) => (
                  <Styled.PostCardLikeAuthor key={like.id}>
                    <img src={like?.profilePic || AvatarImg} alt="" />
                  </Styled.PostCardLikeAuthor>
                ))}
            </Styled.PostCardLikeAuthors>
          </Popover>
        </Styled.PostCardLikes>
        {!!comments?.length && (
          <Styled.PostCardCommentToggle
            onClick={() => toggleComments(!isCommentsOpen)}
          >
            <MessageOutlined />{" "}
            <span>
              {isCommentsOpen ? "Close" : "Show"} all comments (
              {comments?.length}){" "}
            </span>
            {isCommentsOpen ? <UpOutlined /> : <DownOutlined />}
          </Styled.PostCardCommentToggle>
        )}
      </Styled.PostCardFooter>
      <Styled.HR />
      <Styled.PostCardCommentsList isOpen={isCommentsOpen}>
        {comments?.map((item) => (
          <Styled.PostCardCommentsItem key={item?.id}>
            <Styled.PostCardCommentsAvatar>
              <img src={item?.commenter?.profilePic || AvatarImg} alt="" />
            </Styled.PostCardCommentsAvatar>
            <Styled.PostCardCommentsInfo>
              <Styled.PostCardCommentsName>
                {item?.commenter?.firstName} {item?.commenter?.lastName}
              </Styled.PostCardCommentsName>
              <Styled.PostCardCommentsContent>
                {item?.comment}
              </Styled.PostCardCommentsContent>
            </Styled.PostCardCommentsInfo>
            <Styled.PostCardCommentsDate>
              {moment(item?.published_at).format("D MMMM YYYY")}
            </Styled.PostCardCommentsDate>
          </Styled.PostCardCommentsItem>
        ))}
      </Styled.PostCardCommentsList>
      <Styled.PostCardCommentForm>
        <Styled.PostCardCommentAvatar>
          <img src={user?.profilePic?.url || AvatarImg} alt="" />
        </Styled.PostCardCommentAvatar>
        <Styled.PostCardCommentInput
          placeholder="Write your comment"
          onChange={(event) => setMessage(event.target.value)}
          value={message}
        />
        <Styled.PostCardCommentButton
          onClick={() => {
            dispatch(
              addPostCommentAction({
                comment: message,
                id,
              })
            );
            setMessage("");
          }}
        >
          <SendOutlined />
        </Styled.PostCardCommentButton>
      </Styled.PostCardCommentForm>
    </Styled.PostCard>
  );
};

export default Peer2PeerPost;

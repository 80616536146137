import styled from "styled-components";
import { DEVICE } from "../../../../constants/media";
import { isMobileOnly } from "react-device-detect";

const StyledForget = styled.div`
  font-family: var(--AvenirBook);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.3px;
  text-align: left;
  color: var(--white);

  a {
    color: var(--white);
    opacity: 0.9;
    text-decoration: underline;

    &:hover {
      opacity: 0.9;
      color: var(--white);
      text-decoration: underline;
    }

    @media ${DEVICE.mobileTabletDevices} {
      color: ${isMobileOnly ? "#0091ff;" : "#fff;"};
      margin-bottom: ${isMobileOnly ? "0" : "70px;"};
      margin-top: ${isMobileOnly ? "0" : "18px;"};
      font-size: ${isMobileOnly ? "12px" : "14px;"};
      width: 100%;
    }
  }
`;
export default StyledForget;

import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledSessions = styled.div`

    h3 {
      padding-top: 50px;
      font-family: var(--fontAvenirMedium);
	  font-size: 16px;
	  font-weight: 500;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.39;
	  letter-spacing: -0.1px;
	  text-align: left;
	  color: var(--titleCards);
	  margin-bottom: 30px;
	  
	  @media ${DEVICE.laptop} {
          font-size: 18px;
	  }
    }
`
export default StyledSessions;
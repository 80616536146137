import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const P = styled.p`
  font-family: var(--fontAvenirHeavy);
  font-size: 16px;
  font-weight: 900;
  line-height: 1.39;
  letter-spacing: -0.1px;
  text-align: left;
  color: var(--lightGrey);
  padding-bottom: 19px; 
  
  @media ${DEVICE.tablet} {
     font-size: 17px; 
  }  
`;

const H3 = styled.h3`
  font-family: var(--fontAvenirMedium);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.35;
  text-align: left;
  color: var(--primaryGrey);
  margin-bottom: 47px;
  
  @media ${DEVICE.tablet} {
     font-size: 20px;
      margin-bottom: 40px;
  }
`;


export default {
    P,
    H3,
};
import styled from 'styled-components'
import { DEVICE } from '../../constants/media';
import { isMobileOnly, isMobile } from "react-device-detect";


const StyledResultNav = styled.div`
 	 display: flex;
 	 align-items: center;
 	 margin-top: 35px;
 	 justify-content: center;
 	 
 	 .ant-btn {
 	     width:${ isMobileOnly && '100%;' }; 
 	     margin-right: ${ !isMobile && '50px;' }; 
 	 }
 	 
 	 @media ${DEVICE.tablet} {
       justify-content: flex-start;
     }
     
 	 a {
 	      font-family: var(--fontAvenirHeavy);
		  font-size: 16px;
		  font-weight: 900;
		  line-height: 1.38;
		  letter-spacing: -0.09px;
	      color: var(--brightBlue);
	      display: block;
	      width: 100%;
	      
	      @media ${DEVICE.mobileTabletDevices} {
             position: absolute;
             width: 100%;
             text-align: center;
		     left: 0;
		     bottom: 80px;
		     
          }
          
          @media (max-width: 768px) and (orientation:landscape) {
		     bottom: 30px;  
          }
         
 	 }
`
export default StyledResultNav;
import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledWebinarPage = styled.div`
	padding: 30px 20px 0 20px; 
	
	@media ${DEVICE.laptop} {
	   padding: 100px 60px 0 90px; 
	}
	h3 {
	  font-family: var(--fontAvenirHeavy);
	  font-size: 20px;
	  font-weight: 900;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.37;
	  letter-spacing: -0.17px;
	  text-align: left;
	  color: var(--titleCards);
	  padding-bottom: 15px;
	  padding-right: 44px;
	  
	  @media ${DEVICE.tablet} {
		   font-size: 25px;
		   padding-right: 50px;
	  }
	  
	  @media ${DEVICE.laptop} {
		   padding-right: 100px;
		   font-size: 30px;
	  }
	}
`
export default StyledWebinarPage;
import styled from "styled-components";
import { DEVICE } from '../../../constants/media';
import { isTablet } from "react-device-detect";

const Buttons = styled.div`
  display: flex; 
  @media ${DEVICE.mobileTabletDevices} {
      flex-wrap: wrap;
      justify-content: ${ isTablet ?  'space-between': 'flex-start'};
  }
  
  
`;

const P = styled.p`
  margin-bottom: 15px;
`
const Img = styled.img`
  width: 90px;
  margin-left: 15px;
`

export default {
	Buttons,
    Img,
    P
}
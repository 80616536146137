import { createAction } from "redux-actions";

export const GET_FAVORITES = "GET_FAVORITES";
export const GET_FAVORITES_SUCCESS = "GET_FAVORITES_SUCCESS";
export const GET_FAVORITES_FAILURE = "GET_FAVORITES_FAILURE";

export const GET_FAVORITES_IDS = "GET_FAVORITES_IDS";
export const GET_FAVORITES_IDS_SUCCESS = "GET_FAVORITES_IDS_SUCCESS";
export const GET_FAVORITES_IDS_FAILURE = "GET_FAVORITES_IDS_FAILURE";

export const ADD_FAVORITES = "ADD_FAVORITES";
export const ADD_FAVORITES_SUCCESS = "ADD_FAVORITES_SUCCESS";
export const ADD_FAVORITES_FAILURE = "ADD_FAVORITES_FAILURE";

export const REMOVE_FAVORITES = "REMOVE_FAVORITES";
export const REMOVE_FAVORITES_SUCCESS = "REMOVE_FAVORITES_SUCCESS";
export const REMOVE_FAVORITES_FAILURE = "REMOVE_FAVORITES_FAILURE";

export const getFavoritesAction = createAction(GET_FAVORITES);
export const getFavoritesIdsAction = createAction(GET_FAVORITES_IDS);
export const addFavoriteAction = createAction(ADD_FAVORITES);
export const removeFavoriteAction = createAction(REMOVE_FAVORITES);

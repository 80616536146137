import React from 'react';

import Catalogue from './styled/styled'
import CatalogueList from "./CataloguePage.List/CataloguePage.List";

const CataloguePage = () => {
    return  (
        <Catalogue.Page>
            <CatalogueList/>
        </Catalogue.Page>
    )
};

export default CataloguePage;
import React from 'react';

import Styled from './ClaimPoints.Styled'
import ClaimPointsForm from "./ClaimPoints.Form";

const ClaimPoints = ({ setPopupStatus }) => <>
    <Styled.Title>Claim CME points</Styled.Title>
    <Styled.Description>
        You need to watch the webinars and pass the post webinar
        test from “Test Your Knowledge” Section in order to be
        eligible for CME Points
    </Styled.Description>
    <ClaimPointsForm setPopupStatus={setPopupStatus} />
</>;


export default ClaimPoints
import { put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  GET_EXAMS_SUCCESS,
  GET_EXAMS,
  GET_EXAMS_FAILURE,
  SET_EXAM,
  SET_EXAM_SUCCESS,
  SET_EXAM_FAILURE,
} from "./exams.action";
import { GET_USER_DATA } from "../user/user.action";
import { history, urlLocations } from "../../routes/urlLocation";
import { isUrlMatch } from "../router/router.utils";
import { examsRequest, setExamRequest } from "./exams.api";
import { EXAM_TYPE } from "../../constants";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* getExamsOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.exams)) {
      const { search } = payload.location;
      const params = Object.fromEntries(new URLSearchParams(search));
      yield put({
        type: GET_EXAMS,
        ...( params && { payload: { params } } )
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchExams({ payload }) {
  try {
    yield put(startLoaderAction());
    const { data, status } = yield examsRequest({ params: payload.params });
    if (status === 200) {
      yield put({
        type: GET_EXAMS_SUCCESS,
        payload: { ...data },
      });
    }
  } catch (error) {
    yield put({ type: GET_EXAMS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* setExams({ payload }) {
  const { examType, ...res } = payload;
  try {
    yield put(startLoaderAction());
    const { data, status } = yield setExamRequest(res);
    if (status === 200) {
      yield put({ type: SET_EXAM_SUCCESS, payload: data });
      data.isPassed &&
        examType === EXAM_TYPE.POST_WEBINAR &&
        history.push(urlLocations.examSuccess);

      !data.isPassed &&
        examType === EXAM_TYPE.POST_WEBINAR &&
        history.push(urlLocations.examFail);

      examType === EXAM_TYPE.PRE_WEBINAR &&
        history.push(urlLocations.examResult);

      yield put({ type: GET_USER_DATA });
    } else {
      yield put({ type: SET_EXAM_FAILURE });
    }
  } catch (error) {
    yield put({ type: SET_EXAM_FAILURE });

    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* examsSaga() {
  yield takeEvery(LOCATION_CHANGE, getExamsOnLocationChange);
  yield takeEvery(GET_EXAMS, fetchExams);
  yield takeEvery(SET_EXAM, setExams);
}

import styled from "styled-components";

const Col = styled.div`
  max-width: 300px;
  margin: 25px;
  display: inline-block;
`;

const Card = styled.a`
  display: block;
  width: 300px;
  height: 200px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    -webkit-box-shadow: 3px 3px 12px 0px rgba(50, 50, 50, 0.4);
    -moz-box-shadow: 3px 3px 12px 0px rgba(50, 50, 50, 0.4);
    box-shadow: 3px 3px 12px 0px rgba(50, 50, 50, 0.4);
  }
`;

const Title = styled.div`
  margin-top: 15px;
  font-weight: var(--fontAvenirHeavy);
  text-align: center;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  color: var(--titleCards);
`;

export default {
  Card,
  Col,
  Title
};

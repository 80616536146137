import React from "react";
import TextTruncate from "react-text-truncate";

import { urlLocations } from "../../../routes/urlLocation";
import SpeakerBlock from "../../../components/SpeakerBlock/SpeakerBlock";
import StyledDateBlock from "./styled/StyledDateBlock";
import {
  getDay,
  getMonthAndYear,
  getDayOfWeek,
} from "../../../services/webinars/webinars.utils";
import StyledWebinarItem from "./styled/StyledWebinarItem";

const Webinar = ({
  webinar: {
    id,
    title,
    startTime,
    speakers,
    cmeExpirations,
    interest: { color },
  },
  webinar,
}) => (
  <StyledWebinarItem to={`${urlLocations.webinars}/${id}`}>
    <StyledDateBlock color={color}>
      <p>{getDay(startTime)}</p>
      <div>
        <h4>{getMonthAndYear(startTime)}</h4>
        <span>{getDayOfWeek(startTime)}</span>
      </div>
    </StyledDateBlock>

    <h5>
      <TextTruncate line={4} truncateText="…" text={title} />
    </h5>

    <SpeakerBlock name={speakers[0].name} avatar={speakers[0].avatar.url} />
  </StyledWebinarItem>
);

export default Webinar;

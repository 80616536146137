import React from 'react';

import PopupWrapper from "../../hoc/Modal/withPopup";
import ClaimPoints from "./ClaimPoints";
import { POPUPS_ID } from "../../constants";

const ClaimPointsPopup = () => <>
    <PopupWrapper
        component={
            ({ setPopupStatus }) =>
                (<ClaimPoints setPopupStatus={setPopupStatus} />)
        }
        popupId={POPUPS_ID.CLAIM_POINTS_POPUP}
    />
</>;


export default ClaimPointsPopup;
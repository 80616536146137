import React from 'react';

import PopupWrapper from "../../../hoc/Modal/withPopup";
import { POPUPS_ID } from "../../../constants";
import ExpertPopupForm from './ExpertPage.PopupForm';

const ExpertPopup = ({ expertId }) => (
    <PopupWrapper
        destroyOnClose
        popupId={POPUPS_ID.EXPERT_POPUP}
        component={ () => <ExpertPopupForm expertId={expertId} /> }
    />
);

export default ExpertPopup;
import styled from "styled-components";
import { DEVICE } from "../../../constants/media";

const StyledExpertPreview = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: center;

  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    border: solid 4px ${({ color }) => color};
    margin-right: 22px;
    @media ${DEVICE.laptopL} {
      width: 165px;
      height: 165px;
      margin-right: 42px;
      border: solid 8px ${({ color }) => color};
    }
  }
  h4 {
    font-family: var(--fontAvenirHeavy);
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: -0.08px;
    text-align: left;
    color: var(--titleBlue);
    padding-bottom: 5px;

    @media ${DEVICE.laptopL} {
      font-size: 30px;
      letter-spacing: -0.17px;
    }
  }
  h5 {
    font-family: var(--fontAvenirHeavy);
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: -0.17px;
    text-align: left;
    color: var(--titleBlue);

    @media ${DEVICE.laptopL} {
      font-size: 20px;
    }
  }
`;

export default StyledExpertPreview;

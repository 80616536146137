import React, { useRef } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import AddToCalendar from "react-add-to-calendar";
import moment from "moment";

import Sessions from "../../components/Sessions/Sessions";
import StyledWebinarPage from "./styled/StyledWebinarPage";
import StyledWebinarDate from "../styled/StyledWebinarDate";
import ExpertPreview from "../../components/ExpertPreview/ExpertPreview";
import StyledWebinarButtons from "./styled/StyledWebinarButtons";
import StyledAddCalendarBtn from "./styled/StyledAddCalendarBtn";
import StyledLiveNowBtn from "./styled/StyledLiveNowBtn";
import StyledCounter from "./styled/StyledCounter";
import StyledWebinarDescription from "./styled/StyledWebinarDescription";
import StyledWebinarSpeakers from "./styled/StyledWebinarSpeakers";
import StyledWebinarHeader from "./styled/StyledWebinarHeader";
import LiveNowIcon from "../../assets/img/livenow-icon.svg";
import CalendarIcon from "../../assets/img/addtocalendar-icon.svg";
import ClockIcon from "../../assets/img/clock-icon.svg";
import { webinarSelector } from "../../services/webinars/webinars.selector";
import {
  getWebinareFormatedDate,
  isWebinarPast,
  createWebinarEndDate,
  isWebinarLive,
} from "../../services/webinars/webinars.utils";
import { isMobileOnly } from "react-device-detect";
import StyledWebinarStream from "./styled/StyledWebinarStream";
import { getUserInfoSelector } from "../../services/user/user.selector";

const WebinarPage = ({
  profile,
  webinare: {
    title,
    startTime,
    durationMins,
    speakers,
    desc,
    sessions,
    interest: { color },
    streamingURL,
    registrationURL,
    youtubeStreamingUrl,
    cmeExpirations,
  } = {
    title: "",
    durationMins: 0,
    speakers: [],
    desc: "",
    sessions: [],
    interest: { color: "" },
    streamingURL: null,
    registrationURL: null,
    youtubeStreamingUrl: null,
    cmeExpirations: [],
  },
}) => {
  const calendarRef = useRef(null);
  const cmeExpiration = cmeExpirations.find(
    ({ country }) => country?.id === profile?.country?.id
  );

  const [duration, setDuration] = React.useState(null);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentTimeStamp = moment(Date.now()).unix();
      const eventTimeStamp = moment(cmeExpiration?.expirationDate).unix();
      const diff = eventTimeStamp - currentTimeStamp;
      const d = moment.duration(diff * 1000, "milliseconds");
      const nDate = moment.duration(d - 1000, "milliseconds");
      setDuration(nDate);
    }, 1000);
    return () => clearInterval(interval);
  }, [cmeExpiration, duration]);

  return (
    <StyledWebinarPage>
      <div>
        <StyledWebinarHeader>
          <div>
            <h3>{title}</h3>
            {!!cmeExpiration && (
              <StyledCounter>
                CME points accreditation for this webinar will end on{" "}
                {duration?.days()} days, {duration?.hours()} hours{" "}
                {duration?.minutes()} minutes {duration?.seconds()} seconds
              </StyledCounter>
            )}
            <StyledWebinarDate>
              <i>
                <img src={ClockIcon} alt="" />
              </i>
              {getWebinareFormatedDate(startTime)}
            </StyledWebinarDate>
          </div>

          <div>
            {!isWebinarPast({ startTime, durationMins }) && (
              <StyledWebinarButtons>
                <StyledAddCalendarBtn
                  styledtype="add"
                  onClick={() => {
                    calendarRef.current.toggleCalendarDropdown();
                  }}
                  icon={
                    <i>
                      <img src={CalendarIcon} alt="" />
                    </i>
                  }
                >
                  <AddToCalendar
                    onClick={(event) => event.preventDefault()}
                    ref={calendarRef}
                    buttonLabel={isMobileOnly ? " " : "Add to My Calendar"}
                    event={{
                      title,
                      description: desc,
                      startTime,
                      location: "",
                      endTime: createWebinarEndDate({
                        startTime,
                        durationMins,
                      }),
                    }}
                  />
                </StyledAddCalendarBtn>

                {registrationURL && !isMobileOnly && (
                  <StyledAddCalendarBtn
                    styledtype="register"
                    href={registrationURL}
                    target={"_blank"}
                    as={"a"}
                  >
                    Registration
                  </StyledAddCalendarBtn>
                )}
                <StyledLiveNowBtn
                  href={streamingURL}
                  target={"_blank"}
                  disabled={!isWebinarLive({ startTime, durationMins })}
                  icon={
                    <i>
                      <img src={LiveNowIcon} alt="" />
                    </i>
                  }
                >
                  {!isMobileOnly && "Live Now"}
                </StyledLiveNowBtn>
              </StyledWebinarButtons>
            )}
          </div>
        </StyledWebinarHeader>
        {isWebinarLive({ startTime, durationMins }) && youtubeStreamingUrl && (
          <StyledWebinarStream>
            <iframe
              src={youtubeStreamingUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </StyledWebinarStream>
        )}
        <StyledWebinarSpeakers.Row>
          {Object.values(speakers)?.map((speaker) => (
            <StyledWebinarSpeakers.Col key={speaker?.id}>
              <ExpertPreview
                color={color}
                src={speaker?.avatar?.url}
                name={speaker?.name}
                title={speaker?.title}
              />
              <StyledWebinarDescription>
                <p>{speaker?.bio}</p>
              </StyledWebinarDescription>
            </StyledWebinarSpeakers.Col>
          ))}
        </StyledWebinarSpeakers.Row>

        {registrationURL && isMobileOnly && (
          <StyledAddCalendarBtn
            styledtype="register"
            href={registrationURL}
            target={"_blank"}
            as={"a"}
          >
            Registration
          </StyledAddCalendarBtn>
        )}
      </div>
      <Sessions
        sessions={sessions}
        title={"Session Recordings"}
        videoKey={null}
        sessionIdKey={"id"}
      />
    </StyledWebinarPage>
  );
};

export default connect(
  createStructuredSelector({
    webinare: webinarSelector,
    profile: getUserInfoSelector,
  })
)(WebinarPage);

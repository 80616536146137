import { SET_FILE, FILE_UPLOAD_SUCCESS, CLEAR_FILES } from "./file.action";
import initialState from "../../store/initialState";

export default (state = initialState.files, { type, payload }) => {
  switch (type) {
    case SET_FILE:
    case FILE_UPLOAD_SUCCESS:
      return {
        [payload.uploaderName]: payload.file,
      };
    case CLEAR_FILES:
      return state;
    default:
      return state;
  }
};

import styled from "styled-components";
import { DEVICE } from "../../../../constants/media";
import { isTablet } from "react-device-detect";

const StyledMainPage = styled.div`
  background-image: var(--gradientHomePage);
  min-height: 100vh;

  @media ${DEVICE.mobileDevices} {
    .ant-form-item {
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }

  @media ${DEVICE.mobileDevices} {
    margin-top: 20px;
    padding: 50px 20px 45px 20px;
    max-width: 100%;
    flex: 0 0 100%;
    min-height: 540px;
    border-radius: 40px 40px 0 0;
  }

  @media ${DEVICE.tablet} {
    padding: 150px 20px 45px 20px;
    min-height: 100vh;
  }

  @media ${DEVICE.laptop} {
    padding: 180px 63px 45px 63px;
  }

  h1 {
    font-family: var(--fontAvenirHeavy);
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: var(--white);
    padding-bottom: 50px;

    @media ${DEVICE.tablet} {
      font-size: 30px;
    }

    @media ${DEVICE.laptopL} {
      font-size: 44px;
    }

    @media ${DEVICE.mobileDevices} {
      font-size: 24px;
      padding-bottom: 32px;
    }
  }
  h3 {
    font-family: var(--fontAvenirBook);
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: var(--white);
    padding-bottom: 42px;
    max-width: 540px;
  }
  p {
    font-family: var(--fontAvenirBook);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: var(--greyMainText);
    max-width: 540px;
  }
  form {
    max-width: 540px;

    .ant-btn {
      @media ${DEVICE.mobileTabletDevices} {
        width: ${isTablet ? "350px" : "100%"};
      }
    }
  }
`;
export default StyledMainPage;

import React from "react";
import Input from "../Input/Input";
import Styled from "./RenderField.Styled";

const RenderField = ({ name, placeholder, type, input, meta, ...res }) => (
  <>
    <Input
      name={name}
      placeholder={placeholder}
      type={type}
      {...input}
      {...res}
    />
    {meta?.touched && meta?.error && (
      <Styled.ErrorMessage>{meta?.error}</Styled.ErrorMessage>
    )}
  </>
);

export default RenderField;

import axios from "axios";
import { API_PATH } from "../constants";

const instance = axios.create({ baseURL: API_PATH });
const storage = localStorage.getItem("storageTyp");

instance.defaults.headers.common["Authorization"] = window[storage]?.getItem(
  "jwt"
)
  ? `Bearer ${window[storage].getItem("jwt")}`
  : "";

export default instance;

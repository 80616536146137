export const mediaFilesSupport = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "video/mp4",
  "video/webm",
  "video/x-msvideo",
];

export const documentsFilesSupport = [
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const isFormatSupported = (type, supportedTypes) => {
  return supportedTypes.includes(type);
};

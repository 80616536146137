import styled from 'styled-components';
import { Checkbox } from 'antd';
import { DEVICE } from '../../../constants/media';

export default styled(Checkbox)`
    &&& {
            width: 100%;
            font-size: 14px;
            font-family: var(--fontAvenirHeavy);
            
           
			font-weight: 900;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: -0.3px;
			text-align: left;
			color: var(--white);
			padding-left: 2px;
			
			@media ${DEVICE.mobileDevices} {
                font-size: 12px;
	            font-family: var(--fontAvenirBook);
	            
	            .ant-checkbox + span {
				    padding-right: 8px;
				    padding-left: 0;
				    position: relative;
				    top: -4px;
				}
            }
		
		&:hover {
		  .ant-checkbox-checked {
		    &:after{
		      border: 0;
		    }
		  }
		}
			 	
        .ant-checkbox {
            width: 20px;
	        height: 20px;
	        position: absolute;
		    right: 0;
		    top: 0;  
		     
		     &.ant-checkbox-checked .ant-checkbox-inner::after {
	            opacity: 1;
             }
        }
        
      .ant-checkbox-input {
	        width: 100%;
	        height: 100%;
	        position: absolute;
	        right: 0;
	        top: 0;
	        opacity: 0 !important;        
      }
      
      .ant-checkbox-inner {
	      width: 21px;
	        height: 21px;
	        background: transparent;
	        border: 2px solid var(--white);
	        border-radius: 50%;
	        position: relative;
	        
	        &:after {
		        position: absolute;
		        content: '';
	            width: 11px;
			    height: 11px;
			    border-radius: 50%;
			    top: 3px;
			    border: none;
			    transform: none;
			    left: 3px;
			    opacity: 0;
	            background: var(--white);
	        }
	  }  
    }
`

import styled from 'styled-components'
import { DEVICE } from '../../constants/media';

const StyledWebinarDate = styled.div`
	margin-bottom: 65px;
	display: flex;
	align-items: center;
	font-size: 14px;
	@media ${DEVICE.laptop} {
	    font-size: 16px;
	    margin-bottom: 70px;
	}
	i {
		margin-right: 15px;
		
		@media ${DEVICE.laptop} {
	        margin-right: 20px;
		}
	}
`
export default StyledWebinarDate;
import React, { useState } from "react";
import { reduxForm, Field } from "redux-form";
import { Form, Select, Checkbox } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import InputField from "../../components/RenderField/RenderField";
import SelectField from "../../components/RenderSelect/RenderSelect";
import Button from "../../components/Button/Button";
import PhoneField from "../../components/RenderPhoneField/RenderPhoneField";
import { registrationAction } from "../../services/registration/registration.actions";
import { requiredFieldsValidate } from "../../utils/validate";
import { countriesSelector } from "../../services/countries/countries.selectors";
import AvatarUploader from "../../components/AvatarUploader/AvatarUploader";
import Styled from "./Styled";
import PasswordIconSvg from "../../assets/img/icon_password.svg";
import EmailIconSvg from "../../assets/img/icon_mail.svg";
import { urlLocations } from "../../routes/urlLocation";
import { specialitiesSelector } from "../../services/specialities/specialities.selector";

const { Option } = Select;

const requiredFields = [
  "firstName",
  "lastName",
  "password",
  "hospital",
  "specialityNew",
  "country",
  "city",
  "email",
  "mobile",
];

const RegistrationPageForm = ({ handleSubmit }) => {
  const countries = useSelector(countriesSelector);
  const specialities = useSelector(specialitiesSelector);
  const [agreement, setAgreement] = useState(false);
  return (
    <>
      <AvatarUploader uploaderName="registration" />
      <form onSubmit={handleSubmit}>
        <Styled.FormWrap>
          <Form.Item>
            <Field
              name="firstName"
              placeholder="First name"
              label="First mame"
              component={InputField}
            />
          </Form.Item>
          <Form.Item>
            <Field
              name="lastName"
              placeholder="Last name"
              label="Last name"
              component={InputField}
            />
          </Form.Item>
          <Form.Item>
            <Field
              name="email"
              placeholder="Email"
              type="email"
              label="Email"
              prefix={<img src={EmailIconSvg} alt="" />}
              component={InputField}
            />
          </Form.Item>
          <Form.Item>
            <Field
              country="ae"
              name="mobile"
              placeholder="Mobile No"
              label="Mobile No"
              component={PhoneField}
            />
          </Form.Item>
          <Form.Item>
            <Field
              prefix={<img src={PasswordIconSvg} alt="" />}
              name="password"
              placeholder="Password"
              label="Password"
              type="password"
              component={InputField}
            />
          </Form.Item>
          <Form.Item>
            <Field
              name="hospital"
              placeholder="Place of work"
              label="Place of work"
              component={InputField}
            />
          </Form.Item>
          <Form.Item>
            <Field
              name="specialityNew"
              placeholder="Speciality"
              label="Speciality"
              component={SelectField}
            >
              {specialities.map(({ id, title }) => (
                <Option value={id} key={id} children={title} />
              ))}
            </Field>
          </Form.Item>
          <Form.Item>
            <Field
              name="country"
              placeholder="Country"
              label="Country"
              component={SelectField}
            >
              {countries?.map((country) => (
                <Option value={country.id} key={country.id}>
                  {country.name}
                </Option>
              ))}
            </Field>
          </Form.Item>
          <Form.Item>
            <Field
              name="city"
              placeholder="City"
              label="City"
              component={InputField}
            />
          </Form.Item>
          <Form.Item>
            <Field
              name="scfhs_id"
              placeholder="SCFHS ID (For KSA only)"
              label="SCFHS ID (For KSA only)"
              component={InputField}
            />
          </Form.Item>
          <Form.Item>
            <Field
              component={Checkbox}
              name="agree"
              type="checkbox"
              onClick={() => setAgreement(!agreement)}
            >
              <Styled.AgreeText>
                I confirm I am a HCP working &amp;/or resident in GCC countries
                &amp; I Acknowledge having read, understood & accepted the{" "}
                <a
                  target="_blank"
                  href="https://strapi-digimex-be.s3.eu-west-1.amazonaws.com/Communication+Consent+Privacy+Policy-Digimex.pdf"
                >
                  Communication Consent Privacy Policy
                </a>
              </Styled.AgreeText>
            </Field>
          </Form.Item>
        </Styled.FormWrap>
        <Styled.FormFooter>
          <Styled.Message>
            Already have an account?{" "}
            <Link to={urlLocations.login}>Sign in</Link>
          </Styled.Message>
          <Button disabled={!agreement} htmlType="submit">
            Registration
          </Button>
        </Styled.FormFooter>
      </form>
    </>
  );
};

export default reduxForm({
  form: "registrationForm",
  onSubmit: (values, dispatch, { profilePic }) =>
    dispatch(registrationAction({ ...values, profilePic })),
  validate: (v) =>
    requiredFieldsValidate(v, [
      ...requiredFields,
      ...(v.country === 9 ? ["scfhs_id"] : []),
    ]),
})(RegistrationPageForm);

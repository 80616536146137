import React from 'react';

import Styled from './styled'

const NewsletterCard = ({
    newsletter: {
        title,
        thumbnail: { url : thumbnail },
        fileData: { url: fileUrl }
    }
}) => (
    <Styled.Col>
        <Styled.Card href={fileUrl} target={"__blank"} >
            <img src={thumbnail} alt=""/>
        </Styled.Card>
        <Styled.Title>{ title }</Styled.Title>
    </Styled.Col>
);


export default NewsletterCard;
import React from 'react';
import {Link} from 'react-router-dom';

import {urlLocations} from "../../routes/urlLocation";
import StyledProductItem from './styled/StyledProductItem';
import StyledProductList from './styled/StyledProductList';
import StyledProductPage from './styled/StyledProductPage';
import Footer from './styled/StyledHomeFooter';
import webinarImg from '../../assets/img/webinars.png';
import resourcesImg from '../../assets/img/resources.png';
import expertsImg from '../../assets/img/experts.png';
import testImg from '../../assets/img/tests.png';
import sanofiImg from '../../assets/img/sanofi.png';
import sanofiLogo from "../../assets/img/sanofi-new.png";
import skillupImg from "../../assets/img/skillup.png";
import newslettersImg from '../../assets/img/newsletters.jpg';
import otbLogo from "../../assets/img/otb_logo.png"
import Styled from './styled/StyledLogo';

const HOME_MENU = [
    {
        title: "Webinars",
        img: webinarImg,
        src: urlLocations.webinars

    },
    {
    	title: "Resources",
    	img: resourcesImg,
    	src: urlLocations.resources

    },
    {
        title: "Experts",
        img: expertsImg,
        src: urlLocations.experts

    },
    {
        title: "Test Your Knowledge",
        img: testImg,
        src: urlLocations.exams

    },
    {
        title: "Newsletters",
        img: newslettersImg,
        src: urlLocations.newsLetters

    },
    {
        title: "",
        img: skillupImg,
        src: urlLocations.skillUp

    }
]

export default () => (
    <StyledProductPage>
        <StyledProductList>
            {
                HOME_MENU.map(item =>
                    <StyledProductItem key={item.title} img={item.img}>
                        <Link to={item.src}>
                            <h3>{item.title}</h3>
                            <p>View now</p>
                        </Link>
                    </StyledProductItem>
                )
            }
        </StyledProductList>
        <Footer>
            This program is created and delivered by OTB Life Science & Technology Consultancy FZ LLC,
            in collaboration with various international Academic Institutes and speakers.
            Neither OTB Life Science nor the sponsor are responsible and have no influence on the scientific content of
            the program. For Privacy related topics, please refer to the
            <a href={"https://digimex-emails.s3-eu-west-1.amazonaws.com/otb_privacy.pdf"}
               rel="noopener noreferrer" target={"_blank"}> Privacy Policy</a> section.
            <br/>
            <br/>
            <Styled.StyledLogoRow>
                <Styled.StyledLogoItem>
                    Powered By:
                    <br/>
                    <img width={50} src={otbLogo} alt="otb-logo"/>
                </Styled.StyledLogoItem>
                <Styled.StyledLogoItem>
                    Educational support:
                    <br/>
                    <img width={100} src={sanofiLogo} alt="sanofi-logo"/>
                </Styled.StyledLogoItem>
            </Styled.StyledLogoRow>

        </Footer>

    </StyledProductPage>
);


import styled from "styled-components";

const Title = styled.div`
  font-family: var(--fontAvenirHeavy);
  font-size: 24px;
  margin-bottom: 24px;
`;

export default {
  Title
};

import { createMatchSelector } from "connected-react-router";
import get from "lodash/get";
import find from "lodash/find";

import { isWebinarPast } from "./webinars.utils";
import { urlLocations } from "../../routes/urlLocation";

export const webinarsSelector = state => Object.values(state.webinars);

export const webinarSelector = state => {
  const getMatch = createMatchSelector(urlLocations.webinarDetails);
  const pathId = get(getMatch(state), "params.id");
  const webinars = Object.values(state.webinars);
  return find(webinars, webinar => webinar.id.toString() === pathId);
};

export const upcomingWebinarsSelector = state => {
  const webinars = Object.values(state.webinars);
  return webinars.filter(webinar => !isWebinarPast(webinar));
};

export const pastWebinarsSelector = state => {
  const webinars = Object.values(state.webinars);
  return webinars.filter(webinar => isWebinarPast(webinar));
};

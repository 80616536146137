import { GET_EXPERTS_SUCCESS } from "./experts.action";
import initialState from "../../store/initialState";

export default (state = initialState.experts, action) => {
  switch (action.type) {
    case GET_EXPERTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

import { put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  GET_NEWSLETTERS_FAILURE,
  GET_NEWSLETTERS_SUCCESS,
  GET_NEWSLETTERS,
} from "./newsletters.actions";
import { urlLocations } from "../../routes/urlLocation";
import { isUrlMatch } from "../router/router.utils";
import { newslettersRequest } from "./newsletters.api";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* getNewslettersOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.newsLetters)) {
      yield put({ type: GET_NEWSLETTERS });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchNewsletters() {
  try {
    yield put(startLoaderAction());

    const { data, status } = yield newslettersRequest();
    if (status === 200) {
      yield put({
        type: GET_NEWSLETTERS_SUCCESS,
        payload: { ...data },
      });
    }
  } catch (error) {
    yield put({ type: GET_NEWSLETTERS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* newsletterseSaga() {
  yield takeEvery(LOCATION_CHANGE, getNewslettersOnLocationChange);
  yield takeEvery(GET_NEWSLETTERS, fetchNewsletters);
}

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";

import { preWebinarExamsSelector, postWebinarExamsSelector } from "../../services/exams/exams.selector";
import ExamsList from "./ExamsPage.List/ExamsPage.List";
import { EXAM_TYPE } from '../../constants';
import StyledExamsPage from './styled/StyledExamsPage';
import StyledExamsPageNav from './styled/StyledExamsPageNav';
import StyledNavMarker from './styled/StyledNavMarker';

const ExamsPage = ({ preWebinarExams, postWebinarExams }) => {
    const [examType, setExamType] = useState(EXAM_TYPE.PRE_WEBINAR);

    return (
        <StyledExamsPage>
            <StyledExamsPageNav>
                <StyledNavMarker
                    onClick={() => setExamType(EXAM_TYPE.PRE_WEBINAR)}
                    colorParam={examType ===  EXAM_TYPE.PRE_WEBINAR ? 'active' : 'nonactive'}
                    ><i></i>
                    Pre-Webinars Tests
                </StyledNavMarker>
                <StyledNavMarker
                        onClick={() => setExamType(EXAM_TYPE.POST_WEBINAR)}
                        colorParam={examType ===  EXAM_TYPE.POST_WEBINAR ? 'active' : 'nonactive'}
                    ><i></i>
                    Post-Webinars Tests
                </StyledNavMarker>
            </StyledExamsPageNav>

            { examType === EXAM_TYPE.PRE_WEBINAR ?
                <ExamsList  exams={preWebinarExams}/> :
                <ExamsList exams={postWebinarExams}/>
            }
        </StyledExamsPage>)
};

export default connect(
    createStructuredSelector({
        preWebinarExams: preWebinarExamsSelector,
        postWebinarExams: postWebinarExamsSelector
    })
)(ExamsPage);
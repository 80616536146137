import styled, { css } from 'styled-components'
import { isMobile } from "react-device-detect";
import { Layout } from 'antd'
const { Sider } = Layout

const StyledAside = styled(Sider)`
    height: 100%;
    position: relative;
    width: 249px;
    flex: 0 0 249px;
    height: auto;
    padding: 26px 0 20px 0;
    background-image: var(--gradientAside);
    min-height: 100vh;
    z-index: 5;
    
	${() => {
		if (!isMobile) {
		return css`
                    height: 100%;
				    height: 100vh;
				    position: fixed;
				    transform: translate(0);
                `}}}
                
    ${() => {
		if (isMobile) {
		return css`
                    margin-right: ${({ collapsed }) => collapsed && '40px'};
                    transform: ${({ collapsed }) => !collapsed && 'translate(-100px)'};
                `}}}
     &:after {
       content: '';
       position: absolute;
       width: 59px;
       height: 100px;
       top: 220px;
       	${() => {
		if (!isMobile) {
			return css`
	                top: 39px;
                `}}}
       right: -28px;
       background:  url(${props => props.bg}) center center no-repeat;
       background-size: cover;
    }
`
export default StyledAside;
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const urlLocations = {
  root: "/",
  main: "/main",
  webinars: "/webinars",
  webinarDetails: "/webinars/:id",
  resources: "/resources",
  library: "/library",
  experts: "/experts",
  expertsDetails: "/experts/:id",
  exams: "/exams",
  exam: "/exams/:id",
  sanofi: "/sanofi",
  examSuccess: "/exam-success",
  examFail: "/exam-fail",
  examResult: "/exam-result",
  user: "/user",
  aboutUs: "/about-us",
  favorites: "/favorites",
  catalogue: "/catalogue",
  newsLetters: "/newsLetters",
  resetPassword: "/resetPassword",
  login: "/login",
  registration: "/registration",
  peer2peer: "/peer2peer",
  skillUp: "/skillUp",
};

import styled from "styled-components";
import { DEVICE } from "../../constants/media";

const Page = styled.div`
  padding: 0 20px 40px 20px;

  @media ${DEVICE.laptopL} {
    padding: 0 50px 40px 90px;
  }
`;

const PlaceHolder = styled.div`
  padding: 50px 0;
`;

export default {
  Page,
  PlaceHolder
};

import React, { useRef } from "react";
import { Field, reduxForm } from "redux-form";
import { Form } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { loginAction } from "../../../services/login/login.action";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import Checkbox from "../../../components/Checkbox/Checkbox";
import StyledForget from "./styled/StyledForget";
import PasswordIconSvg from "../../../assets/img/icon_password.svg";
import EmailIconSvg from "../../../assets/img/icon_mail.svg";
import LoginPopup from "../MainPage.LoginPopup/MainPage.LoginPopup";
import { setPopupStatusAction } from "../../../services/popup/popup.action";
import { POPUPS_ID } from "../../../constants";
import ForgotPasswordModal from "../MainPage.ForgotPassword/MainPage.ForgotPasswordPopup/MainPage.ForgotPasswordPopup";
import { urlLocations } from "../../../routes/urlLocation";

const FormItem = Form.Item;

const LoginForm = ({ handleSubmit, setPopupStatus }) => {
  const submitRef = useRef(null);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormItem>
          <Field
            name="identifier"
            type="email"
            component={Input}
            prefix={<img src={EmailIconSvg} alt="" />}
            placeholder="user@domain.com"
          />
        </FormItem>
        <FormItem>
          <Field
            name="password"
            placeholder="Password"
            prefix={<img src={PasswordIconSvg} alt="" />}
            component={Input}
            type="password"
          />
        </FormItem>

        <FormItem>
          <Field component={Checkbox} name="rememberMe">
            Remember me
          </Field>
        </FormItem>

        <FormItem>
          <Button
            onClick={() => setPopupStatus(POPUPS_ID.LOGIN_POPUP)}
            size="small"
            type="primary"
          >
            Login
          </Button>
          <button
            type="submit"
            ref={submitRef}
            style={{ visibility: "hidden" }}
          />
        </FormItem>

        <FormItem>
          <StyledForget>
            <Link
              to="#"
              onClick={setPopupStatus.bind(
                null,
                POPUPS_ID.FORGOT_PASSWORD_POPUP
              )}
            >
              Forgot Password ?
            </Link>
          </StyledForget>
          <StyledForget>
            <Link to={urlLocations.registration}>Registration</Link>
          </StyledForget>
        </FormItem>
        <LoginPopup submitRef={submitRef} />
      </form>
      <ForgotPasswordModal />
    </>
  );
};

export default compose(
  connect(() => ({}), {
    setPopupStatus: setPopupStatusAction,
  }),
  reduxForm({
    form: "LoginForm",
    onSubmit: ({ password, identifier, rememberMe }, dispatch) => {
      dispatch(loginAction({ password, identifier, rememberMe }));
    },
  })
)(LoginForm);

import styled from "styled-components";
import { Upload, Tabs } from "antd";

import { DEVICE } from "../../constants/media";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import PendingIcon from "../../assets/img/clock.png";

const Page = styled.div`
  padding: 30px 20px 40px 20px;

  @media ${DEVICE.laptopL} {
    padding: 30px 50px 40px 90px;
    display: flex;
    flex-direction: row;
  }
  @media ${DEVICE.mobileDevices} {
    display: flex;
    flex-direction: column;
  }
`;

const Main = styled.div`
  flex: 0 0 60%;
  @media ${DEVICE.mobileDevices} {
    order: 2;
  }
`;

const Aside = styled.div`
  flex: 0 0 40%;
  padding-left: 50px;
  @media ${DEVICE.mobileDevices} {
    order: 1;
    padding: 0 0 15px;
  }
`;

const AddNewButton = styled(Button)`
  &&& {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const AddNewTabs = styled(Tabs)`
  &&& .ant-tabs-tab {
    font-size: 16px;
    font-weight: 900;
    padding: 0 30px 10px;
    color: var(--ligthBlue);
    & > * {
      display: inline-block;
      vertical-align: middle;
    }
    svg {
      margin-right: 5px;
      color: var(--ligthBlue);
    }

    path {
      stroke: var(--ligthBlue);
    }
  }

  &&& .ant-tabs-tab-active {
    color: var(--modalTitle);
    path {
      stroke: var(--modalTitle);
    }
  }

  &&& .ant-tabs-ink-bar-animated {
    background: var(--modalTitle);
  }
`;
const AddNewTab = styled(Tabs.TabPane)``;
const PostStatus = styled.div`
  border: 1px solid var(--ligthBlue);
  padding: 8px 0;
  text-align: center;
  color: var(--titleBlue);
  font-size: 17px;
  border-radius: 5px;
  margin-bottom: 30px;
  &:before {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    background-image: url(${PendingIcon});
  }
`;

const TagsTitle = styled.div`
  color: var(--ligthBlue);
  margin-bottom: 15px;
`;

const Tag = styled.li`
  display: inline-block;
  padding: 5px 9px 0 23px;
  border: 1px solid;
  margin: 0 10px 10px 0;
  border-radius: 4px;
  border-color: ${({ color }) => color};
  cursor: pointer;
  color: ${({ isSelected }) => (isSelected ? "white" : "var(--darkBlue)")};
  background-color: ${({ isSelected, color }) =>
    isSelected ? color : "inherit"};
  &:before {
    content: "\\2022";
    color: ${({ color, isSelected }) => (isSelected ? "white" : color)};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

const Uploader = styled(Upload)`
  display: inline-block;
  cursor: pointer;
  margin: 0 25px;
  transition: 0.3s color;
  @media ${DEVICE.mobileDevices} {
    margin: 15px;
  }
  && {
    .ant-upload {
      font-size: 15px;
      font-weight: 500;
      color: var(--darkBlue);
      &:hover {
        color: var(--linkAvatar);
      }
    }
  }
  svg {
    margin-right: 10px;
    width: 20px;
    height: inherit;
    display: inline-block;
    vertical-align: inherit;
  }
`;

const UploaderWrap = styled.div`
  margin: 0 -25px;
`;

const Uploaded = styled.div`
  margin-bottom: 15px;
  color: var(--ligthBlue);
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    margin-left: 20px;
  }
  span {
    margin-right: 15px;
  }
  a {
    color: var(--titleBlue);
  }
`;

const Search = styled.div`
  margin-bottom: 30px;
`;

const Filters = styled.div`
  margin-bottom: 40px;
`;

const PostCard = styled.div`
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.07);
  padding: 25px 30px 35px;
  border-radius: 14px;
  margin-bottom: 30px;
  background-color: white;
  @media ${DEVICE.mobileDevices} {
    padding: 15px;
  }
`;

const PostCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  @media ${DEVICE.mobileDevices} {
    flex-direction: column;
  }
`;

const PostCardAuthor = styled.div``;

const PostCardAvatar = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PostCardAuthorName = styled.div`
  display: inline-block;
  font-size: 16px;
  color: var(--modalTitle);
  vertical-align: middle;
`;

const PostCardHeaderInfo = styled.div``;

const PostCardHeaderDate = styled.div`
  font-size: 16px;
  color: var(--ligthBlue);
  @media ${DEVICE.mobileDevices} {
    margin-top: 15px;
  }
`;

const PostCardContent = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--modalTitle);
`;

const PostCardAttachment = styled.div`
  margin-bottom: 15px;
  img {
    width: 100%;
  }
  video {
    width: 100%;
  }
`;

const PostCardAttachmentFile = styled.div`
  border: solid 2px #e3e3f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${DEVICE.mobileDevices} {
    flex-direction: column;
  }
`;

const PostCardAttachmentFileInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media ${DEVICE.mobileDevices} {
    flex-direction: column;
  }
`;

const PostCardAttachmentFileIcon = styled.div`
  padding: 30px;
  border-right: 2px solid #e3e3f1;
  svg {
    width: 28px;
    height: inherit;
  }
  @media ${DEVICE.mobileDevices} {
    border-right: 0;
  }
`;

const PostCardAttachmentFileName = styled.div`
  font-size: 20px;
  color: var(--modalTitle);
  margin-bottom: 5px;
  @media ${DEVICE.mobileDevices} {
    text-align: center;
  }
`;

const PostCardAttachmentFileNames = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 22px;
  @media ${DEVICE.mobileDevices} {
    text-align: center;
    padding: 0 15px;
  }
`;

const PostCardAttachmentFileExt = styled.div`
  font-size: 16px;
  color: var(--ligthBlue);
  @media ${DEVICE.mobileDevices} {
    display: none;
  }
`;

const PostCardAttachmentUpload = styled.a`
  padding-right: 40px;
  cursor: pointer;
  @media ${DEVICE.mobileDevices} {
    padding-right: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  svg {
    width: 28px;
    height: inherit;
    color: var(--playBtn);
  }
`;

const PostCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  align-items: center;
  @media ${DEVICE.mobileDevices} {
    flex-direction: column;
    align-items: baseline;
  }
`;

const PostCardLikeAuthorList = styled.div`
  max-height: 150px;
  overflow-y: hidden;
  color: var(--modalTitle);
`;

const PostCardLikeAuthorItem = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const PostCardLikeAuthorImg = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PostCardLikeAuthorName = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const PostCardLikes = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--playBtn);
  font-weight: 500;
  svg {
    width: 20px;
    height: inherit;
  }
`;

const PostCardLikeIcon = styled.div`
  margin-right: 15px;
  cursor: pointer;
`;

const PostCardLikeCount = styled.div``;

const PostCardLikeAuthors = styled.div`
  margin-left: 15px;
  cursor: pointer;
`;

const PostCardLikeAuthor = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: -10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PostCardCommentToggle = styled.div`
  cursor: pointer;
  color: var(--playBtn);
  margin: 0 -15px;
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    width: 20px;
    height: inherit;
    margin: 0 15px;
  }
`;

const PostCardCommentToggleIcon = styled.div``;

const HR = styled.hr`
  margin: 20px -30px 0;
  background: #dadaed;
  display: block;
  height: 1px;
  border: 0;
  @media ${DEVICE.mobileDevices} {
    margin: 20px -15px 0;
  }
`;

const PostCardCommentForm = styled.div`
  margin: -1px -30px 0;
  display: flex;
  padding: 20px 30px 0;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
  border-top: 1px solid #dadaed;
  @media ${DEVICE.mobileDevices} {
    margin: -1px -15px 0;
    padding: 20px 10px 0;
  }
`;

const PostCardCommentAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  @media ${DEVICE.mobileDevices} {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PostCardHospital = styled.div`
  font-size: 14px;
  color: var(--ligthBlue);
`;

const PostCardCommentInput = styled(Input)`
  display: flex;
  flex: 1;
  margin: 0 15px;
`;

const PostCardCommentButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    color: var(--playBtn);
    width: 30px;
    height: inherit;
  }
`;

const PostCardCommentsList = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? "275px" : "0px")};
  overflow-y: auto;
  padding-right: 20px;
`;

const PostCardCommentsItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
  &:first-child {
    margin-top: 25px;
  }
`;

const PostCardCommentsAvatar = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PostCardCommentsInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 15px;
`;

const PostCardCommentsName = styled.div`
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 2px;
  color: var(--modalTitle);
`;

const PostCardCommentsDate = styled.div`
  color: var(--ligthBlue);
  @media ${DEVICE.mobileDevices} {
    display: none;
  }
`;

const PostCardCommentsContent = styled.div`
  color: var(--playBtn);
`;

const FilterTabs = styled(Tabs)`
  &&& .ant-tabs-tab {
    font-size: 16px;
    font-weight: 900;
    padding: 0 30px 10px;
    color: var(--ligthBlue);
  }

  &&& .ant-tabs-tab-active {
    color: var(--modalTitle);
  }

  &&& .ant-tabs-ink-bar-animated {
    background: var(--modalTitle);
  }
`;

const FilterTab = styled(Tabs.TabPane)`
  color: var(--modalTitle);
`;

const AuthorInfo = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const PostCardTagRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PostCardExternalLinkBtn = styled.a`
  border-radius: 6px;
  color: var(--playBtn);
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
`;

const AddNewFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${DEVICE.mobileDevices} {
    flex-direction: column;
  }
`;

export default {
  AddNewFooter,
  PostCardExternalLinkBtn,
  PostCardCommentsContent,
  PostCardTagRow,
  PostCardCommentsInfo,
  PostCardCommentsDate,
  PostCardCommentsName,
  PostCardCommentsAvatar,
  PostCardCommentsItem,
  PostCardCommentsList,
  PostCardCommentForm,
  PostCardCommentAvatar,
  PostCardCommentInput,
  PostCardCommentButton,
  Page,
  Main,
  Aside,
  AddNewButton,
  PostStatus,
  TagsTitle,
  Tag,
  Uploader,
  UploaderWrap,
  Uploaded,
  Search,
  Filters,
  PostCard,
  PostCardHeader,
  PostCardAuthor,
  PostCardAvatar,
  PostCardAuthorName,
  PostCardHeaderInfo,
  PostCardHeaderDate,
  PostCardContent,
  PostCardAttachment,
  PostCardAttachmentFile,
  PostCardAttachmentFileIcon,
  PostCardAttachmentFileName,
  PostCardAttachmentFileInfo,
  PostCardAttachmentFileNames,
  PostCardAttachmentFileExt,
  PostCardAttachmentUpload,
  PostCardFooter,
  PostCardLikes,
  PostCardLikeIcon,
  PostCardLikeCount,
  PostCardLikeAuthors,
  PostCardLikeAuthor,
  PostCardCommentToggle,
  PostCardCommentToggleIcon,
  HR,
  FilterTab,
  FilterTabs,
  PostCardHospital,
  AuthorInfo,
  PostCardLikeAuthorList,
  PostCardLikeAuthorItem,
  PostCardLikeAuthorImg,
  PostCardLikeAuthorName,
  AddNewTabs,
};

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from "lodash/isEmpty";
import { expertsSelector } from '../../../services/experts/experts.selector';
import {interestsSelector} from "../../../services/interests/interests.selector";
import {getSelectedInterestInfoSelector} from "../../../services/user/user.selector";
import { filterByInterests } from '../../../services/interests/interests.utils';
import ExpertCard from "../ExpertsPage.Card/ExpertsPage.Card";
import StyledExpertsList from "./styled/StyledExpertsList";

const ExpertsList = ({ experts, interests, selectedInterestInfo }) => {
   const  filteredExperts = filterByInterests(experts,interests,  "interests[0].id");

   return <StyledExpertsList>
       {
           !isEmpty(filteredExperts) ?
               filteredExperts.map(expert => (
                <ExpertCard key={expert.id} expert={expert} />))
                : `There is no experts in ${selectedInterestInfo?.Name}`
       }
   </StyledExpertsList>
};

export default connect(
    createStructuredSelector({
        experts: expertsSelector,
        interests: interestsSelector,
        selectedInterestInfo: getSelectedInterestInfoSelector
    })
)(ExpertsList)
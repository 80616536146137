import styled from "styled-components";

const StyledWebinarDescription = styled.div`
  margin-bottom: 50px;
  margin-top: 40px;
  p {
    white-space: break-spaces;
    font-family: var(--fontLatoRegular);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: left;
    color: var(--defaultTextColor);
  }
`;
export default StyledWebinarDescription;

import { put, takeEvery } from "redux-saga/effects";
import { notification } from "antd";
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
} from "./password.action";
import { SET_POPUP_STATUS } from "../popup/popup.action";
import {
  changePasswordRequest,
  forgotPasswordRequest,
  resetPasswordRequest,
} from "./password.api";
import { urlLocations, history } from "../../routes/urlLocation";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* changePassword({ payload }) {
  try {
    yield put(startLoaderAction());

    const { response } = yield changePasswordRequest({
      ...payload,
    });
    if (response.status === 200) {
      yield put({
        type: CHANGE_PASSWORD_SUCCESS,
      });
      yield put({ type: SET_POPUP_STATUS, payload: { status: null } });
    } else {
      yield put({ type: CHANGE_PASSWORD_FAILURE });
      notification.error({
        message: "Error",
        description: response.data.message,
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* forgotPassword({ payload }) {
  try {
    yield put(startLoaderAction());

    const { response } = yield forgotPasswordRequest({
      ...payload,
    });
    if (response.status === 200) {
      yield put({
        type: FORGOT_PASSWORD_SUCCESS,
      });
      notification.open({
        message: "Success",
        description: "Password was successfully reset, please check your email",
      });
      yield put({ type: SET_POPUP_STATUS, payload: { status: null } });
    } else {
      yield put({ type: FORGOT_PASSWORD_FAILURE });
      notification.error({
        message: "Error",
        description: response.data.message,
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* resetPassword({ payload }) {
  try {
    yield put(startLoaderAction());

    const { response } = yield resetPasswordRequest({
      ...payload,
    });
    if (response.status === 200) {
      yield put({
        type: RESET_PASSWORD_SUCCESS,
      });
      history.push(urlLocations.root);
    } else {
      yield put({ type: RESET_PASSWORD_FAILURE });
      notification.error({
        message: "Error",
        description: response.data.message[0].messages[0].message,
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* passwordSaga() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

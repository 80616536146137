import { put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  GET_FAVORITES_SUCCESS,
  GET_FAVORITES,
  GET_FAVORITES_FAILURE,
  ADD_FAVORITES,
  ADD_FAVORITES_FAILURE,
  ADD_FAVORITES_SUCCESS,
  GET_FAVORITES_IDS,
  GET_FAVORITES_IDS_FAILURE,
  GET_FAVORITES_IDS_SUCCESS,
  REMOVE_FAVORITES,
  REMOVE_FAVORITES_FAILURE,
  REMOVE_FAVORITES_SUCCESS,
} from "./favorites.actions";
import { urlLocations } from "../../routes/urlLocation";
import { isUrlMatch } from "../router/router.utils";
import {
  getFavoritesRequest,
  addFavoriteRequest,
  getFavoritesIdsRequest,
  removeFavoriteRequest,
} from "./favorites.api";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* getFavoritesOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.favorites)) {
      yield put({ type: GET_FAVORITES });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getFavoritesIdsOnLocationChange({ payload }) {
  try {
    if (
      isUrlMatch(payload, urlLocations.webinars) ||
      isUrlMatch(payload, urlLocations.webinarDetails) ||
      isUrlMatch(payload, urlLocations.favorites)
    ) {
      yield put({ type: GET_FAVORITES_IDS });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchFavorites() {
  try {
    yield put(startLoaderAction());
    const { data, status } = yield getFavoritesRequest();
    if (status === 200) {
      yield put({
        type: GET_FAVORITES_SUCCESS,
        payload: { ...data },
      });
    }
  } catch (error) {
    yield put({ type: GET_FAVORITES_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* fetchFavoritesIds() {
  try {
    yield put(startLoaderAction());
    const { data, status } = yield getFavoritesIdsRequest();
    if (status === 200) {
      yield put({
        type: GET_FAVORITES_IDS_SUCCESS,
        payload: { ...data },
      });
    }
  } catch (error) {
    yield put({ type: GET_FAVORITES_IDS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* addFavorites({ payload }) {
  try {
    yield put(startLoaderAction());
    const { data, status } = yield addFavoriteRequest({ ...payload });
    if (status === 200) {
      yield put({
        type: ADD_FAVORITES_SUCCESS,
        payload: { ...data },
      });
      yield put({
        type: GET_FAVORITES_IDS,
      });
      yield put({
        type: GET_FAVORITES,
      });
    }
  } catch (error) {
    yield put({ type: ADD_FAVORITES_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* removeFavorites({ payload }) {
  try {
    yield put(startLoaderAction());
    const { data, status } = yield removeFavoriteRequest({ ...payload });
    if (status === 200) {
      yield put({
        type: REMOVE_FAVORITES_SUCCESS,
        payload: { ...data },
      });
      yield put({
        type: GET_FAVORITES_IDS,
      });
      yield put({
        type: GET_FAVORITES,
      });
    }
  } catch (error) {
    yield put({ type: REMOVE_FAVORITES_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* favoritesSaga() {
  yield takeEvery(LOCATION_CHANGE, getFavoritesOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getFavoritesIdsOnLocationChange);
  yield takeEvery(GET_FAVORITES, fetchFavorites);
  yield takeEvery(ADD_FAVORITES, addFavorites);
  yield takeEvery(REMOVE_FAVORITES, removeFavorites);
  yield takeEvery(GET_FAVORITES_IDS, fetchFavoritesIds);
}

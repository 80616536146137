import { all } from "redux-saga/effects";
import loginSaga from "./login/login.saga";
import userSaga from "./user/user.saga";
import logoutSaga from "./logout/logout.saga";
import webinarsSaga from "./webinars/webinars.saga";
import sessionsSaga from "./sessions/sessions.saga";
import articlesSaga from "./articles/articles.saga";
import expertsSaga from "./experts/experts.saga";
import examsSaga from "./exams/exams.saga";
import sanofiSaga from "./sanofi/sanofi.saga";
import interestsSaga from "./interests/interests.saga";
import contactUsSaga from "./contactUs/contactUs.saga";
import favoritesSaga from "./favorites/favorites.saga";
import catalogueSaga from "./catalogue/catalogue.saga";
import newslettersSaga from "./newsletters/newsletters.saga";
import passwordSaga from "./password/password.saga";
import userInterestsSaga from "./userInterests/userInterests.saga";
import registrationSaga from "./registration/registration.saga";
import loaderSaga from "./loader/loader.saga";
import peer2peerSaga from "./peer2peer/peer2peer.saga";
import countriesSaga from "./countries/countries.saga";
import filesSaga from "./file/file.saga";
import specialitiesSaga from "./specialities/specialities.saga";
import resendEmailSaga from "./resendEmail/resendEmail.saga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    filesSaga(),
    loaderSaga(),
    favoritesSaga(),
    userSaga(),
    logoutSaga(),
    webinarsSaga(),
    sessionsSaga(),
    articlesSaga(),
    expertsSaga(),
    examsSaga(),
    sanofiSaga(),
    interestsSaga(),
    contactUsSaga(),
    catalogueSaga(),
    newslettersSaga(),
    passwordSaga(),
    userInterestsSaga(),
    registrationSaga(),
    peer2peerSaga(),
    countriesSaga(),
    specialitiesSaga(),
    resendEmailSaga(),
  ]);
}

import React from 'react';
import { Field, reduxForm } from "redux-form";
import { Form } from "antd";

import Input from "../../../../components/RenderField/RenderField";
import Button from "../../../../components/Button/Button";
import {resetPasswordAction} from "../../../../services/password/password.action";
import { passwordValidate as validate } from '../../../../utils/validate'

const ResetForm = ({ handleSubmit }) => {
    return (
    <form onSubmit={handleSubmit}>
        <Form.Item>
            <Field
                name="password"
                type="password"
                component={Input}
                placeholder="Password"
            />
        </Form.Item>
        <Form.Item>
            <Field
                name="passwordConfirmation"
                type="password"
                component={Input}
                placeholder="Confirm password"
            />
        </Form.Item>
        <Button htmlType="submit" >Save</Button>
    </form>
)};

export default reduxForm({
    validate,
    form: "passwordResetForm",
    onSubmit: (value, dispatch, { code }) => dispatch(resetPasswordAction({...value, code}))
})(ResetForm);
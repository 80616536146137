import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledWebinarsPage = styled.div`
	padding: 25px 20px 80px 20px;
	    	 	  
	@media ${DEVICE.tablet} {
       padding: 20px 40px 80px 20px;
    }
    @media ${DEVICE.laptop} {
        padding: 55px 55px 80px 90px;
    }
`
export default StyledWebinarsPage;
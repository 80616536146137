import React from 'react';

import Styled from './styled';
import NewslettersList from "./NewslettersPage.List/NewslettersPage.List";

const NewslettersPage = () => {
    return (
        <Styled.Page>
            <NewslettersList/>
        </Styled.Page>
    )
};

export default NewslettersPage;
import styled from 'styled-components';
import { DEVICE } from '../../../../constants/media';

const StyledArticleCol = styled.div`
	 display: inline-block;
	 width: calc(100% - 80px);
	 padding-left: 14px;
	 
	 div {
          font-size: 10px;
           @media ${DEVICE.laptop} {
		        font-size: 12px;
	       }
		  font-weight: normal;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.67;
		  letter-spacing: normal;
		  text-align: left;
		  
		  font-family: var(--fontLatoRegular);
		  color: var(--greyMainText);
		  	 
		  white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
		  @supports (-webkit-line-clamp: 4) {
		      overflow: hidden;
		      text-overflow: ellipsis;
		      white-space: initial;
		      display: -webkit-box;
		      -webkit-line-clamp: 4;
		      -webkit-box-orient: vertical;
           }
     }  
`
export default StyledArticleCol;

import { put, select, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import isEmpty from "lodash/isEmpty";

import {
  GET_USER_DATA,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_SUCCESS,
  CLAIM_POINTS,
  CLAIM_POINTS_SUCCESS,
  CLAIM_POINTS_FAILURE,
} from "./user.action";

import { SET_POPUP_STATUS } from "../popup/popup.action";
import { userRequest, userUpdateRequest, claimPointsRequest } from "./user.api";
import { prepareSelectedPoints } from "./user.utils";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* getUserOnLocationChangeData() {
  try {
    yield put(startLoaderAction());

    const user = yield select((state) => state.user);
    const storage = localStorage?.getItem("storageTyp") || "localStorage";

    if (isEmpty(user) && window[storage].getItem("jwt")) {
      yield put({ type: GET_USER_DATA });
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* fetchUserData() {
  try {
    yield put(startLoaderAction());

    const { data, status } = yield userRequest();
    if (status === 200) {
      yield put({
        type: GET_USER_DATA_SUCCESS,
        payload: { ...data },
      });
    }
  } catch (error) {
    yield put({ type: GET_USER_DATA_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* updateUser({ payload }) {
  const { cmePoints, email, country, ...rest } = payload;
  try {
    yield put(startLoaderAction());

    const { data } = yield userUpdateRequest({ ...rest });

    yield put({
      type: UPDATE_PROFILE_SUCCESS,
      payload: { ...data },
    });
  } catch (error) {
    yield put({ type: UPDATE_PROFILE_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* claimPoints({ payload }) {
  try {
    yield put(startLoaderAction());

    const { data, status } = yield claimPointsRequest({
      itemsToClaim: [...prepareSelectedPoints(payload)],
    });
    if (status === 200) {
      yield put({
        type: CLAIM_POINTS_SUCCESS,
        payload: { ...data },
      });

      yield put({ type: GET_USER_DATA });
      yield put({ type: SET_POPUP_STATUS, payload: { status: null } });
    }
  } catch (error) {
    yield put({ type: CLAIM_POINTS_FAILURE });

    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* userSaga() {
  yield takeEvery(LOCATION_CHANGE, getUserOnLocationChangeData);
  yield takeEvery(GET_USER_DATA, fetchUserData);
  yield takeEvery(UPDATE_PROFILE, updateUser);
  yield takeEvery(CLAIM_POINTS, claimPoints);
}

import { createAction } from "redux-actions";

export const GET_EXAMS = "GET_EXAMS";
export const GET_EXAMS_SUCCESS = "GET_EXAMS_SUCCESS";
export const GET_EXAMS_FAILURE = "GET_EXAMS_FAILURE";

export const SET_EXAM = "SET_EXAM";
export const SET_EXAM_SUCCESS = "SET_EXAM_SUCCESS";
export const SET_EXAM_FAILURE = "SET_EXAM_FAILURE";

export const getExamsAction = createAction(GET_EXAMS);
export const setExamAction = createAction(SET_EXAM);

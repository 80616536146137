import styled from "styled-components";

const StyledArticleImg = styled.img`
  width: 80px;
  height: 100px;
  border: ${({ border }) => `1px solid ${border}`};
  border-radius: 2px;
`;

export default StyledArticleImg;

import React from 'react';

import PopupWrapper from "../../../../hoc/Modal/withPopup";
import { POPUPS_ID } from "../../../../constants";
import ForgotPasswordForm from "../MainPage.ForgotPasswordForm/MainPage.ForgotPasswordForm";

const ForgotPasswordModal = () => <PopupWrapper
    popupId={POPUPS_ID.FORGOT_PASSWORD_POPUP}
    component={ForgotPasswordForm}
    destroyOnClose
/>;

export default ForgotPasswordModal;

import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledSpeakerBlock = styled.div`
    display: flex;
    margin-top: 20px;
    align-items: center;
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    padding-left: 26px;
    height: 25px;
    
    img {
        width: 25px;
        height: 25px;
        border-radius: 2px;
        object-fit: cover;
    }
    p {
	  font-size: 12px;
	  line-height: 1.36;
	  letter-spacing: -0.08px;
	  text-align: left;
	  color: var(--subtitleCards);
	  padding-left: 15px;
	  padding-right: 5px;
	  
	  @media ${DEVICE.laptop} {
	     font-size: 14px; 
	  }
    }
`
export default StyledSpeakerBlock;
import React, { useEffect, useState } from 'react';

import Header from '../../components/Header/Header';
import Aside from '../../components/Aside/Aside';
import StyledAutoziredLayout from './styled/StyledAutorizedLayout';
import StyledAutoziredContent from './styled/StyledAutorizedContent';
import StyledAutoziredWrapper from './styled/StyledAutoziredWrapper';
import { history, urlLocations } from "../../routes/urlLocation";

const AuthorizeLayout = ({ children, title }) => {
    useEffect(() => {
        const storage = localStorage?.getItem("storageTyp");
        const jwt = window[storage]?.getItem('jwt');
        !jwt && history.push(urlLocations.root)
    }, []);

	const [ isCollapse, setCollapse ] = useState(false);
	const onCollapse = () => {
		setCollapse(!isCollapse);
	};

  return(
      <StyledAutoziredLayout>
              <Aside
                  collapsed={isCollapse}
                  onCollapse={onCollapse}
              />

              <StyledAutoziredContent>
                  <Header onCollapse={onCollapse} title={title}/>
                  <StyledAutoziredWrapper>
			          {children}
                  </StyledAutoziredWrapper>
              </StyledAutoziredContent>

      </StyledAutoziredLayout>
  )
};

export default AuthorizeLayout;


import { put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  GET_SANOFI_SUCCESS,
  GET_SANOFI,
  GET_SANOFI_FAILURE,
} from "./sanofi.action";
import { sanofiRequest } from "./sanofi.api";
import { urlLocations } from "../../routes/urlLocation";
import { isUrlMatch } from "../router/router.utils";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* getSanofiOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.sanofi)) {
      yield put({ type: GET_SANOFI });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchSanofi() {
  try {
    yield put(startLoaderAction());

    const { data, status } = yield sanofiRequest();
    if (status === 200) {
      yield put({
        type: GET_SANOFI_SUCCESS,
        payload: { ...data },
      });
    }
  } catch (error) {
    yield put({ type: GET_SANOFI_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* sanofiSaga() {
  yield takeEvery(LOCATION_CHANGE, getSanofiOnLocationChange);
  yield takeEvery(GET_SANOFI, fetchSanofi);
}

import React from 'react';

import Styled from './AboutUsPage.Styled';
import otbLogo from '../../assets/img/otb_logo.png'
import sanofiLogo from '../../assets/img/sanofi-new.png';

const AboutUsPage = () =>
    <Styled.Page>
        <Styled.A href={"https://digimex-emails.s3-eu-west-1.amazonaws.com/sanofi+catalogue+FINAL.pdf"} target={"_blank"}>Digimex EDUCATIONAL CATALOGUE 2020</Styled.A>
        <br/>
        <br/>
        <Styled.SubTitle>
            About Digimex:
        </Styled.SubTitle>
        <Styled.P>
            DigiMex is a Multidisciplinary Digital Medical Excellence
            Educational program delivered exclusively through a dedicated
            restricted-access digital web portal. The program is fully accredited
            locally from the Health Authority of Abu Dhabi*, and additionally,
            you will receive a certificate of participation equivalent to 2 CME hours
            from the respective Academic institute delivering the session.
        </Styled.P>
        <Styled.P>
            By Registering to the platform you will potentially receive 6-18 CME hours*, and you will receive latest updates on Dyslipidemia, Diabetes, Atopic Dermatitis Management & various other topics.
        </Styled.P>
        <Styled.P>
            Through the web portal of the program, you will be able to:
        </Styled.P>
        <Styled.UL>
            <li>
                Participate in the Live Webinars and ask Live questions to the speakers
            </li>
            <li>
                View recorded webcasts on-demand
            </li>
            <li>
                Ask the International and local speakers and expert panel and receive the answer
            </li>
            <li>
                Request redemption of your CME points at any time
            </li>
            <li>
                Access the Library of references and resources relevant to the program
            </li>
            <li>
                Connect with Sanofi Medical Teams to request more information on
                latest updates on the science related to their medicines
            </li>
        </Styled.UL>
        <Styled.P>
            The overall content of DigiMex covers the following topics:
        </Styled.P>
        <Styled.UL>
            <li>Atopic Dermatitis management</li>
            <li>Chronic Asthma management</li>
            <li>Chronic Rhinitis with Nasal Polyps</li>
            <li>Diabetes management</li>
            <li>Dyslipidemia management</li>
            <li>VTE treatment & management</li>
            <li>Hypertension and other CVS diseases</li>
            <li>Acute Coronary Syndrome</li>
            <li>Anti Microbial resistance</li>
            <li>Multiple Sclerosis</li>
            <li>Rare Disease</li>
            <li>Vaccines</li>
        </Styled.UL>
        <Styled.smallP>
            * The number of CME depends on the number of webinars you watch & the Knowledge test you take before & after each webinar.
        </Styled.smallP>
        <Styled.SubTitle>
            Terms and Conditions:
        </Styled.SubTitle>
        <Styled.P>
            I have been informed that the following entities of the Sanofi Group
            listed below: Sanofi-Aventis Groupe (DMCC Branch), a corporation existing
            and organized under the laws of United Arab Emirates, acting on its own behalf
            and on behalf of its affiliates, successors and assigns, as hereinafter defined,
            having its registered offices in Dubai Multi Commodities Center United Arab Emirates,
            (hereinafter referred to as the "Company"), wishes to process my personal data
            (hereinafter referred to as the "Personal Data”) for the purposes indicated in this consent form.
        </Styled.P>
        <Styled.P>
            I have been informed that certain of my Personal Data may have been collected indirectly
            through professional databases licensed to the Company and/or the Sanofi Group by third parties.
        </Styled.P>
        <Styled.P>
            The Company, or the Recipients (as defined hereunder) will also collect
            my Personal Data through its interactions with me, either offline, or online,
            to, among others, better understand general trends on the basis of aggregated
            format data (e.g. analyze and predict your preferences), to better understand my
            interests, preferences and my opinion as a healthcare professional, and personalize
            its communication and interactions with me.
        </Styled.P>
        <Styled.P>
            I have been informed that my Personal Data may be used, subject to my consent,
            for the following purposes, or for those purposes that may reasonably derive from the following:
        </Styled.P>
        <Styled.NumUL>
            <li>Receive marketing and/or educational medical
                communications from the Company or any of its third parties/Recipients
                about products and/or educational material and/or medical updates relevant
                to my practice through customized content.
            </li>
            <li>Enroll or register me in medical and/or educational programs,
                congresses, scientific meetings and/or events organized by
                Sanofi or third party organizations
            </li>
            <li>Participate in market studies and surveys.</li>
            <li>Remote detailing and call center communications.</li>
            <li>Receive communications on clinical research outcomes if
                contracted by the Company or any other entity on behalf of the Company,
                and if legally permitted for such a purpose.
            </li>
        </Styled.NumUL>
        <Styled.P>
            The Company may use any means of communication,
            in any shape or format, to communicate with me for the above purposes.
        </Styled.P>
        <Styled.P>
            I understand that my Personal Data may be
            shared with other affiliates of the Sanofi Group and external
            service provider(s) (such as but not limited to, IT resources and solution
            providers, research organizations, hospitality service providers, marketing
            and/or medical communication agencies, publishers, congress orevents organizers,
            IT solutions service providers, etc.) who assist the Company in the processing of my
            Personal Data or assist the Company in providing me with the above mentioned purposes
            (the "Recipients"). Some of these Recipients may be located in countries, which do not
            ensure the same level of personal data protection as the country where I am located.
            In such case, the Company has implemented appropriate mechanisms to protect my Personal Data.
            For more information on the countries where the data are transferred or on the data transfers
            mechanisms implemented, I can contact engage.gulf@sanofi.com
        </Styled.P>
        <Styled.P>
            In any event, my Personal Data is accessed only by the
            Company and Recipients relevant personnel on a need-to-know
            basis, and only for the purposes indicated above. The Company does
            not sell any of my Personal Data for marketing or advertising purposes.
            I have been informed that my Personal Data may be assigned by the Company
            in the context of a sale, contribution, merger or any legal reorganization of the
            Sanofi Group, unless I object as described below.
        </Styled.P>
        <Styled.P>
            I have been informed that I have the right to object to the processing
            of my Personal Data for these purposes by refusing to sign this consent.
            In the event I refuse to provide my Personal Data, or to sign this form, or
            if I withdraw my consent at any time, I understand that the Company will
            not be able to provide me with information about its products, services and
            activities, nor be able to provide me with customized pharmaceutical
            information, based on my preferences and interests. However, no other consequences
            due to such objection will arise.
        </Styled.P>
        <Styled.P>
            I have been informed that the Company will process my
            Personal Data for these purposes as long as the Company
            is not informed that I have ceased to occupy my function as a
            healthcare or pharmaceutical professional, and as long as I
            do not withdraw my consent to the processing of my Personal
            Data for the above mentioned purposes. I also understand that
            my Personal Data may be stored after such time period in an archive
            mode, only to allow the Company or the Recipients to comply with
            obligations under applicable law requirements.
        </Styled.P>
        <Styled.P>
            I have been informed that I have a right to (i)
            access my Personal Data, (ii) request rectification or
            deletion thereof, (iii) object to the processing thereof,
            (iv) request the erasure of my Personal Data ("right to be forgotten”),
            (v) obtain from the Company my Personal Data in a commonly used
            machine-readable format, and transfers such Personal Data to another
            entity of my choice ("data portability”)(v)request restriction of the
            processing of my Personal Data to the extent permitted by applicable law.
        </Styled.P>
        <Styled.P>
            I have been also informed that I have a right
            to withdraw at any time, free-of-charge, my consent
            to the processing of my Personal Data for the
            above-mentioned purposes. In such case the Company
            shall no longer process my Personal Data unless it
            has compelling legitimate grounds to do so.
        </Styled.P>
        <Styled.P>
            The above-mentioned rights may be implemented by
            contacting the Company via email at engage.gulf@sanofi.com.
            When I contact the Company via email, I may be asked to answer
            several questions related to my Personal Data in order to
            allow the Company to verify my identity.
        </Styled.P>
        <Styled.P>
            You can reach the global Data Protection Officer for
            the Company at Privacy-Office-Global@sanofi.com ,I
            hereby freely consent to the Company and Recipients processing my
            Personal Data in the conditions detailed therein.
        </Styled.P>
        <Styled.SubTitle>
            Privacy Policy:
        </Styled.SubTitle>
        <Styled.P>
            This program is created and delivered by OTB Life Science &
            Technology Consultancy FZ LLC, in collaboration with various
            international Academic Institutes and speakers. Neither OTB Life Science nor
            the sponsor are responsible and have no influence on the scientific
            content of the program. For Privacy related topics, please
            refer to the <a
            rel="noopener noreferrer"
            href={"https://digimex-emails.s3-eu-west-1.amazonaws.com/otb_privacy.pdf"}
                            target={"_blank"}>Privacy Policy</a> section.
        </Styled.P>
        <Styled.FooterRow>
            <div>
                Powered By:
                <br/>
                <img src={otbLogo} alt="otb-logo"/>
            </div>
            <div>
                With Educational Support From:
                <br/>
                <br/>
                <img width={150} src={sanofiLogo} alt="sanofi-logo"/>
            </div>
        </Styled.FooterRow>
    </Styled.Page>;


export default AboutUsPage;

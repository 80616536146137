import styled from "styled-components";

const StyledLogoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledLogoItem = styled.div`
  align-self: flex-start;
  margin-right: 20px;
  margin-left: 20px;
`;

export default {
  StyledLogoRow,
  StyledLogoItem
};

import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";

import { setSessionLogAction } from "../../../services/sessions/sessions.action";
import {
	isTablet,
	isMobileOnly
} from "react-device-detect";

const heightVideo = isMobileOnly ? 180 : isTablet ? 310 : 400;

const VideoWrapper = ({ poster , url, sessionId, setSessionLog }) => {
    const videoRef = useRef(null);
    const [ isVideoPlay, setVideoStatus ] = useState(false);
    const [ videoLogTime, setVideoLogTime ] = useState(0);
    const ref = useRef(videoLogTime);

    const onVideoPlay = () => {
        setVideoStatus(true);
    };

    const startTimeLogging = () => {
        if (isVideoPlay) {
            ref.current = videoLogTime + 1;
            setVideoLogTime(videoLogTime + 1);
        }
    };

    const onVideoPause = () => {
        setVideoStatus(false);
    };

    useEffect(() => {
        startTimeLogging();
    }, [isVideoPlay]);


    useEffect(() => {
        const timer = setTimeout(startTimeLogging, 1000);
        return () => {
            clearTimeout(timer);
        }
    }, [videoLogTime]);

    useEffect(function (){
        return () => {
            setSessionLog({
                sessionId,
                watchedMins: ref.current,
            });
        }
    },  []);

    return (
    <video
        onPlaying={onVideoPlay}
        onPause={onVideoPause}
        ref={videoRef}
        poster={poster}
        autoPlay
        height={heightVideo}
        controls="controls"
        width="100%"
        src={url}
    >
        <source src={url} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'  />
    </video>
)};

export default connect(() => ({}), {
        setSessionLog: setSessionLogAction
    })(VideoWrapper);
import { GET_WEBINARS_SUCCESS } from "./webinars.action";
import initialState from "../../store/initialState";

export default (state = initialState.webinars, action) => {
  switch (action.type) {
    case GET_WEBINARS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import Styled from "./Styled";
import { openNotification } from "../../utils/notifications";
import { selectFile } from "../../services/file/file.selector";
import {
  fileUploadAction,
  removeFileAction,
  setFilesAction,
} from "../../services/file/file.action";

const AVATAR_FORMATS = ["image/jpeg", "image/png", "image/jpg"];

const AvatarUploader = ({ uploaderName, darkThema, initialPic }) => {
  const dispatch = useDispatch();
  const profilePic = useSelector((state) => selectFile(state, uploaderName));

  useEffect(() => {
    dispatch(setFilesAction({ file: initialPic, uploaderName }));
  }, [initialPic]);

  const onBeforeUpload = ({ type }) => {
    if (!AVATAR_FORMATS.includes(type)) {
      openNotification({
        type: "error",
        message: "Error",
        description: "Unsupported file format, please select other",
      });
      return false;
    }
  };
  return (
    <Styled.UploaderWrapper>
      <Styled.Uploader
        fileList={false}
        customRequest={({ file }) =>
          dispatch(fileUploadAction({ file, uploaderName }))
        }
        beforeUpload={onBeforeUpload}
      >
        {!!profilePic ? (
          <img src={profilePic?.url} alt="" />
        ) : (
          <UploadOutlined />
        )}
        {profilePic && (
          <Styled.UploaderRemove>
            <DeleteOutlined
              onClick={(event) => {
                event.stopPropagation();
                dispatch(removeFileAction(uploaderName));
              }}
            />
          </Styled.UploaderRemove>
        )}
      </Styled.Uploader>
      <Styled.UploaderInfo darkThema={darkThema}>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias
        aperiam, at consectetur explicabo illum, laborum maxime molestias neque
        odio quam quisquam quod quos soluta voluptatem. Iure, maiores,
        molestias! Modi.
      </Styled.UploaderInfo>
    </Styled.UploaderWrapper>
  );
};

export default AvatarUploader;

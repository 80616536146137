import styled from 'styled-components'
import { DEVICE } from '../../constants/media';
import { isMobileOnly, isMobile } from "react-device-detect";

const StyledResultBlock = styled.div`
 	 
	 padding: 0 20px 0 20px;
	 display: flex;
	 align-items: center;
     min-height: calc(100vh - 80px);
     height: 100%;
     position:  relative;
     
     @media ${DEVICE.tablet} {
       padding: 0 20px 0 20px;
     }
     
     @media ${DEVICE.laptopL} {
       padding: 0 90px 90px 90px;
     }
     
      @media ${DEVICE.mobileTabletDevices} {
        height: ${ isMobile && 'calc(100vh - 100px)' }; 
      }
	 
	 &>div {
	     width: 500px;
	     @media ${DEVICE.mobileDevices} {
             width:${ isMobileOnly && '100%;' }; 
             margin-top: ${ (isMobileOnly && (window.innerWidth < 650) ) && '80px;' }; 
         }
	 }
	 
	 h2 {
	      font-family: var(--fontAvenirHeavy);
		  font-size: 24px;
		  font-weight: 900;
		  line-height: 1.23;
		  letter-spacing: normal;
		  text-align: left;
		  color: var(--modalTitle);
		  padding-bottom: 18px;
		  
		  @media ${DEVICE.tablet} {
             font-size: 44px;
          }
	 }
	 
	 b {
        font-family: var(--fontAvenirHeavy);
	 }
	 
	 p {
		  font-size: 14px;
		  font-weight: normal;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.56;
		  letter-spacing: normal;
		  text-align: left;
		  color: var(--fontColorQuestion);
		  
		  @media ${DEVICE.tablet} {
             font-size: 18px;
          }
          
	 }
	 
	 h5 {
		  position: absolute;
		  bottom: 37px;
		  left: 0;
		  font-size: 14px;
		  font-weight: normal;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.71;
		  letter-spacing: normal;
		  text-align: left;
		  color: #939eab;
		  padding-left: 90px;
		  padding-right: 90px;
	 }
`
export default StyledResultBlock;
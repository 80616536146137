import React from 'react';
import { Link } from "react-router-dom";

import StyledSanofiPage from '../SanofiPage/styled/StyledSanofiPage';
import StyledSanofiList from '../SanofiPage/styled/StyledSanofiList';
import StyledSanofiCard from '../SanofiPage/styled/StyledSanofiCard';
import LibraryBg from "../../assets/img/Library.png"
import { urlLocations } from "../../routes/urlLocation";
import SanofiItem4 from "../../assets/img/Advent.png";
import {useLocation} from "react-router";

const ResourcesPage = () => {
    const { search } = useLocation()
    return (
        <StyledSanofiPage>
            <StyledSanofiList>
                <StyledSanofiCard bg={LibraryBg} >
                    <Link to={`${urlLocations.library}${search ? search : ''}`}>
                        <h3>Library of
                            <br/>
                            Resources</h3>
                    </Link>
                </StyledSanofiCard>
                <StyledSanofiCard bg={ SanofiItem4 }>
                    <a target='_blank' rel="noopener noreferrer" href='https://www.adventprogram.com/'>
                        <h3>Sanofi ADVENT
                            Program</h3>
                    </a>
                </StyledSanofiCard>
            </StyledSanofiList>
        </StyledSanofiPage>
    );
}


export default ResourcesPage;
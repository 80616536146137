import styled from 'styled-components'
import { Input } from 'antd'

const StyledInput = styled(Input)`
    && {
        border: 1px solid var(--borderInput);
		background-color: var(--white);
		position: relative;
		padding: 12px 20px;
		color: var(--defaultTextColor);
		height: 48px;
        border-radius: 4px;
        &[disabled]{
          color: var(--ligthBlue);
        }
        .ant-input-prefix {
            width: 22px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
        }
        
        &:hover {
		    border-color: var(--borderInput);
       }

    }
`

export default StyledInput;



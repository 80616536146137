import React, { useState } from "react";
import Peer2PeerTabs from "./Peer2peer.Tabs";
import Peer2PeerForm from "./Peer2peer.Form";
import { useSelector } from "react-redux";
import { selectFile } from "../../../services/file/file.selector";

export const POST_TYPES = {
  POST: "POST",
  ANNOUNCEMENT: "ANNOUNCEMENT",
};

const Peer2PeerAddNewContent = () => {
  const [selectedType, selectType] = useState(POST_TYPES.POST);
  const file = useSelector((state) => selectFile(state, "peer2peer"));
  return (
    <>
      <Peer2PeerTabs selectedType={selectedType} onChange={selectType} />
      <Peer2PeerForm
        initialValues={{
          content: "",
          interest: "",
        }}
        postType={selectedType}
        attachment={file?.id}
      />
    </>
  );
};

export default Peer2PeerAddNewContent;

import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { DEVICE } from '../../../constants/media';

const StyledWebinarButton = styled(({ ...props }) => (
	<Button {...props} />
))`
   && {
          width: 100%;
		  height: 50px;
          border-radius: 8px;
          margin-bottom: 15px;
          background: var(--primaryRed);
          box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.07);
          font-size: 16px;
		  font-weight: 900;
		  font-stretch: normal;
		  font-style: normal;
		  padding-top: 15px;
		  line-height: 1.38;
		  letter-spacing: -0.09px;
		  text-align: left;
		  color: var(--white);
		  padding-left: 12px;
		  border: none;
		  opacity: ${props => props.disabled ? .6 : 1};
		  
		  @media ${DEVICE.tablet} {
		    padding-left: 100px;
		  }
		  
		  &:hover {
		    border: none;
		  }
		  i {
		     position: absolute;
		        left: 14px;
		        top: 14px;
		        
		     @media ${DEVICE.tablet} {
		        left: 32px;
		     }
    
		  }
		  
    }
    
`

export default StyledWebinarButton;

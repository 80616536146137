import React from "react";
import { useDispatch } from "react-redux";

import ModalWrapper from "../../hoc/Modal/withPopup";
import Styled from "./Styled";
import { POPUPS_ID } from "../../constants";
import { setPopupStatusAction } from "../../services/popup/popup.action";
import Peer2PeerAddNewContent from "./Peer2peer.AddNewContent/Peer2Peer.AddNewContent";
import { removeFileAction } from "../../services/file/file.action";

const Peer2PeerNewContent = () => {
  const dispatch = useDispatch();
  return (
    <>
      <Styled.AddNewButton
        onClick={() =>
          dispatch(setPopupStatusAction(POPUPS_ID.ADD_NEW_CONTENT_POPUP))
        }
      >
        Add new Content
      </Styled.AddNewButton>
      <ModalWrapper
        destroyOnClose
        component={Peer2PeerAddNewContent}
        popupId={POPUPS_ID.ADD_NEW_CONTENT_POPUP}
        onClose={() => dispatch(removeFileAction("peer2peer"))}
      />
    </>
  );
};

export default Peer2PeerNewContent;

import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link, withRouter} from 'react-router-dom';
import {createStructuredSelector} from "reselect";

import StyledAside from './styled/StyledAside';
import StyledBackBtn from './styled/StyledBackBtn';
import StyledNav from './styled/StyledNav';
import StyledLogo from './styled/StyledLogo';
import StyledCounter from './styled/StyledCounter';
import {getUserInfoSelector} from '../../services/user/user.selector'
import AsideLogo from '../../assets/img/aside-logo.svg';
import BackArrow from '../../assets/img/icon-arrow.svg';
import {urlLocations, history} from "../../routes/urlLocation";
import LogoutPopup from "../../components/LogoutPopup/LogoutPopup";
import {setPopupStatusAction} from '../../services/popup/popup.action';
import {POPUPS_ID} from "../../constants";
import ContactUsPopUp from "../ContactUsPopUp/ContactUsPopUp";
import BackBtn from '../../assets/img/side_menu_button_bg.png';
import ClaimPointsPopup from "../ClaimPoints/ClaimPoints.Popup";
import {isMobile} from "react-device-detect";
import sanofiImg from "../../assets/img/sanofi.png";

const Aside = (
    {
        user:
            {cmePoints},
        setPopupStatus,
        location: {pathname},
        collapsed,
        onCollapse
    }) => {

    return (
        <StyledAside
            bg={BackBtn}
            trigger={null}
            collapsible
            width={isMobile ? 0 : 249}
            collapsedWidth={isMobile ? 320 : 249}
            collapsed={collapsed}
        >
            <StyledLogo>
                <Link to={urlLocations.main}>
                    <img src={AsideLogo} alt="Digimex"/>
                </Link>
            </StyledLogo>
            <StyledBackBtn
                isMobileAttr={isMobile}
                collapsed={collapsed}

            >
                <div onClick={
                    () => {
                        !isMobile ? (urlLocations.main !== history.location.pathname
                            && history.goBack()) : onCollapse();
                    }}>
                    <img src={BackArrow} alt="Go back"/>
                </div>
            </StyledBackBtn>

            <StyledNav>
                <ul>
                    <li className={pathname === urlLocations.main ? 'active' : ''}>
                        <Link
                            to={urlLocations.main}
                            onClick={() => {
                                isMobile && onCollapse()
                            }}
                        >
                            Program Sections
                        </Link>
                    </li>
                    {/*<li className={pathname === urlLocations.peer2peer ? 'active' : ''}>*/}
                    {/*    <Link*/}
                    {/*        to={urlLocations.peer2peer}*/}
                    {/*        onClick={() => {*/}
                    {/*            isMobile && onCollapse()*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Peer 2 Peer*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <li className={pathname === urlLocations.sanofi ? 'active' : ''}>
                        <Link
                            to={urlLocations.sanofi}
                            onClick={() => {
                                isMobile && onCollapse()
                            }}
                        >
                            Meet Sanofi
                        </Link>
                    </li>
                    <li>
                        <div onClick={() => {
                            setPopupStatus(POPUPS_ID.CLAIM_POINTS_POPUP);
                            isMobile && onCollapse();
                        }}>
                            Claim my CME points
                            <StyledCounter>{cmePoints ?? 0}</StyledCounter>
                        </div>
                    </li>
                    <li className={pathname === urlLocations.catalogue ? 'active' : ''}>
                        <Link
                            to={urlLocations.catalogue}
                            onClick={() => {
                                isMobile && onCollapse()
                            }}
                        >
                            Digimex Catalogue
                        </Link>
                    </li>
                    <li className={pathname === urlLocations.favorites ? 'active' : ''}>
                        <Link
                            to={urlLocations.favorites}
                            onClick={onCollapse}
                        >
                            Favorites
                        </Link>
                    </li>
                    <li
                        className={
                            pathname === urlLocations.aboutUs ? 'active' : ''
                        }
                    >
                        <Link to={urlLocations.aboutUs} onClick={onCollapse}>About Digimex</Link>
                    </li>
                    <li>
                        <div onClick={
                            () => {
                                setPopupStatus(POPUPS_ID.CONTACT_US_POPUP);
                                isMobile && onCollapse();
                            }
                        }>Contact Us
                        </div>
                    </li>
                    <li>
                        <div onClick={
                            () => {
                                setPopupStatus(POPUPS_ID.LOGOUT_POPUP);
                                isMobile && onCollapse();
                            }
                        }>Logout
                        </div>
                    </li>
                </ul>
            </StyledNav>
            <LogoutPopup/>
            <ContactUsPopUp/>
            <ClaimPointsPopup/>
        </StyledAside>
    )
};


export default compose(
    withRouter,
    connect(
        createStructuredSelector({
            user: getUserInfoSelector
        }),
        {setPopupStatus: setPopupStatusAction})
)(Aside)



import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledWebinarButtons = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 50px;
	
	@media ${DEVICE.tablet} {
		 width: 250px;
	}	
`
export default StyledWebinarButtons;
import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledMainPageAside = styled.div`
    height: 100%;
    background: var(--lightWhite);
    p {
      width: 100%;
      font-family: var(--fontAvenirBook);
	  font-size: 18px;
	  font-weight: normal;
	  font-stretch: normal;
	  line-height: 1.56;
	  letter-spacing: normal;
	  text-align: center;
	  color: var(--greySubtitle);
    } 
`;

const DesktopText = styled.div`
      @media ${DEVICE.mobileDevices} {
        display: none;
      }
`;

const MobileText = styled.div`
      display: none;
      @media ${DEVICE.mobileDevices} {
        display: block;
      }
`;

export default {
    StyledMainPageAside,
    DesktopText,
    MobileText
};
import initialState from "../../store/initialState";
import { GET_USER_INTERESTS_SUCCESS } from "./userInterests.action";

export default (state = initialState.userInterests, action) => {
  switch (action.type) {
    case GET_USER_INTERESTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';
import { isTablet } from "react-device-detect";

const StyledProductPage = styled.div`
    padding: 0 20px 0 20px;
 
    @media ${DEVICE.mobileTabletDevices} {
           padding: ${ isTablet ?  '0 20px 35px 20px;  ': '0 20px 0 20px;'};  
    }
     
    @media ${DEVICE.laptopL} {
        padding: 30px 40px 35px 40px; 
    }
    
     
`
export default StyledProductPage;
import { put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  GET_EXPERTS,
  GET_EXPERTS_FAILURE,
  GET_EXPERTS_SUCCESS,
  ASK_EXPERT_FAILURE,
  ASK_EXPERT_SUCCESS,
  ASK_EXPERT
} from "./experts.action";
import { SET_POPUP_STATUS } from "../popup/popup.action";
import { urlLocations } from "../../routes/urlLocation";
import { isUrlMatch } from "../router/router.utils";
import { expertsRequest, askExpertRequest } from "./experts.api";

function* getExpertsOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.experts)) {
      const { search } = payload.location;
      const params = Object.fromEntries(new URLSearchParams(search));
      yield put({
        type: GET_EXPERTS,
        ...( params && { payload: { params } } )
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchExperts({ payload }) {
  try {
    const { data, status } = yield expertsRequest({ params: payload.params });
    if (status === 200) {
      yield put({
        type: GET_EXPERTS_SUCCESS,
        payload: { ...data }
      });
    }
  } catch (error) {
    yield put({ type: GET_EXPERTS_FAILURE });
    console.log(error);
  }
}

function* askExpert({ payload }) {
  try {
    const { status } = yield askExpertRequest({ ...payload });
    if (status === 200) {
      yield put({
        type: ASK_EXPERT_SUCCESS
      });
    }
  } catch (error) {
    yield put({ type: ASK_EXPERT_FAILURE });
    console.log(error);
  } finally {
    yield put({
      type: SET_POPUP_STATUS,
      payload: { status: null }
    });
  }
}

export default function* expertsSaga() {
  yield takeEvery(LOCATION_CHANGE, getExpertsOnLocationChange);
  yield takeEvery(GET_EXPERTS, fetchExperts);
  yield takeEvery(ASK_EXPERT, askExpert);
}

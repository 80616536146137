import styled from "styled-components";
import Input from "../../../components/Input/Input";

const Title = styled.div`
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
`;

const StyledInput = styled(Input)`
  margin-bottom: 16px;
`;

export default {
  Title,
  Input: StyledInput,
};

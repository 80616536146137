import styled from 'styled-components';
import { Layout } from 'antd'

const StyledAutorizedLayout = styled(Layout)`
	  background: var(--lightWhite);
	  
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-flex: 1;
	      -ms-flex: auto;
	          flex: auto;
	  -webkit-box-orient: horizontal;
	  -webkit-box-direction: normal;
	      -ms-flex-direction: row;
	             flex-direction: row;
	  box-sizing: border-box;
`
export default StyledAutorizedLayout;
import React from "react";
import { Tabs } from "antd";

import Styled from "../Styled";

const { TabPane } = Tabs;

const TABS = [
  {
    tab: "All posts",
    key: "",
  },
  {
    tab: "Events & Announcements",
    key: "ANNOUNCEMENT",
  },
];

const Peer2PeerFilters = ({ onChange }) => {
  return (
    <Styled.Filters>
      <Styled.FilterTabs defaultActiveKey="" onChange={onChange}>
        {TABS.map(({ tab, key }) => (
          <Styled.FilterTab key={key} tab={tab} />
        ))}
      </Styled.FilterTabs>
    </Styled.Filters>
  );
};

export default Peer2PeerFilters;

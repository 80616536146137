import { put, takeEvery } from "redux-saga/effects";
import {
  CONTACT_US,
  CONTACT_US_FAILURE,
  CONTACT_US_SUCCESS,
} from "./contactUs.action";

import { contactUsRequest } from "./contactUs.api";
import { SET_POPUP_STATUS } from "../popup/popup.action";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* contactUs({ payload }) {
  try {
    yield put(startLoaderAction());
    const { status } = yield contactUsRequest({ ...payload });
    if (status === 200) {
      yield put({ type: CONTACT_US_SUCCESS });
      yield put({ type: SET_POPUP_STATUS, payload: { status: null } });
    }
  } catch (error) {
    yield put({ type: CONTACT_US_FAILURE });

    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* contactUsSaga() {
  yield takeEvery(CONTACT_US, contactUs);
}

import styled from "styled-components";
import { Select } from "antd";

const StyledSelect = styled(Select)`
  && {
    min-width: 100%;
    & .ant-select-selector {
      border: 1px solid var(--borderInput);
      background-color: var(--white);
      position: relative;
      padding: 9px 20px;
      color: var(--defaultTextColor);
      min-height: 48px;
      border-radius: 4px;
    }

    &[disabled] {
      color: var(--ligthBlue);
    }
    &:hover {
      border-color: var(--borderInput);
    }
  }
`;

export default StyledSelect;

import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';
import { isTablet, isMobile, isMobileOnly } from "react-device-detect";

const StyledSanofiList = styled.div`
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      font-family: var(--fontAvenirBook);
      margin: -40px auto;  
      @media ${DEVICE.tablet} {
        padding: 0;  
        margin: 0 auto;
        
      }
      @media ${DEVICE.mobileDevices} {
            justify-content: space-between; 
            margin: 0 -8px;
      }
      
      @media (max-width: 820px) and (orientation: landscape) {
         max-width: ${ isMobileOnly && '590px;'};
         margin: 0 auto;
      }
      max-width: ${ (!isMobile || isTablet) ? 'auto' : isMobileOnly ? '350px;' : '100%'};
`
export default StyledSanofiList;
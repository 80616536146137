import { createAction } from "redux-actions";

export const GET_SESSIONS = "GET_SESSIONS";
export const GET_SESSIONS_SUCCESS = "GET_SESSIONS_SUCCESS";
export const GET_SESSIONS_FAILURE = "GET_SESSIONS_FAILURE";

export const SET_SESSION_LOG = "SET_SESSION_LOG";
export const SET_SESSION_LOG_SUCCESS = "SET_SESSION_LOG_SUCCESS";
export const SET_SESSION_LOG_FAILURE = "SET_SESSION_LOG_FAILURE";

export const getSessionsAction = createAction(GET_SESSIONS);
export const setSessionLogAction = createAction(SET_SESSION_LOG);

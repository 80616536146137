import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import orderBy from "lodash/orderBy";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";

import Peer2PeerPost from "../Peer2peer.Posts/Peer2peer.Post";
import {
  postsCountSelector,
  postsSelector,
} from "../../../services/peer2peer/peer2peer.selector";
import { getPostAction } from "../../../services/peer2peer/peer2peer.actions";
const Peer2PeerPostsList = ({ filter, selectedInterests, selectInterest }) => {
  const [params, setParams] = useState({
    _limit: 20,
    _start: 0,
  });
  const dispatch = useDispatch();
  const posts = useSelector(postsSelector);
  const dataLength = useSelector(postsCountSelector);

  const filteredPosts = !!filter
    ? posts?.filter((post) => post?.postType === filter)
    : posts;

  const selectedPosts = !selectedInterests?.length
    ? filteredPosts
    : filteredPosts?.filter((post) =>
        selectedInterests?.includes(post?.interest?.id)
      );

  const handleNext = () => {
    dispatch(
      getPostAction({
        _limit: 20,
        _start: params._start + params._limit,
      })
    );
    setParams({
      _limit: 20,
      _start: params._start + params._limit,
    });
  };

  return (
    <InfiniteScroll
      hasMore={posts?.length < dataLength}
      loader={Spin}
      dataLength={posts?.length}
      next={handleNext}
      pullDownToRefresh
      pullDownToRefreshThreshold={50}
      refreshFunction={handleNext}
    >
      {orderBy(selectedPosts, "published_at", "desc")?.map((post) => (
        <Peer2PeerPost
          key={post.id}
          selectInterest={selectInterest}
          selectedInterests={selectedInterests}
          {...post}
        />
      ))}
    </InfiniteScroll>
  );
};

export default Peer2PeerPostsList;

import React, { useEffect } from 'react';

import { history, urlLocations } from "../../routes/urlLocation";

export default ({ children }) => {
    useEffect(() => {
        const storage = localStorage.getItem("storageTyp");
        const jwt = window[storage]?.getItem('jwt');
        jwt && history.push(urlLocations.main)
    }, []);
    return <div>
        { children }
    </div>
};


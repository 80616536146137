import moment from "moment";

export const getDay = time => moment(time).format("D");
export const getMonthAndYear = time => moment(time).format("MMMM YYYY");
export const getWebinareFormatedDate = time =>
  moment(time).format("Do MMMM YYYY [at] h:mm A");

export const getDayOfWeek = time => moment(time).format("dddd");

export const isWebinarPast = webinar => {
  const duration = webinar.durationMins;
  const startTime = moment(webinar.startTime).add(duration, "minutes");
  return moment().diff(startTime) > 0;
};

export const createWebinarEndDate = ({ startTime, durationMins }) => {
  return moment(startTime)
    .add(durationMins, "minutes")
    .toISOString();
};

export const isWebinarLive = ({ startTime, durationMins }) =>
  moment().isBetween(
    startTime,
    createWebinarEndDate({ startTime, durationMins })
  );

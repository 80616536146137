import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledSessionsList = styled.div`
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    overflow-x: auto;
    flex-wrap: nowrap;
   
     @media ${DEVICE.laptopL} {
	      overflow-x: auto;
	      flex-wrap: wrap;
	      margin-left: -20px;
          margin-right: -20px;
	 }
`
export default StyledSessionsList;
import styled from "styled-components";
import { DEVICE } from "../../../constants/media";

const StyledLogo = styled.div`
  display: flex;
  justify-content: center;

  @media ${DEVICE.mobileTabletDevices} {
    img {
      max-width: 210px;
    }
  }

  @media ${DEVICE.mobileDevices} {
    margin-bottom: 35px;
    img {
      max-width: 145px;
    }
  }

  a {
    display: inline-block;
  }
`;

export default StyledLogo;

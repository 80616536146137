import React from 'react';
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import isEmpty from "lodash/isEmpty";

import WebinarsList from './WebinarsPage.WebinarsList/WebinarsPage.WebinarsList';
import Interests from '../../components/Interests/Interests';
import Sessions from '../../components/Sessions/Sessions';
import {
    upcomingWebinarsSelector,
    pastWebinarsSelector,
    webinarsSelector,
} from "../../services/webinars/webinars.selector";
import { interestsSelector } from "../../services/interests/interests.selector";
import { getSelectedInterestInfoSelector } from '../../services/user/user.selector';
import { sessionSelector } from '../../services/sessions/sessions.selector';
import StyledWebinarsPage from "./styled/StyledWebinarsPage"
import {useLocation} from "react-router";

const WebinarsPage = (
    {
        upcomingWebinars,
        pastWebinars,
        interests,
        selectedInterestInfo,
        sessions,
        webinars
    }) => {
    const { search } = useLocation()
    return (
        <StyledWebinarsPage>

            { !search &&  <Interests/> }

            { !isEmpty(upcomingWebinars) && <WebinarsList
                webinars={upcomingWebinars}
                title={'Upcoming Webinars'}
                interests={interests}
                selectedInterestInfo={selectedInterestInfo}
            /> }
            { !isEmpty(pastWebinars) && <WebinarsList
                webinars={pastWebinars}
                title={'Past Webinars'}
                interests={interests}
                selectedInterestInfo={selectedInterestInfo}
            /> }
            { !!sessions && !search &&
                <Sessions
                    title={"Other Resources"}
                    sessions={sessions.map(session => session.video)}
                />
            }
        </StyledWebinarsPage>);
}

export default connect(createStructuredSelector({
    webinars: webinarsSelector,
    upcomingWebinars: upcomingWebinarsSelector,
    pastWebinars: pastWebinarsSelector,
    interests: interestsSelector,
    selectedInterestInfo: getSelectedInterestInfoSelector,
    sessions: sessionSelector
}))(WebinarsPage)

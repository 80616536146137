import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledSpecialityItem = styled.div`
      display: flex;
      flex-wrap: nowrap;
      margin-left: -5px;
	  margin-right: -5px;
	  overflow-x: auto;
	  flex-wrap: nowrap;
	  
	   @media ${DEVICE.laptop} {
		  margin-left: -10px;
		  margin-right: -10px;
	   }
	  
	  @media ${DEVICE.laptopL} {
	    flex-wrap: wrap;
	    margin-left: -17px;
	    margin-right: -17px;
	  }
	  
	  & > p {
	      padding-left: 20px;
	      
	  }
`
export default StyledSpecialityItem;
import { put, select, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import isEmpty from "lodash/isEmpty";

import {
  GET_USER_INTERESTS,
  GET_USER_INTERESTS_FAILURE,
  GET_USER_INTERESTS_SUCCESS,
} from "./userInterests.action";

import { userInterestRequest } from "./userInterests.api";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* getUserInterestsOnLocationChangeData() {
  try {
    const userInterests = yield select((state) => state.userInterests);
    const storage = localStorage?.getItem("storageTyp") || "localStorage";

    if (isEmpty(userInterests) && window[storage].getItem("jwt")) {
      yield put({ type: GET_USER_INTERESTS });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchUserInterestsData() {
  try {
    yield put(startLoaderAction());

    const { data, status } = yield userInterestRequest();
    if (status === 200) {
      yield put({
        type: GET_USER_INTERESTS_SUCCESS,
        payload: { ...data },
      });
    }
  } catch (error) {
    yield put({ type: GET_USER_INTERESTS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* userInterestsSaga() {
  yield takeEvery(LOCATION_CHANGE, getUserInterestsOnLocationChangeData);
  yield takeEvery(GET_USER_INTERESTS, fetchUserInterestsData);
}

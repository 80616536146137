import styled from 'styled-components';
import React from 'react';
import { Modal } from 'antd';
import { DEVICE } from '../../../constants/media';
import { isTablet, isMobileOnly } from "react-device-detect";

const StyledModal = styled(
	({ ...props }) => <Modal {...props} />
)`
	&& {
		border-radius: 14px;
		background-color: var(--bgPopup);
		min-height: 280px;
		padding-bottom: 0;
		
		.ant-modal-content {
			box-shadow: none;
			border-radius: 14px;
			min-width: 100%;
			min-height: 280px;
			padding:  40px 20px 30px 20px;
			background-color: var(--bgPopup);
			
			@media ${DEVICE.tablet} {
                padding:  55px 30px 40px;
            }
			
		}
		
		.ant-modal-close-x {
		    width: 29px;
		    height: 30px;
		    position: absolute;
		    z-index: 120;
		    top: -23px;
		    right: -27px;
		}
		
		.ant-modal-body {
			padding: 0;
		}
		
		h3 {
		  font-family: var(--fontAvenirHeavy);
		  font-size: 24px;
		  font-weight: 900;
		  line-height: 1.23;
		  letter-spacing: normal;
		  color: var(--modalTitle);
		  margin-bottom: 27px;
		  
		  @media ${DEVICE.laptopL} {
             font-size: 44px;
          }
		}
		.ant-btn {
		
			@media (max-width: 1200px) {
                width: ${ isTablet ?  '45%;': isMobileOnly ?  '100%;' : '230px'}; 
                margin-bottom: 15px;
                margin-right: ${ !isMobileOnly ?  '20px': '0'};
                font-size: ${ isTablet ?  '17px;': '16px;'};
            }
		}
	}
`
export default StyledModal;
import styled from 'styled-components'

const StyledWebinarWrapper = styled.div`
	width: 100%;
    
    h3 {
      padding-top: 20px;
      padding-bottom: 20px;
      font-family: var(--fontAvenirMedium);
	  font-size: 18px;
	  font-weight: 500;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.39;
	  letter-spacing: -0.1px;
	  text-align: left;
	  color: var(--titleCards);
    }
`
export default StyledWebinarWrapper;
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";

import Styled from "../Styled";
import { selectFile } from "../../../services/file/file.selector";
import { removeFileAction } from "../../../services/file/file.action";

const Peer2PeerUploaded = () => {
  const dispatch = useDispatch();
  const file = useSelector((state) => selectFile(state, "peer2peer"));
  return (
    <div>
      {!!file && (
        <Styled.Uploaded>
          <span>Uploaded: </span>
          <a target="_blank" href={file?.url}>
            {file?.name}
          </a>
          <DeleteOutlined
            onClick={() => dispatch(removeFileAction("peer2peer"))}
          />
        </Styled.Uploaded>
      )}
    </div>
  );
};

export default Peer2PeerUploaded;

import styled from "styled-components";
import { DEVICE } from "../../constants/media";

const Page = styled.div`
  padding: 90px 63px 45px 63px;
  background-image: var(--gradientHomePage);
  min-height: 100vh;
  overflow-y: auto;
  @media ${DEVICE.mobileTabletDevices} {
    padding: 40px 30px;
  }
  @media ${DEVICE.mobileDevices} {
    margin-top: 20px;
  }
`;

const Title = styled.div`
  font-family: var(--fontAvenirHeavy);
  font-size: 44px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: var(--white);
  padding-bottom: 50px;
`;

const FormWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -15px;
  & > * {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    padding: 0 15px;
    @media ${DEVICE.mobileTabletDevices} {
      flex-basis: 100%;
    }
  }
`;
const Message = styled.div`
  color: var(--white);
  @media ${DEVICE.mobileTabletDevices} {
    display: block;
    margin-bottom: 15px;
  }
  a {
    color: var(--white);
    text-decoration: underline;
  }
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AgreeText = styled.span`
  color: var(--white);
  a {
    color: var(--white);
    text-decoration: underline;
  }
`;

export default {
  Page,
  Title,
  FormWrap,
  Message,
  FormFooter,
  AgreeText,
};

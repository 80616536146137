import React from 'react';
import StyledExpertPreview from './styled/StyledExpertPreview'

const ExpertPreview = ({ src, name, title, color }) =>

    <StyledExpertPreview color={ color }>
        <img width="50" src={ src } alt="" />
        <div>
            <h4>{ name }</h4>
            <h5>{ title }</h5>
        </div>
    </StyledExpertPreview>;

export default ExpertPreview;
import React from "react";
import { isMobileOnly } from "react-device-detect";
import LoginForm from "./MainPage.LoginForm";
import StyledMainPageLogin from "./styled/StyledMainPage";
import AppLinkPanel from "../../../components/AppLinkPanel/AppLinkPanel";
import MainPageResendPopup from "../MainPage.ResendPopup/MainPage.ResendPopup";

const MainPageLogin = () => (
  <StyledMainPageLogin>
    <h1>
      Welcome to
      <br />
      Digimex Program
    </h1>
    <LoginForm />

    {isMobileOnly && <AppLinkPanel></AppLinkPanel>}
    <MainPageResendPopup />
  </StyledMainPageLogin>
);

export default MainPageLogin;

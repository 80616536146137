export const SIZE = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 420,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 1800
};

export const DEVICE = {
  mobileS: `(min-width: ${SIZE.mobileS}px)`,
  mobileM: `(min-width: ${SIZE.mobileM}px)`,
  mobileL: `(min-width: ${SIZE.mobileL}px)`,
  tablet: `(min-width: ${SIZE.tablet}px)`,
  laptop: `(min-width: ${SIZE.laptop}px)`,
  laptopL: `(min-width: ${SIZE.laptopL}px)`,
  desktop: `(min-width: ${SIZE.desktop}px)`,
  desktopL: `(min-width: ${SIZE.desktop}px)`,
  mobileDevices: `(max-width: ${SIZE.tablet - 1}px)`,
  mobileTabletDevices: `(max-width: ${SIZE.laptopL - 1}px)`
};

//for using in styled components @media ${DEVICE.desktop} {...}

import React from 'react';
import { Input } from 'antd';

const TextArea = ({ input,...props }) => (
    <Input.TextArea
        {...props}
        {...input}
    />
);

export default TextArea;
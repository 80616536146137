import styled from "styled-components";
import { DEVICE } from "../../../constants/media";

const StyledWebinarSpeakersRow = styled.div`
  margin: 0 -15px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  @media ${DEVICE.mobileDevices} {
    flex-direction: column;
  }
`;

const StyledWebinarSpeakersCol = styled.div`
  padding: 0 15px;
  flex: 1;
`;

export default {
  Row: StyledWebinarSpeakersRow,
  Col: StyledWebinarSpeakersCol
};

import { createAction } from "redux-actions";

export const GET_POSTS = "GET_POSTS";
export const GET_POSTS_FAILURE = "GET_POSTS_FAILURE";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";

export const GET_POSTS_COUNT = "GET_POSTS_COUNT";
export const GET_POSTS_COUNT_FAILURE = "GET_POSTS_COUNT_FAILURE";
export const GET_POSTS_COUNT_SUCCESS = "GET_POSTS_COUNT_SUCCESS";

export const GET_PENDING_POSTS_COUNT = "GET_PENDING_POSTS_COUNT";
export const GET_PENDING_POSTS_COUNT_FAILURE =
  "GET_PENDING_POSTS_COUNT_FAILURE";
export const GET_PENDING_POSTS_COUNT_SUCCESS =
  "GET_PENDING_POSTS_COUNT_SUCCESS";

export const CREATE_POST = "CREATE_POST";
export const CREATE_POST_FAILURE = "CREATE_POST_FAILURE";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";

export const SET_POSTS = "SET_POSTS";

export const TOGGLE_POST_LIKE = "TOGGLE_POST_LIKE";
export const TOGGLE_POST_LIKE_FAILURE = "TOGGLE_POST_LIKE_FAILURE";
export const TOGGLE_POST_LIKE_SUCCESS = "TOGGLE_POST_LIKE_SUCCESS";

export const ADD_POST_COMMENT = "ADD_POST_COMMENT";
export const ADD_POST_COMMENT_FAILURE = "ADD_POST_COMMENT_FAILURE";
export const ADD_POST_COMMENT_SUCCESS = "ADD_POST_COMMENT_SUCCESS";

export const createPostAction = createAction(CREATE_POST);
export const getPostAction = createAction(GET_POSTS);
export const togglePostLikeAction = createAction(TOGGLE_POST_LIKE);
export const addPostCommentAction = createAction(ADD_POST_COMMENT);

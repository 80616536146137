import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import isEmpty from 'lodash/isEmpty';

import {history, urlLocations} from "../../routes/urlLocation";
import StyledResultBlock from '../styled/StyledResultBlock';
import StyledResultNav from '../styled/StyledResultNav';
import {getExamResult} from "../../services/exams/exams.selector";

const ExamSuccessPage = ({result}) => {
    useEffect(() => {
        if (isEmpty(result)) {
            history.push(urlLocations.exams)
        }
    }, []);
    return (
        <StyledResultBlock>
            <div>
                <h2>Congratulations !</h2>
                <div>
                    {result.cmePointsEarned > 0 ?
                        <p>
                            You have earned <b style={{color: '#498E00'}}>{result.cmePointsEarned} CME</b> points by passing this exam.
                            You can submit a request to claim them at any time from the side menu, and we will take care of the rest.
                        </p>
                        :
                        <p>
                            You have passed this exam successfully.
                            Looking forward to seeing your answers for the other exams too
                        </p>
                    }
                </div>

                <StyledResultNav>
                    <Link to={urlLocations.main}>Back to Main Page</Link>
                </StyledResultNav>
            </div>
        </StyledResultBlock>
    )
};

export default connect(
    createStructuredSelector({
        result: getExamResult
    })
)(ExamSuccessPage);

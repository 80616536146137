import React from 'react';
import {Field, reduxForm} from "redux-form";
import StyledInput from "../../../components/Input/Input";
import TextArea from "../../../components/Textarea/Textarea";
import StyledButton from "../../../components/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import {askExpertAction} from "../../../services/experts/experts.action";
import {Form} from "antd";

const FormItem = Form.Item;



const ExpertPopupForm = ({ handleSubmit }) => (
    <form onSubmit={handleSubmit} >
        <h3>Ask Expert</h3>
        <FormItem>
            <Field
                component={ StyledInput }
                placeholder='Subject'
                name='subject'
            />
        </FormItem>
        <FormItem>
            <Field
                component={ TextArea }
                autoSize={{ minRows: 5, maxRows: 10 }}
                placeholder="Question details"
                name="content"
            />
        </FormItem>
        <StyledButton size="small" htmlType="submit" >Ask</StyledButton>

    </form>);

export default compose(
    connect(
        () => ({}),
        {
            askExpert: askExpertAction
        }
    ),
    reduxForm({
        form: 'askForm',
	    onSubmit: ({ subject, content },dispatch, { expertId, askExpert }) => {
            askExpert({ subject,  content, expert: expertId});
        }
    }),

)(ExpertPopupForm)
import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';
import { isTablet , isMobileOnly } from "react-device-detect";

const StyledExpertsPageCard = styled.div`
    display: flex;
	min-height: 150px;
	border-radius: 14px;
	background-color: var(--lightWhite:);
	padding: 22px 5px;
	margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
        
    @media ${DEVICE.mobileTabletDevices} {
	    margin-bottom: ${ isTablet ?  '45px': '30px'};
	    padding: ${ isTablet ? '22px 10px;': '15px 5px;'};
    }   
    transition: all 0.3s ease-in;
    width: 150px;
	  
    & a > div {
        width: 100%;
        text-align: center;
        height:  ${ !isMobileOnly ?  '170px': '100px;'};
    }
    @media ${DEVICE.tablet} {
        width: 25%;
        padding: 22px 5px;
    }
    @media ${DEVICE.laptop} {
        width: 30%;
        padding: 22px 10px;
    }
    @media ${DEVICE.laptopL} {
        width: 254px;
        min-height: 300px;
        padding: 22px 13px;
    }
     a {
           display: flex;
           flex-wrap: wrap;
           justify-content: center;
     }
	&:hover {
		background-color: var(--white);
		box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.07);
	}
	img {
		width: 99px;
	    height: 99px;
	    object-fit: cover;
	    border-radius: 50%;
		border: solid 4px ${({color}) => color};
		@media ${DEVICE.laptop} {
	       width: 165px;
	       height: 165px;
	       border: solid 8px ${({color}) => color};
        }
	}
	h4 {
	  font-family: var(--fontAvenirHeavy);
	  font-size: 16px;
	  font-weight: 900;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.38;
	  letter-spacing: -0.13px;
	  color: var(--titleBlue);
	  padding-top: 15px;
	  text-align: center; 
	  margin: 0;
	  width: 100%;
	  padding-bottom: 5px;
	  min-height: 62px;
	   @media ${DEVICE.mobileTabletDevices} {
	        min-height: 66px;
	   }
	  
	  @media ${DEVICE.laptop} {
	      font-size: 23px;
	      padding-bottom: 7px;
	      padding-top: 25px;
      }
	}
	p {

		  font-family: var(--fontAvenirMedium);
		  font-size: 14px;
		  font-weight: 500;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.35;
		  letter-spacing: -0.11px;
		  color: var(--subtitleCards);
		  text-align: center;
		  width: 100%;
		  
		  @media ${DEVICE.mobileTabletDevices} {
		    min-height: 54px;
		  }
		  @media ${DEVICE.laptop} {
              font-size: 19px;  
          }
          @media ${DEVICE.laptopL} {
              min-height: 75px;
          }
          
          
	}
`
export default StyledExpertsPageCard;
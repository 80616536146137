import React from 'react';
import {Link} from "react-router-dom";
import {urlLocations} from "../../../routes/urlLocation";
import StyledExpertsPageCard from "./styled/StyledExpertsPageCard";
import TextTruncate from 'react-text-truncate';
import { isMobileOnly } from "react-device-detect";

const ExpertCard = (
    { expert: {
        id,
        interests,
        avatar: { url },
        name,
        hospital,
    } = {
        id: null,
        interests: [],
        avatar: { url: "" },
        name: "",
        hospital: ""
    } }) => (
        <StyledExpertsPageCard color={interests[0].color} >
            <Link key={id} to={`${urlLocations.experts}/${id}`}>
                <div>
                    <img width="50" src={url} alt=""/>
                </div>
                <h4>
                    <TextTruncate
                        line={isMobileOnly ? 4 : 3 }
                        truncateText="…"
                        text={ name }
                    />
                </h4>

                <p>
                    <TextTruncate
                        line={isMobileOnly ? 4 : 3 }
                        truncateText="…"
                        text={ hospital }
                    />
                </p>
            </Link>
        </StyledExpertsPageCard>
    );

export default ExpertCard
import styled from "styled-components";
import { Upload } from "antd";
import { DEVICE } from "../../constants/media";

const UploaderRemove = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--brightBlue);
  opacity: 0;
  transition: 0.3s opacity;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    cursor: pointer;
    width: 40px;
    height: inherit;
    color: white;
  }
`;

const Uploader = styled(Upload)`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 9px;
  margin-bottom: 25px;
  background: var(--white);
  border: 1px solid var(--darkBlue);
  overflow: hidden;
  .ant-upload {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    width: 40px;
    transform: translateY(0);
    transition: 0.3s transform;
    height: inherit;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    svg {
      transform: translateY(-3px);
    }
    ${UploaderRemove} {
      opacity: 0.9;
    }
  }
`;

const UploaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media ${DEVICE.mobileDevices} {
    display: flex;
    flex-direction: column;
  }
`;

const UploaderInfo = styled.div`
  display: flex;
  flex: 1;
  padding-left: 50px;
  color: ${({ darkThema }) => (darkThema ? "inherit" : "white")};
  max-width: 400px;
  @media ${DEVICE.mobileDevices} {
    padding: 0;
    margin: 10px 0;
  }
`;

export default {
  UploaderInfo,
  UploaderWrapper,
  UploaderRemove,
  Uploader,
};

import { SET_INTEREST, CLEAR_INTERESTS } from "./interests.action";
import initialState from "../../store/initialState";

export default (state = initialState.interests, action) => {
  switch (action.type) {
    case SET_INTEREST:
      return action.payload;
    case CLEAR_INTERESTS:
      return "";
    default:
      return state;
  }
};

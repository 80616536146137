import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';
import { isTablet } from "react-device-detect";

const StyledProductList = styled.div`
  
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: ${ (isTablet) && '25px;'};
    margin: 0 -20px 50px -20px;
    max-width: 100%;
    
    @media ${DEVICE.mobileTabletDevices} {
        margin: 0 -8px;
        justify-content: ${ isTablet ?  'center;': ''};
        margin-top: 24px;
    }
     
    @media ${DEVICE.tablet} {
        margin: 0 auto; 
    }
    
     p {
        margin-top: 10px;
        var(--greyMainText);
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.71;      
     }
     .ant-row {
        margin-left: -20px;
        margin-right: -20px;
     }
     .ant-col-8 {
        width: 310px;
        height: 290px;
        margin: 0 20px 40px 20px;
        
	    @media ${DEVICE.laptop} {
            width: 210px;
		    margin: 0 10px 40px 10px;
        }
     
	    @media ${DEVICE.laptopL} {
		    width: 310px;
		    height: 290px;
		    min-width: 310px;
		    margin: 0 20px 40px 20px;   
	    }    
     }
`
export default StyledProductList;
import styled from "styled-components";

const Card = styled.div`
  margin: 50px;
  max-width: 200px;
  display: inline-block;
`;

const CatalogueItem = styled.a`
  display: block;
  width: 200px;
  height: 300px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    -webkit-box-shadow: 3px 3px 12px 0px rgba(50, 50, 50, 0.4);
    -moz-box-shadow: 3px 3px 12px 0px rgba(50, 50, 50, 0.4);
    box-shadow: 3px 3px 12px 0px rgba(50, 50, 50, 0.4);
  }
`;

const CatalogueItemTitle = styled.div`
  padding: 20px 0 0 0;
  font-family: var(--fontAvenirHeavy);
  font-size: 22px;
  color: var(--titleCards);
`;

export default {
  Card,
  CatalogueItem,
  CatalogueItemTitle
};

import React from "react";
import { Row } from "antd";
import { Switch, Route } from "react-router";

import Aside from "./MainPage.Aside/MainPage.Aside";
import Login from "./MainPage.Login/MainPage.Login";
import Styled from "./styled/StyledMainPage";
import Reset from "./MainPage.Reset/MainPage.Reset";
import { urlLocations } from "../../routes/urlLocation";
import RegistrationPage from "../RegistrationPage/RegistrationPage";

const MainPage = () => {
  return (
    <Styled.StyledMainPage>
      <Row>
        <Styled.MainCol span={10}>
          <Aside />
        </Styled.MainCol>

        <Styled.LoginCol span={14}>
          <Switch>
            <Route exact path={urlLocations.root} component={Login} />
            <Route exact path={urlLocations.resetPassword} component={Reset} />
            <Route
              exact
              path={urlLocations.registration}
              component={RegistrationPage}
            />
          </Switch>
        </Styled.LoginCol>
      </Row>
    </Styled.StyledMainPage>
  );
};
export default MainPage;

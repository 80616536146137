import React from 'react';
import { isMobileOnly } from "react-device-detect";
import StyledAppLinkPanel from './styled/StyledAppLinkPanel';
import AppStoreImg from '../../assets/img/appstore.svg';
import GooglePlayImg from '../../assets/img/gp.svg';


const AppLinkPanel = () => (
	<StyledAppLinkPanel>

		{ isMobileOnly && <h5> Or download our apps </h5> }
		<div>
			<a rel="noopener noreferrer" href="https://apps.apple.com/us/app/id1377656674" target={"_blank"}>
				<img src={AppStoreImg} alt="Download on the App Store" />
			</a>
			<a rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.otb.digimex" target={"_blank"}>
				<img src={GooglePlayImg} alt="Get it on Google Play" />
			</a>
		</div>
	</StyledAppLinkPanel>
);

export default AppLinkPanel;



import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';
import { isTablet  } from "react-device-detect";

const StyledExamsPage = styled.div`
      padding: 25px 20px 100px 20px;
      position: relative;
      @media ${DEVICE.laptop} {
         padding:  ${ isTablet ? '57px 20px 60px 20px;' : '57px 87px 60px 90px' };
      }
`
export default StyledExamsPage;
import styled from "styled-components";
import { Col } from "antd";
import { DEVICE } from "../../constants/media";

const Page = styled.div`
  padding: 57px 20px 80px 20px;

  @media ${DEVICE.laptopL} {
    padding: 57px 40px 80px 90px;
  }

  @media ${DEVICE.mobileTabletDevices} {
    form {
      & > .ant-col {
        margin-bottom: 30px;
      }
    }
  }
`;

const Title = styled.div`
  font-size: 30px;
  font-family: var(--fontAvenirHeavy);
  margin-bottom: 10px;
`;

const Column = styled(Col)`
  @media ${DEVICE.mobileTabletDevices} {
    margin-bottom: 35px;
    .ant-btn {
      width: 100%;
      max-width: 700px;
    }
    .ant-form-item-control-input-content {
      max-width: 700px;
    }
  }
`;

const Hospital = styled.div`
  margin-bottom: 65px;
`;

const SubTitle = styled.div`
  margin-bottom: 20px;
`;

const Affix = styled.div`
  color: var(--sessionCBlueColor);
  cursor: pointer;
`;

export default {
  Page,
  Title,
  Hospital,
  Column,
  Affix,
  SubTitle
};

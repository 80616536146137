import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledBio = styled.div`
	padding: 0 0 20px 0;
	
    @media ${DEVICE.laptop} {
       padding: 4px 58px 44px 0;
    }
	
    p {
      font-family: var(--fontLatoRegular);
	  font-size: 14px;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.93;
	  letter-spacing: 0.1px;
	  text-align: left;
	  color: var(--defaultTextColor);
	  padding-bottom: 15px;
    }
`
export default StyledBio;
import styled from "styled-components";
import { DEVICE } from "../../constants/media";
import { isTablet, isMobileOnly } from "react-device-detect";

const Title = styled.div`
  margin-bottom: 20px;
  font-family: var(--fontAvenirHeavy);
  font-size: 24px;

  @media ${DEVICE.laptop} {
    font-size: 34px;
  }
  @media ${DEVICE.laptopL} {
    font-size: 44px;
  }
`;

const Description = styled.div`
  margin-bottom: 15px;
`;

const List = styled.div`
  padding: 15px 0 75px;

  @media ${DEVICE.tablet} {
    padding: 15px 0 50px;
  }
`;

const ClaimItem = styled.div`
  padding: 15px;
  border-radius: 14px;
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.07);
  font-size: 20px;
  font-family: var(--fontAvenirHeavy);
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  background: white;

  @media ${DEVICE.mobileTabletDevices} {
    flex-wrap: wrap;
    color: #1e1c1c;
    padding: 17px;

    h4 {
      margin-top: 10px;
      display: block;
      width: 100%;
    }
  }
`;

const ClaimItemPoints = styled.span`
  color: #0092ff;
  margin: 0 16px;
`;

const Buttons = styled.div`
   @media ${DEVICE.mobileTabletDevices} {
     display: flex; 
     justify-content: ${isTablet ? "space-between" : "flex-start"};
     
  .ant-btn {
     //    height: ${isTablet ? "60px;" : "45px;"};
     //    width: ${isTablet ? "45%;" : "100%;"};
        margin-right: ${!isMobileOnly ? "20px" : "0"};
        margin-left: ${isMobileOnly ? "0" : "15px"};

    }
   }
   
   @media ${DEVICE.mobileDevices} {
      flex-wrap: wrap;
       .ant-btn {
        font-size: 16px;
     }
   }
  
`;

export default {
  Title,
  Description,
  ClaimItem,
  Buttons,
  ClaimItemPoints,
  List
};

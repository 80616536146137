import { put, takeEvery, select } from "redux-saga/effects";

import { fileUploadRequest } from "./file.api";
import {
  FILE_REMOVE,
  FILE_REMOVE_FAILURE,
  FILE_REMOVE_SUCCESS,
  FILE_UPLOAD,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_SUCCESS,
  FILES_UPLOAD,
} from "./file.action";
import { getFilteredFiles } from "./file.utils";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* fileUpload({ payload }) {
  try {
    yield put(startLoaderAction());
    const { response } = yield fileUploadRequest(payload.file);

    yield put({
      type: FILE_UPLOAD_SUCCESS,
      payload: {
        uploaderName: payload.uploaderName,
        file: response.data[0],
      },
    });
  } catch (error) {
    yield put({ type: FILE_UPLOAD_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* removeFile({ payload: uploaderName }) {
  try {
    yield put({ type: FILE_REMOVE_SUCCESS });
    yield put({
      type: FILE_UPLOAD_SUCCESS,
      payload: { uploaderName, file: null },
    });
  } catch (error) {
    yield put({ type: FILE_REMOVE_FAILURE });

    console.log(error);
  }
}

export default function* fileUploadSaga() {
  yield takeEvery(FILE_UPLOAD, fileUpload);
  yield takeEvery(FILE_REMOVE, removeFile);
}

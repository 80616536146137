import React from 'react'
import StyledPageArticle from './styled/StyledPageArticle';
import StyledArticleCol from './styled/StyledArticleCol';
import ImgPreviewArticle from '../../../assets/img/icon-preview.png';
import TextTruncate from 'react-text-truncate';
import StyledArticleImg from "./styled/StyledArticleImg";

const Article = ({ article: { fileData: { url }, interest: { color } ,thumbnail , title, authors } }) =>
        (<StyledPageArticle
            rel="noopener noreferrer"
            target="_blank"
            href={url}

        >
            <StyledArticleImg border={color} src={ thumbnail?.url || ImgPreviewArticle} alt="" />
            <StyledArticleCol>
                <h4> { title }</h4>
                <div>
                        <TextTruncate
                            line={3}
                            truncateText="…"
                            text={ authors  }
                        />
                </div>

            </StyledArticleCol>
        </StyledPageArticle>);

export default Article;
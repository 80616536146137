import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledNav = styled.nav`
	display: flex;
	flex-wrap: wrap;
	margin-top: 110px;
	
	@media ${DEVICE.laptop} {
	    margin-top: 60px;
	}
	
	ul {
		width: 100%;
		max-width: 270px;
		@media ${DEVICE.tablet} {
	        max-width: 290px;
		}
		@media ${DEVICE.laptop} {
	        width: 100%;
	        max-width: auto;
		}
	}
	
	li.active{
        color: var(--white) ;
	    font-family: var(--fontAvenirHeavy);
	    a{
            color: var(--white);
	        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.15) -98%, rgba(74, 144, 226, 0));
		    &:before {
		        content: '';
		        position: absolute;
		        left: 0;
		        top: 0;
		        width: 2px;
		        height: 49px;
		        background: rgba(255,255,255,0.4);
		    } 
	    }
	}
	
    li {
      width: 100%;
      cursor: pointer;
      height: 49px;
	  font-family: var(--fontAvenirMedium);
	  font-size: 15px;
	  font-weight: 500;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.33;
	  letter-spacing: normal;
	  text-align: left;
	  color: var(--white);
	 
	  margin-bottom: 4px;
	  position: relative;
	  display: flex;
	  box-sizing: border-box;
	  transition: all 0.3s ease-in;
	 
	  
	  a,div {
	    padding: 16px 10px 13px 40px;
		width: 100%;
		height: 100%;
	    color: var(--asideTxtTitle) 
	  }
	  
	  &.active {
	    color: var(--white) ;
	    font-family: var(--fontAvenirHeavy);
	    // background-image: linear-gradient(to right, rgba(255, 255, 255, 0.15) -98%, rgba(74, 144, 226, 0));
	    &:before {
	        content: '';
	        position: absolute;
	        left: 0;
	        top: 0;
	        width: 2px;
	        height: 49px;
	        background: rgba(255,255,255,0.4);
	    }
	  }
	  &:hover {
	    color: var(--white) ;
	    font-family: var(--fontAvenirHeavy);
	    
	    a,div {
	     &:hover {
	        color: var(--white);
	        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.15) -98%, rgba(74, 144, 226, 0));
	    
		    &:before {
		        content: '';
		        position: absolute;
		        left: 0;
		        top: 0;
		        width: 2px;
		        height: 49px;
		        background: rgba(255,255,255,0.4);
		    } 
	    }
	    
	    
	  }
	  
	
    }
`
export default StyledNav;
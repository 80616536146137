import styled from 'styled-components'
import { isMobile } from "react-device-detect";

const StyledMainPageAside = styled.span`
	  position: absolute;
	  right: 15px;
	  top: ${ isMobile ? '6px' : '9px'}; 
      width: 30px;
	  height: 30px;
	  border-radius: 6px;
	  border: solid 1px rgba(255, 255, 255, 0.25);
	  background-color: rgba(255, 255, 255, 0.1); 
	  color: var(--asideTxtTitle);
	  font-family: var(--fontAvenirHeavy);
	  display: inline-flex;
	  justify-content: center;
	  align-items: center;
	  padding-top: 2px;
	  margin-left: 14px;	   
`
export default StyledMainPageAside;
export const firebaseConfig = {
  apiKey: "AIzaSyA9o13WUs6TaIB46_wj9w419NzkAzCqslU",
  authDomain: "digimex-gulf.firebaseapp.com",
  databaseURL: "https://digimex-gulf.firebaseio.com",
  projectId: "digimex-gulf",
  storageBucket: "digimex-gulf.appspot.com",
  messagingSenderId: "414880633531",
  appId: "1:414880633531:web:2b59ed0787f2ceb3814f9e",
  measurementId: "G-YM7MLQD93K"
};

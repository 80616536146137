import React from 'react';

import { filterByInterests } from '../../../services/interests/interests.utils';
import Webinar from "../WebinarsPage.Webinar/WebinarsPage.Webinar";
import isEmpty from 'lodash/isEmpty';
import StyledWebinarWrapper from './styled/StyledWebinarWrapper';
import StyledBlockWebinars from './styled/StyledBlockWebinars';

const key = 'interest.id';

const WebinarsList = ({ webinars, title, interests, selectedInterestInfo}) => {
    const filteredWebinars = filterByInterests(webinars, interests, key);
    const sortedWebinars = filteredWebinars.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

    return <>
        <StyledWebinarWrapper>
            {!isEmpty(filteredWebinars) && <h3>{ title }</h3> }
            <StyledBlockWebinars>
                {
                    !isEmpty(sortedWebinars) ? filteredWebinars.map(webinar =>
                        <Webinar key={webinar.id} webinar={webinar} />
                    ) : null
                }
            </StyledBlockWebinars>
        </StyledWebinarWrapper>
    </>};


export default WebinarsList;

import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledPageArticle = styled.a`
	 display: flex;
     padding: 10px 10px 10px 10px;
     
     width: 300px;
     max-height: 155px;
     border-radius: 14px;
     box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.07);
     background-color: var(--white);
     color: var(--titleCard); 
     align-items: flex-start;
     margin-left: 15px;
     
     @media ${DEVICE.laptop} {
		 width: 330px; 
		 min-height: 140px;
         max-height: 220px;
         padding: 27px 24px 24px 12px;
	 }
     
     &:hover {
         color: var(--titleCard); 
     }
     
     h4 {
          font-family: var(--fontAvenirHeavy);  
          font-weight: 900;
		  line-height: 1.36;
		  letter-spacing: -0.12px;
		  text-align: left;
		  color: var(--titleCard); 
		  margin-bottom: 6px;
		  white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
		   @supports (-webkit-line-clamp: 2) {
		      overflow: hidden;
		      text-overflow: ellipsis;
		      white-space: initial;
		      display: -webkit-box;
		      -webkit-line-clamp: 2;
		      -webkit-box-orient: vertical;
            }
     }
     h5 {
            color: var(--greyArticle); 
            font-family: var(--fontAvenirMedium);
			font-size: 14px;
			font-weight: 500;
			padding-bottom: 9px;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.36;
			letter-spacing: -0.08px;
			text-align: left;
			color: var(--greyArticle);
     }
     
`
export default StyledPageArticle;
import React from "react";
import StyledSelect from "./Styled.js";

const Select = (props) => (
  <>
    <StyledSelect {...props} value={props?.value || undefined} />
  </>
);

export default Select;

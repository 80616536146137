import React, { useState } from 'react';
import { connect } from 'react-redux';

import { setExamAction } from '../../../services/exams/exams.action';
import Styled from './styled/StyledExamPageQuestion';
import Button from '../../../components/Button/Button'
import StyledQuestionsList from './styled/StyledQuestionsList';
import StyledQuestionItem from './styled/StyledQuestionItem';

const ExamQuestions = ({ questions, id, setExam, examType }) => {
    const [ answers, setAnswer ] = useState({});
    const [ step, setStep ] = useState(0);
    const [ selectedAnswer, selectAnswer ] = useState(0);

    const handleAnswer = () => {
        const questionsId = questions[step].id;
        setAnswer({ ...answers, ...{ [questionsId]: selectedAnswer } });
        selectAnswer(0);
        if (step >= questions.length - 1  ){
            setExam({
                exam: id,
                examType,
                answers: {...answers, ...{ [questionsId]: selectedAnswer } }})
        } else {
            setStep(step + 1);
        }
    };

    return (<>
        <Styled.P>Questions #{step+1}</Styled.P>
        <Styled.H3>{questions[step].title}</Styled.H3>

        <StyledQuestionsList>{
            questions[step].options.map((option, index) =>
                <StyledQuestionItem
                    key={option.id}
                    onClick={() => { selectAnswer(index) }}
                    isSelected={selectedAnswer === index ? true : false }
                >
                    - { option.title }
                </StyledQuestionItem>
            )
        }</StyledQuestionsList>

        <Button
            onClick={() => handleAnswer()
        }>
            Answer
        </Button>
    </>)
};


export default connect(() => ({}), { setExam: setExamAction })(ExamQuestions);
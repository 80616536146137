import React from 'react';
import PopupWrapper from "../../hoc/Modal/withPopup";
import ContactUsForm from "./ContactUsPopupForm";
import { POPUPS_ID } from "../../constants";

const ContactUsPopup = () => (
    <PopupWrapper
        popupId={POPUPS_ID.CONTACT_US_POPUP}
        component={ContactUsForm}
        destroyOnClose
    />
);

export default ContactUsPopup
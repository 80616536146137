export const API_PATH = "https://api.digimex-gulf.com";
// export const API_PATH = "http://localhost:1337";

export const EXAM_TYPE = {
  PRE_WEBINAR: "PRE_WEBINAR",
  POST_WEBINAR: "POST_WEBINAR",
};

export const POPUPS_ID = {
  LOGOUT_POPUP: "LOGOUT_POPUP",
  EXPERT_POPUP: "EXPERT_POPUP",
  CONTACT_US_POPUP: "CONTACT_US_POPUP",
  VIDEO_POPUP: "VIDEO_POPUP",
  LOGIN_POPUP: "LOGIN_POPUP",
  CLAIM_POINTS_POPUP: "CLAIM_POINTS_POPUP",
  CHANGE_PASSWORD_POPUP: "CHANGE_PASSWORD_POPUP",
  FORGOT_PASSWORD_POPUP: "FORGOT_PASSWORD_POPUP",
  ADD_NEW_CONTENT_POPUP: "ADD_NEW_CONTENT_POPUP",
  RESEND_EMAIL_POPUP: "RESEND_EMAIL_POPUP",
};

export const STORAGE_TYPE = {
  LOCAL_STORAGE: "localStorage",
  SESSION_STORAGE: "sessionStorage",
};

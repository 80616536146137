import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledWebinarHeader = styled.div`
	display: flex;
	justify-content: space-between;
	& > div {
		display: inline-block;
	}

	div+div {
		width: 50px;
	
		@media ${DEVICE.tablet} {
			width: 250px;
		    
		}
	}
`
export default StyledWebinarHeader;
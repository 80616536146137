import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import isObject from "lodash/isObject";

import { sanofiSelector } from "../../services/sanofi/sanofi.selector";
import SanofiItem1 from "../../assets/img/sanofi1.png";
import SanofiItem2 from "../../assets/img/sanofi2.png";
import SanofiItem3 from "../../assets/img/sanofi3.png";
import StyledSanofiPage from "./styled/StyledSanofiPage";
import StyledSanofiList from "./styled/StyledSanofiList";
import StyledSanofiCard from "./styled/StyledSanofiCard";
import Peer2PeerImg from "../../assets/img/peer2peer.jpg";
import { Link } from "react-router-dom";
import { urlLocations } from "../../routes/urlLocation";

const SANOFI_CARDS = {
  reportAdverseEventContact: {
    title: "Report Adverse Event",
    src: SanofiItem1,
  },
  askSanofiMedicalExpertContact: {
    title: "Ask Sanofi Medical Expert",
    src: SanofiItem2,
  },
  // sanofiTeamContact: {
  //     title: 'Connect with Sanofi team',
  //     src: SanofiItem3
  // }
};

const SanofiPage = ({ sanofi }) => (
  <StyledSanofiPage>
    <StyledSanofiList>
      {Object.entries(sanofi)?.map(
        (el) =>
          SANOFI_CARDS[el[0]] && (
            <StyledSanofiCard key={el[0]} bg={SANOFI_CARDS[el[0]].src}>
              <a href={`mailto:${isObject(el[1]) ? el[1].email : el[1]}`}>
                <h3>{SANOFI_CARDS[el[0]].title}</h3>
              </a>
            </StyledSanofiCard>
          )
      )}
      <StyledSanofiCard key={"peer2peer"} bg={Peer2PeerImg}>
        <Link to={urlLocations.peer2peer}>
          <h3>Peer 2 peer</h3>
        </Link>
      </StyledSanofiCard>
    </StyledSanofiList>
  </StyledSanofiPage>
);

export default connect(
  createStructuredSelector({
    sanofi: sanofiSelector,
  })
)(SanofiPage);

import styled, { css } from "styled-components";
import { DEVICE } from "../../../constants/media";
import { isTablet, isMobileOnly, isMobile } from "react-device-detect";

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  max-width: 1450px;
  padding: 20px 20px 50px 20px;
  position: relative;

  @media ${DEVICE.laptopL} {
    padding: 39px 45px 0 90px;
  }
  padding: ${isTablet
    ? "39px 20px 0 90px;"
    : isMobileOnly ? "20px 20px 50px 20px;" : "39px 45px 0 90px;"};

  h1 {
    font-family: var(--fontAvenirHeavy);
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #1d3e59;
    display: inline-block;
    width: 80%;
    position: relative;
    top: 55px;
    padding-left: ${({ paddingAttr }) => isMobile && paddingAttr && "25px"};
    top: ${isTablet ? "2px;" : isMobileOnly ? "55px;" : "0"};

    ${() => {
      if (isMobile) {
        return css`
          width: 100%;
          display: inline-block;
        `;
      }
    }} @media ${DEVICE.laptopL} {
      font-size: 26px;
      top: 0;
      width: 70%;
    }
  }
  .ant-badge {
    margin-right: 14px;

    @media ${DEVICE.laptop} {
      margin-right: 29px;
    }
  }
  .ant-badge-dot {
    width: 10px;
    height: 10px;
    top: 1px;
    right: 1px;
    background: var(--primaryRed);
  }
  .ant-dropdown-link {
    & > span {
      display: none;
      @media ${DEVICE.laptopL} {
        display: inline-block;
        padding-right: 10px;
      }
    }
  }
`;

export default {
  Header
};

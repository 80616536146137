import React from 'react';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { newslettersSelector } from "../../../services/newsletters/newsletters.selector";
import NewsletterCard from "../NewsLetters.Card/NewsLetters.Card";
import Styled from './styled'

const NewslettersList = ({ newsletters }) => {
    return (
        <Styled.List>
            {
                newsletters.map(newsletter =>
                    <NewsletterCard newsletter={newsletter} />
                )
            }
        </Styled.List>
    )
};

export default connect(
    createStructuredSelector({
        newsletters: newslettersSelector
    }),
    {}
)(NewslettersList)
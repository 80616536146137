import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';
import { isTablet, isMobileOnly } from "react-device-detect";

const StyledSanofiCard = styled.div`
      width: 160px;
      height: 200px;
	  border-radius: 14px;
	  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.07);
	  margin-bottom: 28px;
	  position: relative;
	  border-radius: 14px;
	  overflow: hidden;
	  background:  url(${props => props.bg}) center center no-repeat;
	  // &:nth-child(2n) {
	  //    margin-right: 0;
	  // }
	  
	  width: ${ (isTablet) && '42%;'};
	  
	  @media ${DEVICE.tablet} {
          margin-left: 20px;
          margin-right: 20px;
          width: 200px;
	  }
	  
	  @media ${DEVICE.mobileDevices} {
         margin: 8px;
         width: 158px;
      }
      
      @media (max-width: 820px) and (orientation: landscape) {
         width: ${ isMobileOnly && '42%'};
      }
	  
	  @media ${DEVICE.laptop} {
        margin-left: 20px;
	    margin-right: 20px;
	    width: 310px;
	    margin-bottom: 40px;
        height: 290px;
        margin-left: 20px;
	    margin-right: 20px;
	  }
	  
	  a {
		display: inline-block;	
		width: 100%;
		height: 100%;
	  }
	  
	  h3 {
		  position: absolute;
		  bottom: 0;
		  left: 0;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  width: 100%;
		  background: rgba(11,47,75,0.6);
		  height: 78px;
	      font-family: var(--fontAvenirHeavy);
		  font-size: 12px;
		  font-weight: 900;
		  line-height: 1.5;
		  letter-spacing: normal;
		  text-align: center;
		  color: var(--white);
		  width: 100%;
		  padding: 0 7px;
		  
		 
		  @media ${DEVICE.tablet} {
		      font-size: 18px;
	      }
		  @media ${DEVICE.laptop} {
		      font-size: 20px;
		      padding: 0 30px;
	      }
		  @media ${DEVICE.laptopL} {
		      line-height: 1.5;
	      }
	  }  
`
export default StyledSanofiCard ;
import React from 'react';
import Logo from '../../../components/Logo/Logo'
import { isMobileOnly } from "react-device-detect";
import Styled from './styled/StyledMainPage.Aside'
import AppLinkPanel from '../../../components/AppLinkPanel/AppLinkPanel';

const MainPageAside = () => (
	<Styled.StyledMainPageAside>
		<Logo/>
		<Styled.DesktopText>
			<p>Or use our mobile applications</p>
		</Styled.DesktopText>

		{!isMobileOnly && <Styled.MobileText>
			<p>Stay tuned for the mobile version</p>
			<p>Currently you can enjoy there platform from your computer</p>
		</Styled.MobileText> }

		{!isMobileOnly && <AppLinkPanel></AppLinkPanel>}

	</Styled.StyledMainPageAside>


);


export default MainPageAside;
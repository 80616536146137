import React, {useState} from 'react';
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Styled from './ClaimPoints.Styled';
import { getUserCMEPointsDetails, getUserInfoSelector } from '../../services/user/user.selector';
import ClaimPointsField from "./ClaimPoints.Field";
import Button from '../../components/Button/Button';
import { claimPointsAction } from "../../services/user/user.action";

const ClaimPointsForm = ({ handleSubmit, setPopupStatus, points, user }) => {
    const [ selectedPoints, setSelectedPoints ] = useState(0);
    const handleCheck = ( checked, points ) => {
        if ( checked ) {
            setSelectedPoints( selectedPoints + points )
        } else {
            setSelectedPoints( selectedPoints - points )
        }
    };
    return (
        <form onSubmit={handleSubmit} >
            <Styled.List>
                { !!points && points.map(item =>
                    (!item.isClaimed && <ClaimPointsField
                        onChange={
                            (event) => handleCheck(event.target.checked, item.points)
                        }
                        key={item.id}
                        item={item}
                    />)
                )}
            </Styled.List>
            <Styled.Buttons>
                { !!user.cmePoints && <Button
                    htmlType='submit'
                    disabled={!selectedPoints}
                    style={{
                        pointerEvents: !selectedPoints ? 'none' : '',
                    }}
                >
                    Claim { selectedPoints } Points
                </Button> }
                <Button
                    styledtype='cancel'
                    onClick={setPopupStatus.bind(null, null)}
                >
                    Cancel
                </Button>
            </Styled.Buttons>
        </form>
    )
};

export default compose(
    connect(
        createStructuredSelector({
            points: getUserCMEPointsDetails,
            user: getUserInfoSelector
        }), {}),
    reduxForm({
        form: 'ClaimCMEForm',
        onSubmit: (value, dispatch) =>
            dispatch(claimPointsAction(value))
    }),
)(ClaimPointsForm)
import React from 'react';

import PopupWrapper from "../../../hoc/Modal/withPopup";
import { POPUPS_ID } from "../../../constants";
import Disclaimer from "../MainPage.Disclaimer/MainPage.Disclaimer";

const LoginPopup = ({ submitRef }) => (
    <PopupWrapper
        destroyOnClose
        popupId={POPUPS_ID.LOGIN_POPUP}
        component={ () => <Disclaimer submitRef={submitRef} /> }
    />
);

export default LoginPopup;
import initialState from "../../store/initialState";
import { GET_CATALOGUE_SUCCESS } from "./catalogue.action";

export default (state = initialState.catalogue, action) => {
  switch (action.type) {
    case GET_CATALOGUE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

import styled from 'styled-components';
import { DEVICE } from '../../../../constants/media';

const StyledExam = styled.div`
	height: 82px;
	border-radius: 14px;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 14px 21px 14px 25px;
	position: relative;
	overflow: hidden;
	background: var(--white);
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.07);
	margin-bottom: 30px;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	
	 @media ${DEVICE.tablet} {
         padding: 14px 21px 14px 34px;
         margin-bottom: 20px;
     }
     
     @media (max-width: 600px) {
        .ant-btn {
            display: none;
        }
     }
	
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 10px;
		background:  ${({color}) => color};	
	}
	
	h3 {
	  font-family: var(--fontAvenirHeavy);
	  font-size: 14px;
	  font-weight: 900;
	  line-height: 1.35;
	  letter-spacing: -0.1px;
	  text-align: left;
	  color: var(--titleCards);
	  margin-right: 12px;
	  min-width: 300px;
	  max-width: 700px;
	  white-space: pre-wrap;
	   a {
	        color: var(--titleCards);
	   }
	  
	  @media ${DEVICE.tablet} {
         font-size: 18px;
         margin-right: 40px;
         white-space: nowrap;
         overflow: hidden;
	     text-overflow: ellipsis;
	     width: calc(100% - 225px);
         
      }
      @media ${DEVICE.laptopL} {
         font-size: 20px;
      }
	}
	
	& a > span {
		color: #5f853f;
		font-size: 12px;
		width: 100%;
		position: absolute;
		left: 25px;
        bottom: 3px;
		font-weight: 900;
		font-family: var(--fontAvenirHeavy);
	}
	
`
export default StyledExam;
import { put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import { CLEAR_INTERESTS } from "./interests.action";

function* clearInterestsOnLocationChange() {
  try {
    yield put({ type: CLEAR_INTERESTS });
  } catch (error) {
    console.log(error);
  }
}

export default function* interestsSaga() {
  yield takeEvery(LOCATION_CHANGE, clearInterestsOnLocationChange);
}

import React from 'react';
import StyledSpeakerBlock from './styled/StyledSpeakerBlock';

const SpeakerBlock = ({avatar, name}) => (
	<StyledSpeakerBlock>
		<img src={ avatar } alt=""/>
		<p>{ name }</p>
	</StyledSpeakerBlock>
);

export default SpeakerBlock;



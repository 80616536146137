import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledArticleList = styled.ul`
        display: flex;
        margin-left: -5px;
        margin-right: -5px;
        flex-wrap: wrap;
        margin-top: 30px;
        padding: 0;
        overflow-x: auto;
        flex-wrap: nowrap;
        
        @media ${DEVICE.laptopL} {
		   margin-left: -15px;
           margin-right: -15px;
           flex-wrap: wrap;
	    }
	     li {
	        display: flex;
	        margin-left: 5px;
	        margin-right: 5px;
	        margin-bottom: 30px;
	        
	        @media ${DEVICE.laptop} {
			    margin-left: 15px;
	            margin-right: 15px;
	        }
	     }
`
export default StyledArticleList;
import { call, put, takeEvery } from "redux-saga/effects";

import { STORAGE_TYPE, POPUPS_ID } from "../../constants";
import { LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS } from "./login.action";
import { SET_POPUP_STATUS } from "../popup/popup.action";
import { loginRequest } from "./login.api";
import { history, urlLocations } from "../../routes/urlLocation";
import instance from "../root.api";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";
import openNotification, {
  prepareErrorMessages,
} from "../..//utils/notifications";

function* login({ payload: { identifier, password, rememberMe } }) {
  try {
    yield put(startLoaderAction());

    const { data, status } = yield call(loginRequest, { identifier, password });
    if (status && status === 200) {
      localStorage.setItem(
        "storageTyp",
        rememberMe ? STORAGE_TYPE.LOCAL_STORAGE : STORAGE_TYPE.SESSION_STORAGE
      );
      const storage = localStorage.getItem("storageTyp");
      window[storage].setItem("jwt", data.jwt);
      instance.defaults.headers.common["Authorization"] = `Bearer ${data.jwt}`;

      history.push(urlLocations.main);
      yield put({ type: LOGIN_SUCCESS });
    }
  } catch ({ response }) {
    yield put({ type: SET_POPUP_STATUS, payload: { status: null } });
    const { data } = response;
    const isNotConfirmed =
      data?.message?.[0].messages?.[0].id === "Auth.form.error.confirmed";

    yield isNotConfirmed &&
      put({
        type: SET_POPUP_STATUS,
        payload: POPUPS_ID.RESEND_EMAIL_POPUP,
      });
    yield call(openNotification, {
      type: "error",
      message: "Error",
      description: prepareErrorMessages(response),
    });
    yield put({
      type: LOGIN_FAILURE,
    });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* loginSaga() {
  yield takeEvery(LOGIN, login);
}

import React from 'react';

import ExpertsList from './ExpertsPage.List/ExpertsPage.List'
import Interests from "../../components/Interests/Interests";
import StyledExpertsPage from './styled/StyledExpertsPage'
import {useLocation} from "react-router";

const ExpertsPage = () => {
    const { search } = useLocation()
    return (
        <StyledExpertsPage>
            { !search &&  <Interests/> }
            <ExpertsList/>
        </StyledExpertsPage>
    )
}


export default ExpertsPage;

import styled, {css} from 'styled-components'
import { Link } from 'react-router-dom'
import { DEVICE } from '../../../../constants/media';
import { isTablet } from "react-device-detect";

const StyledWebinarItem = styled(Link)`
      max-width: 190px;
      height: 250px;
	  border-radius: 14px;
	  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.07);
	  background-color: var(--white);
	  margin-left: 5px;
	  margin-right: 5px;
	  padding: 25px 20px 15px 0;
	  position: relative;
	  margin-bottom: 20px;
	  min-width: 160px;
	 
	  @media ${DEVICE.mobileTabletDevices} {
            width: ${ isTablet ?  '205px': '190px'};
            
          ${() => {
			if (isTablet) {
				return css`
					 width: 205px;
                     height: 300px;
			         min-width: 205px;
			         min-height: 300px;
			         height: 300px;
			         margin-left: 10px;
				     margin-right: 10px;
                `}
                else {
				return css`
					 width: 190px;
                     height: 250px;
			         min-width: 205px;
			         min-height: 300px;
			         height: 300px;
		             margin-left: 5px;
	                 margin-right: 5px;
                `}
          }}
       }
	  @media ${DEVICE.laptopL} {
	     max-width: 234px;
         height: 300px;
         min-height: 300px;
         margin-left: 17px;
	     margin-right: 17px;
	  }
	  
	  h5 {
	      margin-top: 42px;
	      font-family: var(--fontAvenirHeavy);
		  font-size: 14px;
		  font-weight: 900;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.5;
		  letter-spacing: -0.1px;
		  text-align: left;
		  color: var(--titleCards);
		  margin-bottom: 30px;
		  padding-left: 25px;
		  padding-right: 10px;
		  span {
		    display:inline-block;
		    width: 100%;
		  }
		  
		  @media ${DEVICE.laptop} {
	         margin-top: 47px;
	         font-size: 16px;
	      }
	  }
	  
`
export default StyledWebinarItem;
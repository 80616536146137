import styled from "styled-components";
import { DEVICE } from "../../../constants/media";

const StyledIconNotification = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: solid 1px rgba(76, 82, 188, 0.1);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media ${DEVICE.laptopL} {
    width: 40px;
    height: 40px;
  }
  img {
    width: 15px;
    height: 15px;

    @media ${DEVICE.laptopL} {
      width: 21px;
      height: 21px;
    }
  }
`;

export default StyledIconNotification;

import React from 'react';
import { connect } from 'react-redux';
import Button from '../../components/Button/Button';

import { logoutAction } from "../../services/logout/logout.action";
import PopupWrapper from "../../hoc/Modal/withPopup";
import { POPUPS_ID } from "../../constants";
import Styled from './Logout.styled';

const LogoutPopup = ({ logout }) => (
    <PopupWrapper
        popupId={POPUPS_ID.LOGOUT_POPUP}
        component={ ({ setPopupStatus }) => (
            <div>
                <Styled.Title>Are you sure you want to log out ?</Styled.Title>

                <Styled.WrapButton>
                    <Button size="small" onClick={() => logout()}>Log Out</Button>
                    <Button styledtype="cancel" size="small" onClick={() => setPopupStatus(null)} >Cancel</Button>
                </Styled.WrapButton>
            </div>)
        }
    />
)


export default connect(
    () => ({}),
    {
        logout: logoutAction
    })
(LogoutPopup)
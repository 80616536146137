import styled from 'styled-components';

const StyledBackBtn = styled.div`
	  width: 40px;
	  height: 40px;
	  position: absolute;
	  right: -20px;
      top: 33px;
	  
	  div {
	      width: 40px;
		  height: 40px;
		  position: absolute;
		  right: -6px;
          top: 217px;
          top:${({ isMobileAttr }) => isMobileAttr ? '217px;' : '36px;'};
		  border-radius: 50%;
		  z-index: 2;
		  cursor: pointer;
		  text-align: center;
		  background-image: var(--gradientBackBtn);
		  padding-top: 13px;
		   a {
		        display: flex;
			    align-items: center;
			    justify-content: center;
		   }
		   img {
		        position: relative;
		        left: ${({ collapsed }) => collapsed ? '2px': '-1px' };
		        top: ${({ collapsed }) => collapsed ? '-2px': '-1px' };
		        transform: rotate(${({ collapsed }) => collapsed && '180deg' });
		   }
	  } 
`
export default StyledBackBtn;
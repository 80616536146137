import styled from 'styled-components';
import { isMobile } from "react-device-detect";

import { Layout } from 'antd';
const { Content } = Layout;


const StyledAutorizedContent = styled(Content)`
	  width: 100%;
	  margin-left:${isMobile ? '0' : '249px'};
	  background: var(--lightWhite);
	  overflow-x: hidden;
	  
	  
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-flex: 1;
	      -ms-flex: auto;
	          flex: auto;
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	      -ms-flex-direction: column;
	          flex-direction: column;
	  min-height: 0;
`
export default StyledAutorizedContent;
import { call, put, select, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import isEmpty from "lodash/isEmpty";

import { specialitiesRequest } from "./specialities.api";
import * as specialitiesActions from "./specialities.actions";
import * as specialitiesSelectors from "./specialities.selector";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* getSpecialitiesOnLocationChangeData() {
  try {
    const specialities = yield select(
      specialitiesSelectors.specialitiesSelector
    );
    if (isEmpty(specialities)) {
      yield put({ type: specialitiesActions.GET_SPECIALITIES });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchSpecialities() {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(specialitiesRequest);

    yield put({
      type: specialitiesActions.GET_SPECIALITIES_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: specialitiesActions.GET_SPECIALITIES_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* specialitiesSaga() {
  yield takeEvery(LOCATION_CHANGE, getSpecialitiesOnLocationChangeData);
  yield takeEvery(specialitiesActions.GET_SPECIALITIES, fetchSpecialities);
}

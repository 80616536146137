import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledDateBlock = styled.div`
	padding: 40px 20px 37px 20px;
	
	@media ${DEVICE.tablet} {
          padding: 65px 40px 37px 40px;
    }
    
	@media ${DEVICE.laptop} {
          padding: 65px 90px 37px 90px;
    }
    
	@media ${DEVICE.laptopL} {
          padding: 65px 70px 37px 70px;
    }

	p {
		  line-height: 1.71;
		  letter-spacing: normal;
		  text-align: left;
		  color: var(--greyMainText);
		  padding-right: 40px;
		  margin-top: 15px;
	}
`
export default StyledDateBlock;
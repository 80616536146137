import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getUserInfoSelector } from "../../services/user/user.selector";
import Styled from "./UserPage.styled";
import UserForm from "./UserPage.Form";
import { updateProfileAction } from "../../services/user/user.action";
import AvatarUploader from "../../components/AvatarUploader/AvatarUploader";
import { selectFile } from "../../services/file/file.selector";

const UserPage = ({
  updateProfile,
  user,
  user: {
    firstName,
    lastName,
    hospital,
    email,
    mobile,
    scfhs_id,
    country,
    cmePoints,
    interests,
    profilePic: initialPic,
  },
  profilePic,
}) => {
  return (
    <Styled.Page>
      <Styled.Title>
        Dr. {firstName} {lastName}
      </Styled.Title>
      <Styled.Hospital>{hospital}</Styled.Hospital>
      <AvatarUploader
        initialPic={initialPic}
        uploaderName="profile"
        darkThema
      />
      <UserForm
        updateProfile={updateProfile}
        user={user}
        profilePic={profilePic}
        initialValues={{
          interests:
            interests &&
            Object.values(interests).map((interest) => interest.id),
          country: country?.name,
          cmePoints,
          firstName,
          lastName,
          email,
          mobile,
          scfhs_id,
          specialityNew: user?.specialityNew?.id,
          hospital,
        }}
      />
    </Styled.Page>
  );
};

export default connect(
  createStructuredSelector({
    user: getUserInfoSelector,
    profilePic: (state) => selectFile(state, "profile"),
  }),
  {
    updateProfile: updateProfileAction,
  }
)(UserPage);

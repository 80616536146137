import styled from "styled-components";

const ErrorMessage = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  color: var(--primaryRed);
  font-size: 12px;
  margin-top: 3px;
`;

export default {
  ErrorMessage,
};

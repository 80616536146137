import styled from "styled-components";
import { Col } from "antd";

import { DEVICE } from "../../../constants/media";

const StyledMainPage = styled.div`
  min-height: 100vh;
  height: 100%;
  background: var(--lightWhite);
`;

const LoginCol = styled(Col)`
  @media ${DEVICE.mobileDevices} {
    //display: none;
    max-width: 100%;
    flex: 0 0 100%;
  }
`;

const MainCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-self: center;
  @media ${DEVICE.mobileDevices} {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;

export default {
  StyledMainPage,
  LoginCol,
  MainCol,
};

import styled from 'styled-components';
import { DEVICE } from '../../constants/media';
import { isTablet, isMobileOnly } from "react-device-detect";


const Title = styled.h3`
  min-height: 70px;
  
  @media ${DEVICE.tablet} {
      min-height: 100px; 
  } 
  
`;
const WrapButton = styled.div`
  display: flex;
  flex-wrap: ${ isMobileOnly && 'wrap;'};
  justify-content: ${ isTablet ?  'space-between': 'flex-start'};
  
  && .ant-btn {
        width: 175px;
        height: ${ isTablet ?  '60px;': isMobileOnly ? '45px;' : '60px'};
        width: ${ isTablet ?  '45%;' : isMobileOnly ? '100%;' : '175px'};
        font-size: ${ isTablet ?  '17px;': '16px;'};
        margin-left: ${ isTablet ?  '20px': '0'};
        margin-right ${ !isMobileOnly ?  '20px': '0'};
  }
`;

export default {
    Title,
	WrapButton
}
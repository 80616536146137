import React from "react";
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect";

import { catalogueSelector } from "../../../services/catalogue/catalogue.selector";
import CatalogueCard from "../CataloguePage.Card/CataloguePage.Card";
import Styled from "./styled";

const CatalogueList = ({ catalogue }) => {
    return (
        <Styled.List>
            {
                catalogue.map(catalogueItem =>
                    <CatalogueCard key={catalogueItem.id} catalogueItem={catalogueItem} />
                )
            }
        </Styled.List>
    )
};

export default connect(
    createStructuredSelector({
        catalogue: catalogueSelector
    }),
    {}
)(CatalogueList)
import React from 'react';
import Button from '../../components/Button/Button';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { setPopupStatusAction } from '../../services/popup/popup.action';
import { exportSelector } from '../../services/experts/experts.selector';
import ExpertPreview from '../../components/ExpertPreview/ExpertPreview';
import StyledExpertPage from './styled/StyledExpertPage';
import StyledBio from './styled/StyledBio';
import { POPUPS_ID } from "../../constants";
import ExpertPopup from './ExpertPage.Popup/Expert.Popup';

const ExpertPage = ({
    setPopupStatus,
    expert: {
        interests,
        avatar: { url },
        name,
        title,
        hospital,
        bio,
        id
    } = {
        interests: [],
        avatar: { url: "" },
        name: "",
        hospital: "",
        title: "",
        bio: "",
        id: null
    } }) => (
        <StyledExpertPage>
            <ExpertPreview src={url}
                           color={interests[0]?.color}
                           name={name}
                           title={title}
                           hospital={hospital }/>

            <StyledBio>
                <p>{ bio }</p>
            </StyledBio>

            <Button size="middle" onClick={() => setPopupStatus(POPUPS_ID.EXPERT_POPUP)} >Ask Question</Button>
            <ExpertPopup expertId={id} popupId={POPUPS_ID.EXPERT_POPUP} />
        </StyledExpertPage>);

export default connect(
    createStructuredSelector({
        expert: exportSelector
    }),
    {
        setPopupStatus: setPopupStatusAction
    }
)(ExpertPage)
import React from "react";
import SessionCard from "../Sessions.Card/Session.Card";
import StyledSessionsList from "./styled/StyledSessions";

const SessionsList = ({ sessions = [] }) => (
  <StyledSessionsList>
    {!!sessions &&
      sessions.map((session) => (
        <SessionCard key={session?.id} session={session} />
      ))}
  </StyledSessionsList>
);

export default SessionsList;

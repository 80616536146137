import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Article from '../ArticlesPage.Article/ArticlesPage.Article'
import { articlesSelector } from '../../../services/articles/articles.selector';
import StyledArticleList from './styled/StyledArticleList';
import { filterByInterests } from "../../../services/interests/interests.utils";
import { interestsSelector } from "../../../services/interests/interests.selector";

const key = "interest.id";

const ArticleList = ({ articles, interests }) => {

    const articlesArr = Object.values(articles);
    const filteredArticles = filterByInterests(articlesArr, interests, key);

    return (
            <>
                <StyledArticleList>
                    { filteredArticles?.map(article =>
                    <li key={article.id} >
                        <Article  article={article} />
                    </li>)}

                </StyledArticleList>
        </>)
};

export default connect(createStructuredSelector({
    articles: articlesSelector,
    interests: interestsSelector,
}))(ArticleList);
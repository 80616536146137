import React, { useState } from "react";
import { useDispatch } from "react-redux";

import PopupWrapper from "../../../hoc/Modal/withPopup";
import Button from "../../../components/Button/Button";
import { POPUPS_ID } from "../../../constants";
import { resendEmailAction } from "../../../services/resendEmail/resendEmail.action";
import Styled from "./styled";

const MainPageResendPopupContent = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  return (
    <>
      <Styled.Title>You haven't confirm your email yet.<br/> Please check you email, or type your email here to resend confirmation email</Styled.Title>
      <Styled.Input
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        type="email"
      />
      <Button
        disabled={!email}
        onClick={() => dispatch(resendEmailAction({ email }))}
      >
        Resend
      </Button>
    </>
  );
};

const MainPageResendPopup = () => {
  return (
    <PopupWrapper
      component={MainPageResendPopupContent}
      popupId={POPUPS_ID.RESEND_EMAIL_POPUP}
    />
  );
};

export default MainPageResendPopup;

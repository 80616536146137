import React from "react";
import Select from "../Select/Select";
import Styled from "./Styled";

const RenderField = ({ name, placeholder, type, input, meta, ...res }) => {
  const { onBlur, ...resInput } = input;
  return (
    <>
      <Select
        name={name}
        placeholder={placeholder}
        type={type}
        {...resInput}
        {...res}
      />
      {meta?.touched && meta?.error && (
        <Styled.ErrorMessage>{meta?.error}</Styled.ErrorMessage>
      )}
    </>
  );
};

export default RenderField;

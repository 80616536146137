import { put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  GET_WEBINARS_SUCCESS,
  GET_WEBINARS,
  GET_WEBINARS_FAILURE,
} from "./webinars.action";
import { urlLocations } from "../../routes/urlLocation";
import { isUrlMatch } from "../router/router.utils";
import { webinarsRequest } from "./webinars.api";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* getWebinarsOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.webinars)) {
      const { search } = payload.location;
      const params = Object.fromEntries(new URLSearchParams(search));
      yield put({
        type: GET_WEBINARS,
        ...( params && { payload: { params } } )
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchWebinars({ payload }) {
  try {
    yield put(startLoaderAction());

    const { data, status } = yield webinarsRequest({ params: payload.params });
    if (status === 200) {
      yield put({
        type: GET_WEBINARS_SUCCESS,
        payload: { ...data },
      });
    }
  } catch (error) {
    yield put({ type: GET_WEBINARS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* webinarsSaga() {
  yield takeEvery(LOCATION_CHANGE, getWebinarsOnLocationChange);
  yield takeEvery(GET_WEBINARS, fetchWebinars);
}

import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { isPopupVisibleSelector } from "../../services/popup/popup.selectors";
import { setPopupStatusAction } from "../../services/popup/popup.action";
import StyledModal from "./styled/StyledModal";
import IconCloseSvg from "../../assets/img/close.svg";
import { isTablet, isMobileOnly } from "react-device-detect";

const widthVideo = isMobileOnly ? 320 : isTablet ? 600 : 790;

const PopupWrapper = ({
  setPopupStatus,
  onClose,
  component: Component,
  isPopupVisible,
  footer = null,
  ...props
}) => (
  <StyledModal
    visible={isPopupVisible}
    footer={footer}
    width={widthVideo}
    closeIcon={<img src={IconCloseSvg} alt="" />}
    onCancel={(e) => {
      onClose && onClose(e);
      setPopupStatus(null);
    }}
    {...props}
  >
    <Component {...props} setPopupStatus={setPopupStatus} />
  </StyledModal>
);

export default connect(
  createStructuredSelector({
    isPopupVisible: (state, props) =>
      isPopupVisibleSelector(state, props.popupId),
  }),
  {
    setPopupStatus: setPopupStatusAction,
  }
)(PopupWrapper);

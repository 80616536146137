import instance from "../root.api";

export const getPostsRequest = (params) => instance.get("/posts", { params });
export const getPostsCountRequest = () => instance.get("/posts/count");

export const getPendingPostsCountRequest = () =>
  instance.get("/posts/pending-count");

export const createPostRequest = (data) => instance.post("/posts", data);

export const togglePostLikeRequest = ({ type, id }) =>
  instance.post(`/posts/${id}/${type}`);

export const addPostCommentRequest = ({ id, ...data }) =>
  instance.post(`/posts/${id}/comment`, data);

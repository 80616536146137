import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';
import { isTablet  } from "react-device-detect";

const ExamPage = styled.div`
    padding: 60px 20px 100px 20px;

    @media ${DEVICE.laptop} {
       padding:  105px 50px 100px 90px;
    }   
`;

const ExamBody = styled.div`
    .ant-btn {
        width: 100%;
        max-width: 550px;
        margin: 0 auto;
        display: block;
    }
    @media ${DEVICE.tablet} {
       .ant-btn {
         width: 230px;
       }
    }
    
    @media ${DEVICE.laptop} {
       .ant-btn {
         margin: ${ isTablet ? '0 auto;' : '0;' };
       }
    } 
`;

const H1 = styled.h1`
    font-family: var(--fontAvenirHeavy);
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: -0.17px;
    text-align: left;
    color: var(--titleCards);
    padding-bottom: 10px;
    padding-right: 20px;
    
    @media ${DEVICE.laptop} {
        font-size: 30px;
        padding-bottom: 15px;
        padding-right: 100px;
    }  
`;

const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    vertical-align: top;
    
    .ant-btn {
        display: none;
    }
    
    @media ${DEVICE.tablet} {
       .ant-btn {
            display: block;
       }
    }
`;


export default {
    HeaderRow,
	ExamBody,
    H1,
    ExamPage
};
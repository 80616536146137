import React from "react";
import TextArea from "../Textarea/Textarea";
import Styled from "./RenderTextArea.Styled";

const RenderField = ({ name, placeholder, type, input, meta }) => (
  <>
    <TextArea name={name} placeholder={placeholder} type={type} {...input} />
    {meta?.touched && meta?.error && (
      <Styled.ErrorMessage>{meta?.error}</Styled.ErrorMessage>
    )}
  </>
);

export default RenderField;

import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledDateBlock = styled.div`
	margin-bottom: 5px;
	height: 50px;
	position: relative;
	display: flex;
	align-items: center;
	
	@media ${DEVICE.laptop} {
	   height: 66px;
	}
	
	&:after {
		content: '';
		position: absolute;
		width: 6px;
		height: 40px;
		top: 6px;
		left: 0;
		@media ${DEVICE.laptop} {
	        top: 12px;
		}
		background: ${props => props.color};
	}
	div {
		display: flex;
		flex-wrap: wrap;
	}
	h4 {
	  font-family: var(--fontAvenirHeavy);
	  font-size: 15px;
	  font-weight: 900;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.39;
	  letter-spacing: -0.1px;
	  text-align: left;
	  color: var(--titleCards);
	  padding-left: 72px;
	  
	  @media ${DEVICE.laptop} {
	      font-size: 18px;
	      padding-left: 86px;
	  }
	}
	
	p {
		position: absolute;
		left: 12px;
		top: 0;
		width: 55px;
		text-align: center;
		height: 50px;
		font-family: Avenir;
		font-size: 38px;
		font-weight: 700;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: -2.5px;
		color: var(--titleCards);	
		  
		@media ${DEVICE.tablet} {
		    font-size: 42px;
		    height: 66px;
        }
	    @media ${DEVICE.laptop} {
	        font-size: 47px;
	        height: 66px;
	        font-weight: 900;
	        left: 17px;
	    }
	}
	span {
	  display: inline-block;
	  font-size: 12px;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.36;
	  letter-spacing: -0.08px;
	  text-align: left;
	  color: var(--subtitleCards);
	  padding-left: 72px;
	  
	  @media ${DEVICE.laptop} {
	      font-size: 14px;
	      padding-left: 86px;
	  }
	} 
`
export default StyledDateBlock;
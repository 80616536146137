import styled from "styled-components";
import { DEVICE } from "../../../constants/media";

const StyledUserBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 90px;

  .ant-avatar {
    width: 30px;
    height: 30px;
    margin-right: 2px;

    @media ${DEVICE.laptop} {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  a {
    font-family: var(--fontAvenirHeavy);
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: var(--linkAvatar);
    svg {
      margin-left: 10px;
    }
  }
`;

export default StyledUserBlock;

import React from 'react';

import PopupWrapper from "../../../../hoc/Modal/withPopup";
import { POPUPS_ID } from "../../../../constants";
import ChangePasswordForm from "../ChangePasswordForm/ChangePasswordForm";

const ChangePasswordModal = () => <PopupWrapper
    popupId={POPUPS_ID.CHANGE_PASSWORD_POPUP}
    component={ChangePasswordForm}
    destroyOnClose
/>;

export default ChangePasswordModal;

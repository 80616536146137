import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";

import { interestsSelector } from '../../../services/interests/interests.selector'
import { setInterestAction, clearInterestsAction } from '../../../services/interests/interests.action';
import StyledInterest from './styled/StyledInterest.jsx';
import StyledInterestMarker from './styled/StyledInterestMarker';

const handleSetInterests = (setInterest, clearInterests, selectedInterest, id) => {
    if (selectedInterest === id){
        clearInterests()
    } else {
        setInterest(id)
    }
};

const Interest = ({ interest, setInterest, selectedInterest, clearInterests }) =>
    <StyledInterest
        fontWeight={selectedInterest === interest.id ? 'bold': 'normal'}

        onClick={ () => {
        handleSetInterests(setInterest, clearInterests, selectedInterest, interest.id  )
    }} >
        <StyledInterestMarker
            color={interest.color}
            isActive={selectedInterest === interest.id ? true: false}
        >
        </StyledInterestMarker>
        <p>{ interest.Name }</p>

    </StyledInterest>;

export default connect(
    createStructuredSelector({
        selectedInterest: interestsSelector,
    }),
    {
        setInterest: setInterestAction,
        clearInterests: clearInterestsAction
    }
)(Interest)
import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledArticlePage = styled.div`
      padding: 40px 20px 80px 20px;  
        
      @media ${DEVICE.laptopL} {
		  padding: 57px 40px 80px 90px;
	  }       
`
export default StyledArticlePage;
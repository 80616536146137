import React from "react";
import { Form } from "antd";
import { Field, reduxForm } from "redux-form";
import { useSelector } from "react-redux";
import { Select as AntSelect } from "antd";

import TextArea from "../../../components/RenderTextArea/RenderTextArea";
import { userInterestsSelector } from "../../../services/userInterests/userInterests.selector";
import RenderSelect from "../../../components/RenderSelect/RenderSelect";
import Input from "../../../components/Input/Input";
import Peer2PeerUploaded from "./Peer2peer.Uploaded";
import Button from "../../../components/Button/Button";
import { createPostAction } from "../../../services/peer2peer/peer2peer.actions";
import Peer2PeerUploaders from "./Peer2peer.Uploaders";
import { requiredFieldsValidate } from "../../../utils/validate";
import Styled from "../Styled";

const { Option } = AntSelect;

const Peer2PeerForm = ({ handleSubmit }) => {
  const interests = Object.values(useSelector(userInterestsSelector));

  return (
    <form onSubmit={handleSubmit}>
      <Form.Item>
        <Field
          name="content"
          placeholder="Start a post.."
          component={TextArea}
        />
      </Form.Item>
      <Form.Item>
        <Field name="externalLink" placeholder="Add link.." component={Input} />
      </Form.Item>
      <Peer2PeerUploaded />
      <Form.Item>
        <Field
          name="interest"
          placeholder="Select tag.."
          component={RenderSelect}
        >
          {interests.map((interest) => (
            <Option
              children={interest.Name}
              key={interest.id}
              value={interest.id}
            />
          ))}
        </Field>
      </Form.Item>
      <Styled.AddNewFooter>
        <Peer2PeerUploaders />
        <Button htmlType="submit">Add new Content</Button>
      </Styled.AddNewFooter>
    </form>
  );
};

export default reduxForm({
  form: "peer2peerForm",
  destroyOnUnmount: true,
  validate: (v) => requiredFieldsValidate(v, ["content", "interest"]),
  onSubmit: (value, dispatch, props) => {
    dispatch(
      createPostAction({
        ...value,
        attachment: props?.attachment,
        postType: props?.postType,
      })
    );
  },
})(Peer2PeerForm);

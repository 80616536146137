import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import isEmpty from 'lodash/isEmpty';

import Button  from '../../components/Button/Button';
import { history, urlLocations } from "../../routes/urlLocation";
import StyledResultBlock from '../styled/StyledResultBlock';
import StyledResultNav from '../styled/StyledResultNav';
import { getExamResult } from "../../services/exams/exams.selector";

const ExamFailPage = ({ result }) => {
    useEffect(() => {
        if(isEmpty(result)) {
            history.push(urlLocations.exams)
        }
    }, []);

   return ( <StyledResultBlock>
        <div>
            <h2>Sorry, please try again !</h2>
            <div>
                <p>
                    Unfortunately, you score is only <b>{result.score}%</b>. You have to
                    get at least <b>{result.minToSuccess}%</b> to get your CME points.
                    But don’t worry, you can try again at any time.
                </p>
            </div>

            <StyledResultNav>
                <Button size="small" onClick={() => history.goBack()} >Try again</Button>
                <Link to={urlLocations.main} >Back to Main Page</Link>
            </StyledResultNav>


        </div>
    </StyledResultBlock>)
};


export default connect(
    createStructuredSelector({
        result: getExamResult
    })
)(ExamFailPage);
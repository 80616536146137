import React, { useState } from "react";

import Peer2PeerFilters from "./Peer2peer.Filters";
import Peer2PeerPostsList from "./Peer2peer.PostsList";

const Peer2PeerPosts = ({ selectedInterests, selectInterest }) => {
  const [filter, setFilter] = useState(null);
  return (
    <>
      <Peer2PeerFilters onChange={setFilter} />
      <Peer2PeerPostsList
        selectedInterests={selectedInterests}
        selectInterest={selectInterest}
        filter={filter}
      />
    </>
  );
};

export default Peer2PeerPosts;

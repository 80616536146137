import React from "react";
import PhoneInput from "../PhoneInput/PhoneInput";
import Styled from "./Styled";

const RenderPhoneField = ({ name, placeholder, type, input, meta, ...res }) => (
  <>
    <PhoneInput
      name={name}
      placeholder={placeholder}
      type={type}
      {...input}
      {...res}
    />
    {meta?.touched && meta?.error && (
      <Styled.ErrorMessage>{meta?.error}</Styled.ErrorMessage>
    )}
  </>
);

export default RenderPhoneField;

import React from 'react';

import StyledMainPageLogin from "../MainPage.Login/styled/StyledMainPage";
import ResetForm from "./MainPage.ResetForm/MainPage.ResetForm"
import {withRouter} from "react-router";


const MainPageReset = ({ location }) => {
    const code = new URLSearchParams(location.search).get('code');
    return (
        <StyledMainPageLogin>
            <h1>Welcome to<br/>Digimex Program</h1>
            <ResetForm code={code} />
        </StyledMainPageLogin>
    )
};


export default withRouter(MainPageReset);
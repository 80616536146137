import styled from "styled-components";
import { Select } from "antd";

const MaltySelect = styled(Select)`
  min-height: 48px;
  border-radius: 4px;
  &&& .ant-select-selector {
    min-height: 48px;
  }
`;

export default MaltySelect;

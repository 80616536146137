import React from 'react';
import { Field, reduxForm } from "redux-form";
import { Form } from "antd";
import { passwordValidate as validate } from '../../../../utils/validate'


import Input from "../../../../components/RenderField/RenderField";
import Button from "../../../../components/Button/Button";
import { changePasswordAction } from "../../../../services/password/password.action";

const ChangePasswordForm = ({ handleSubmit }) => {
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Form.Item>
                    <Field
                        name='currentPassword'
                        component={Input}
                        placeholder='Current password'
                        type={"password"}
                    />
                </Form.Item>
                <Form.Item>
                    <Field
                        name='newPassword'
                        component={Input}
                        placeholder='New password'
                        type={"password"}
                    />
                </Form.Item>
                <Form.Item>
                    <Field
                        name='confirmNewPassword'
                        component={Input}
                        placeholder='Confirm new password'
                        type={"password"}
                    />
                </Form.Item>
                <Button htmlType='submit' >Change</Button>
            </form>
        </>
    )
};

export default reduxForm({
    validate,
    form: "changePasswordFrom",
    onSubmit: (value, dispatch) => dispatch(changePasswordAction(value)),
})(ChangePasswordForm)
import * as specialitiesActions from "./specialities.actions";
import initialState from "../../store/initialState";

export default (state = initialState.specialities, action) => {
  switch (action.type) {
    case specialitiesActions.GET_SPECIALITIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

import { GET_ARTICLES_SUCCESS } from "./articles.actions";
import initialState from "../../store/initialState";

export default (state = initialState.articles, action) => {
  switch (action.type) {
    case GET_ARTICLES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

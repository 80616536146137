import styled from "styled-components";
import { DEVICE } from "../../../constants/media";

const Page = styled.div`
  padding: 60px 20px 36px 20px;

  @media ${DEVICE.laptop} {
    padding: 105px 90px 92px 90px;
  }
`;

export default {
  Page
};

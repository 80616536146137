import styled from "styled-components";
import { isTablet } from "react-device-detect";

const StyledMobileGoBack = styled.div`
  position: absolute;
  width: 13px;
  height: 12px;
  left: 0;
  top: ${isTablet ? "10px;" : "10px;"};
  z-index: 2;
  background: url(${props => props.bg}) center center no-repeat;
  background-size: cover;
`;

export default StyledMobileGoBack;

import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import { history } from "../routes/urlLocation";

import { LOGOUT } from "./logout/logout.action";
import initialState from "../store/initialState";
import userReducer from "./user/user.reducer";
import webinarsReducer from "./webinars/webinars.reducers";
import sessionsReducer from "./sessions/sessions.reducer";
import articlesReducer from "./articles/articles.reducer";
import expertsReducer from "./experts/experts.reducer";
import examsReducer from "./exams/exams.reducer";
import sanofiReducer from "./sanofi/sanofi.reducer";
import interestsReducer from "./interests/interests.reducer";
import popupReducer from "./popup/popup.reducer";
import favoritesReducer from "./favorites/favorites.reducer";
import catalogueReducer from "./catalogue/catalogue.reducer";
import newslettersReducer from "./newsletters/newsletters.reducer";
import userInterestReducer from "./userInterests/userInterests.reducer";
import loaderReducer from "./loader/loader.reducer";
import peer2peerReducer from "./peer2peer/peer2peer.reducer";
import countriesReducer from "./countries/countries.reducer";
import filesReducer from "./file/file.reducer";
import specialitiesReducer from "./specialities/specialities.reducer";

const appReducer = combineReducers({
  user: userReducer,
  specialities: specialitiesReducer,
  countries: countriesReducer,
  favorites: favoritesReducer,
  webinars: webinarsReducer,
  sessions: sessionsReducer,
  articles: articlesReducer,
  experts: expertsReducer,
  exams: examsReducer,
  sanofi: sanofiReducer,
  interests: interestsReducer,
  popup: popupReducer,
  posts: peer2peerReducer,
  files: filesReducer,
  catalogue: catalogueReducer,
  newsletters: newslettersReducer,
  userInterests: userInterestReducer,
  loader: loaderReducer,
  form: formReducer,
  router: connectRouter(history),
});

const rootReducers = (state, action) => {
  if (action.type === LOGOUT) {
    state = initialState;
  }
  return appReducer(state, action);
};

export default rootReducers;

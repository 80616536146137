import React from 'react';

import ArticlesList from './ArticlesPage.List/ArticlesPage.List';
import StyledArticlePage from './styled/StyledActiclePage';
import Interests from "../../components/Interests/Interests";
import {useLocation} from "react-router";

const ArticlePage = () => {
	const { search } = useLocation()
	return(
		<StyledArticlePage>
			{ !search &&  <Interests/> }
			<ArticlesList/>
		</StyledArticlePage>
	)
};

export default ArticlePage;

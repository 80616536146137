import React from 'react';
import { Field } from 'redux-form';

import ClaimCheckbox from "../ClaimCheckbox/ClaimCheckbox";
import Styled from './ClaimPoints.Styled'

const ClaimPointsField = ({ item: { id, title, points }, onChange }) => {
    return (
        <Styled.ClaimItem>
            <Field onChange={onChange} name={btoa(id)} component={ClaimCheckbox} />
            <Styled.ClaimItemPoints>{points} Points</Styled.ClaimItemPoints>

            <h4>{ title }</h4>
        </Styled.ClaimItem>
    )
};

export default ClaimPointsField;
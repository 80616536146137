import {
  GET_POSTS_COUNT_SUCCESS,
  GET_POSTS_SUCCESS,
  CREATE_POST_SUCCESS,
  SET_POSTS,
  GET_PENDING_POSTS_COUNT_SUCCESS,
} from "./peer2peer.actions";
import initialState from "../../store/initialState";

export default (state = initialState.posts, action) => {
  switch (action.type) {
    case GET_POSTS_SUCCESS:
      return {
        ...state,
        list: [...state.list, ...action.payload],
      };
    case CREATE_POST_SUCCESS:
      return {
        ...state,
        list: [...state.list],
      };
    case GET_POSTS_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload,
      };
    case GET_PENDING_POSTS_COUNT_SUCCESS:
      return {
        ...state,
        pendingCount: action.payload,
      };
    case SET_POSTS:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};

import React from 'react'
import StyledLogo from './styled/StyledLogo'
import LogoMain from '../../assets/img/digimaxlogo.svg'

const Logo = () => (
	<StyledLogo>
		<img src={LogoMain} alt="Digimex"/>
	</StyledLogo>
);

export default Logo;



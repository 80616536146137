import React from "react";
import { useDispatch } from "react-redux";
import { CameraOutlined, PaperClipOutlined } from "@ant-design/icons";

import Styled from "../Styled";
import { fileUploadAction } from "../../../services/file/file.action";
import {
  isFormatSupported,
  mediaFilesSupport,
  documentsFilesSupport,
} from "./Peer2peer.utils";
import { openNotification } from "../../../utils/notifications";

const Peer2PeerUploaders = () => {
  const dispatch = useDispatch();
  return (
    <Styled.UploaderWrap>
      <Styled.Uploader
        customRequest={({ file }) =>
          dispatch(fileUploadAction({ file, uploaderName: "peer2peer" }))
        }
        showUploadList={false}
        beforeUpload={({ type }) => {
          if (!isFormatSupported(type, mediaFilesSupport)) {
            openNotification({
              type: "error",
              message: "Error",
              description: "Unsupported file format, please select other",
            });
            return false;
          }
        }}
      >
        <CameraOutlined />
        Photo / Video
      </Styled.Uploader>
      <Styled.Uploader
        customRequest={({ file }) =>
          dispatch(fileUploadAction({ file, uploaderName: "peer2peer" }))
        }
        showUploadList={false}
        beforeUpload={({ type }) => {
          if (!isFormatSupported(type, documentsFilesSupport)) {
            openNotification({
              type: "error",
              message: "Error",
              description: "Unsupported file format, please select other",
            });
            return false;
          }
        }}
      >
        <PaperClipOutlined />
        PDF / PPT File
      </Styled.Uploader>
    </Styled.UploaderWrap>
  );
};

export default Peer2PeerUploaders;

import React from 'react';
import isEmpty from "lodash/isEmpty";

import SessionsList from "./Sessions.List/Sessions.List";
import StyledSessions from './styled/StyledSessions';

const Sessions = ({ title = '', sessions }) => (
    <>
        { !isEmpty(sessions) && <StyledSessions>
            <h3>{ title } </h3>
            <SessionsList
                sessions={sessions}
            />
        </StyledSessions> }
    </>
);

export default Sessions;
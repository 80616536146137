import React from "react";
import { useSelector } from "react-redux";

import RegistrationPageForm from "./RegistrationPage.Form";
import Styled from "./Styled";
import { selectFile } from "../../services/file/file.selector";

const RegistrationPage = () => {
  const profilePic = useSelector((state) => selectFile(state, "registration"));
  return (
    <Styled.Page>
      <Styled.Title>Create your account</Styled.Title>
      <RegistrationPageForm
        profilePic={profilePic?.id}
        initialValues={{
          firstName: "",
          lastName: "",
          password: "",
          hospital: "",
          specialityNew: "",
          country: "",
          city: "",
          email: "",
          mobile: "",
          scfhs_id:""
        }}
      />
    </Styled.Page>
  );
};

export default RegistrationPage;

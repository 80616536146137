import { GET_EXAMS_SUCCESS, SET_EXAM_SUCCESS } from "./exams.action";
import initialState from "../../store/initialState";

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EXAMS_SUCCESS:
      return {
        ...state,
        list: {
          ...action.payload
        }
      };
    case SET_EXAM_SUCCESS:
      return { ...state, result: { ...action.payload } };
    default:
      return state;
  }
};

export const passwordValidate = (values) => {
  const passwordValidation = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
  const passwordErrorMessage =
    "Your password must be at least 8 characters long, " +
    "contain at least one number and have a mixture of uppercase and lowercase letters.";
  const errors = {};
  if (values.password && !passwordValidation.test(values.password)) {
    errors.password = passwordErrorMessage;
  }
  if (
    values.passwordConfirmation &&
    !passwordValidation.test(values.passwordConfirmation)
  ) {
    errors.passwordConfirmation = passwordErrorMessage;
  }
  if (values.newPassword && !passwordValidation.test(values.newPassword)) {
    errors.newPassword = passwordErrorMessage;
  }
  if (
    values.confirmNewPassword &&
    !passwordValidation.test(values.confirmNewPassword)
  ) {
    errors.confirmNewPassword = passwordErrorMessage;
  }

  return errors;
};

export const requiredFieldsValidate = (values, requiredFields = []) => {
  const entries = Object.entries(values);
  const fields = requiredFields
    ? entries.filter((f) => requiredFields.includes(f[0]))
    : entries;
  return fields.reduce((acc, field) => {
    const [name, value] = field;
    if (!value) {
      return { ...acc, [name]: "Field Is Required" };
    } else {
      return acc;
    }
  }, {});
};

import { call, put, takeEvery } from "redux-saga/effects";

import {
  RESEND_EMAIL,
  RESEND_EMAIL_FAILURE,
  RESEND_EMAIL_SUCCESS,
} from "./resendEmail.action";
import { resendEmailRequest } from "./resendEmail.api";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";
import { SET_POPUP_STATUS } from "../popup/popup.action";
import openNotification, {
  prepareErrorMessages,
} from "../../utils/notifications";

function* resendEmail({ payload }) {
  try {
    yield put(startLoaderAction());
    yield resendEmailRequest(payload);

    yield put({
      type: RESEND_EMAIL_SUCCESS,
    });
    yield put({
      type: SET_POPUP_STATUS,
      payload: {
        status: null,
      },
    });
    yield call(openNotification, {
      type: "success",
      message: "Success",
      description: "Email was successfully resent",
    });
  } catch ({ response }) {
    yield put({ type: RESEND_EMAIL_FAILURE });
    yield call(openNotification, {
      type: "error",
      message: "Error",
      description: prepareErrorMessages(response),
    });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* resendEmailSaga() {
  yield takeEvery(RESEND_EMAIL, resendEmail);
}

import React from "react";
import { useSelector } from "react-redux";

import Styled from "./Styled";
import { userInterestsSelector } from "../../services/userInterests/userInterests.selector";

const Peer2PeerTags = ({ selectInterest, selectedInterests }) => {
  const interests = Object.values(useSelector(userInterestsSelector));

  const handleTagClick = (id) => {
    if (selectedInterests.includes(id)) {
      const newIds = selectedInterests.filter((elId) => elId !== id);
      selectInterest(newIds);
      return;
    }
    selectInterest([...selectedInterests, id]);
  };

  return (
    <div>
      <Styled.TagsTitle>Search by tags: </Styled.TagsTitle>
      <ul>
        {interests.map((interest) => (
          <Styled.Tag
            onClick={() => handleTagClick(interest.id)}
            color={interest.color}
            key={interest.id}
            isSelected={selectedInterests.includes(interest.id)}
          >
            {interest.Name}
          </Styled.Tag>
        ))}
      </ul>
    </div>
  );
};

export default Peer2PeerTags;

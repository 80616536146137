import React from 'react';

import Exam from '../ExamsPage.Exam/ExamsPage.Exam';
import StyledExamsPageList from './styled/StyledExamsPageList';

const ExamsList = ({ exams }) =>
    <StyledExamsPageList>
        { !!exams && exams.map(exam =>
            <Exam key={exam.id} exam={exam} />
        )}
    </StyledExamsPageList>;

export default ExamsList;
import React from 'react';
import {connect} from "react-redux";

import {setPopupStatusAction} from "../../../services/popup/popup.action";
import Button from "../../../components/Button/Button";
import Styled from './MainPage.Disclaimer.Styled';
import AppStoreImg from "../../../assets/img/sanofi-new.png";

const Disclaimer = ({submitRef, setPopupStatus}) => <>
    <h3>Disclaimer</h3>
    {/*<Styled.P>*/}
    {/*    One JLT Building, Level 3, Jumeraih Lake Towers , PO Box 53899, Dubai, UAE*/}
    {/*</Styled.P>*/}
    <Styled.P>
        This program is created and delivered by OTB Life Science & Technology Consultancy FZ LLC,
        in collaboration with various international Academic Institutes and speakers.
        Neither OTB Life Science nor the sponsor are responsible and have no influence on the scientific content of
        the program. For Privacy related topics, please refer to the
        <a href={"https://digimex-emails.s3-eu-west-1.amazonaws.com/otb_privacy.pdf"}
           rel="noopener noreferrer" target={"_blank"}> Privacy Policy</a> section.
    </Styled.P>
    <Styled.P>
        This is an educational support provided by
        <Styled.Img src={AppStoreImg} alt="Download on the App Store" />
    </Styled.P>
    <br/>
    {/*<Styled.P>*/}
    {/*    Please do not share this message or the link here in with anyone.*/}
    {/*    FFor further medical information, please contact: For UAE ✆ 800 MEDICAL Toll Free Number. For all Gulf*/}
    {/*    countries ✆ <a href={"tel:00971 565776791"}>+971 565776791</a> or email: <a*/}
    {/*    href={"mailto:medical-information.gulf@sanofi.com"}>medical-information.gulf@sanofi.com</a>. Full*/}
    {/*    prescribing information is*/}
    {/*    available upon request. To Report adverse events please call: ✆ +971 561747001 or*/}
    {/*    email: <a href={"mailto:gulf.pharmacovigilance@sanofi.com"}>gulf.pharmacovigilance@sanofi.com</a> | <a*/}
    {/*    href={"https://www.sanofi.com"} rel="noopener noreferrer" target={"_blank"}>www.sanofi.com</a>*/}
    {/*</Styled.P>*/}
    <Styled.Buttons>
        <Button onClick={() => {
            submitRef.current.click()
        }}>Agree</Button>
        <Button styledtype='cancel' onClick={() => setPopupStatus(null)}>Disagree</Button>
    </Styled.Buttons>

</>;


export default connect(() => ({}), {
    setPopupStatus: setPopupStatusAction
})(Disclaimer);

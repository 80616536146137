import styled from 'styled-components';
const StyledAppLinkPanel = styled.div`

		&> div{
			display: flex;
	        width: 100%;
	        justify-content: center;
	        height: 62px;
	        align-items: center;
	        padding: 15px 0 0;
		}
		
		h5 {
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			color: #fff;
			margin-top: 20px;
			margin-bottom: 15px;
			text-align: center;	
		}
        
        a {
            display: inline-block;
            margin-left: 13px;
            
            &:first-of-type {
                margin-left: 0;
            }
        }
`;

export default StyledAppLinkPanel;
import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledProductItem = styled.div`
    width: 310px;
    height: 290px;
    margin: 25px 13px;
    min-height: 235px;
    position: relative;
    border-radius: 8px;
	-webkit-box-shadow: 3px 3px 12px 0px rgba(50, 50, 50, 0.4);
	-moz-box-shadow:    3px 3px 12px 0px rgba(50, 50, 50, 0.4);
	box-shadow:         3px 3px 12px 0px rgba(50, 50, 50, 0.4);
  
    background:  url(${props => props.img}) center center no-repeat;
    background-size: cover;
    
    @media ${DEVICE.mobileDevices} {
        width: 45%;
        height: 200px;
        margin: 8px;
    }
     
     @media ${DEVICE.laptop} {
        margin: 20px;
     } 
       
     @media ${DEVICE.mobileTabletDevices} {
        width: 100%;
        min-height: 300px;
     }
      
    a {
       display: block;
        width: 100%;
        height: 100%;
    }
    
    h3 {
	    position: absolute;
	    bottom: 49px;
	    left: 0;
	    padding-left: 22px;
	    
	  font-family: var(--fontAvenirHeavy);
	  font-size: 30px;
	  font-weight: 900;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.5;
	  letter-spacing: normal;
	  text-align: left;
	  color: var(--white);
	  
	  @media ${DEVICE.mobileDevices} {
        font-size: 20px;
      }
    }
    p {
        position: absolute;
	    bottom: 28px;
	    left: 0;
	    padding-left: 22px;
		font-size: 14px;
		font-weight: 900;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.93;
		letter-spacing: normal;
		text-align: left;
		color: var(--white);
		
    }
`
export default StyledProductItem;
import styled from "styled-components";
import { Checkbox } from "antd";

const ClaimCheckbox = styled(Checkbox)`
  &&& {
    .ant-checkbox-inner {
      width: 27px;
      height: 27px;
      border: 3px solid var(--blueExams);
      &:after {
        width: 9px;
        height: 13px;
        z-index: 2;
      }
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: var(--blueExams);
      }
      &:after {
        border: 3px solid var(--blueExams);
        background: var(--blueExams);
      }
    }
  }
`;

export default ClaimCheckbox;

import React from 'react';

import Styled from './styled';

const CatalogueCard = ({
        catalogueItem:
            { title,
                thumbnail: { url : thumbnail },
                fileData: { url: fileUrl }
            }
    }) => (
    <Styled.Card>
        <Styled.CatalogueItem href={fileUrl} target={"__blank"} >
            <img src={thumbnail} alt=""/>
        </Styled.CatalogueItem>
        <Styled.CatalogueItemTitle>
            {title}
        </Styled.CatalogueItemTitle>
    </Styled.Card>
);


export default   CatalogueCard;
import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledExpertPage = styled.div`
    padding: 60px 20px 36px 20px;  
     
    @media ${DEVICE.laptop} {
       padding: 105px 90px 92px 90px;  
    }  
    &&& {
	    .ant-btn {
	            width: 100%;
			    max-width: 500px;
			    display: block;
			    margin: 0 auto;
			    
			    @media ${DEVICE.laptopL} {
                    max-width: 230px;
				    margin: 0;  
                }
	     }
    }
`
export default StyledExpertPage;
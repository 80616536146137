import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledSessionCard = styled.div`
	width: 250px;
    height: 320px;
    min-width: 250px;
    min-height: 300px;
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.07);
    background-color: var(--white);
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 14px;
    margin-bottom: 20px;
    
     @media ${DEVICE.laptop} {
          min-width: 320px;
	      width: 320px;
          height: 340px;
          min-height: 340px;
          margin-left: 20px;
          margin-right: 20px;
	 }
   
    h4 {
      font-size: 16px;
      font-family: var(--fontAvenirHeavy);
	  font-weight: 900;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.38;
	  letter-spacing: -0.09px;
	  text-align: left;
	  color: var(--titleCards);
	  margin-bottom: 5px;
	  padding-left: 20px;
	  padding-right: 30%;
	  min-height: 60px;
	  
	  @media ${DEVICE.laptop} {
         min-height: 80px;
	  }
    }
    h5 {
      font-size: 14px;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.36;
	  letter-spacing: -0.08px;
	  text-align: left;
	  color: var(--subtitleCards);
	  margin-bottom: 10px;
	  padding-left: 20px;
	  padding-right: 20px;
    }
    p {
      font-family: var(--fontAvenirHeavy);
      font-size: 20px;
	  font-weight: 900;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.35;
	  letter-spacing: -0.11px;
	  text-align: left;
	  color: var(--sessionCBlueColor);
    }
`;


const CardInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
`;

const StarIcon = styled.img`
  cursor: pointer;
`;


export default {
    Card: StyledSessionCard,
    CardInfoRow,
    StarIcon
};
import { call, put, select, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import isEmpty from "lodash/isEmpty";

import { countriesRequest } from "./countries.api";
import * as countriesActions from "./countries.actions";
import * as countriesSelectors from "./countries.selectors";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* getCountriesOnLocationChangeData() {
  try {
    const countries = yield select(countriesSelectors.countriesSelector);
    if (isEmpty(countries)) {
      yield put({ type: countriesActions.GET_COUNTRIES });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchCountries() {
  try {
    yield put(startLoaderAction());
    const { response } = yield call(countriesRequest);

    yield put({
      type: countriesActions.GET_COUNTRIES_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    yield put({ type: countriesActions.GET_COUNTRIES_FAILURE });
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* countriesSaga() {
  yield takeEvery(LOCATION_CHANGE, getCountriesOnLocationChangeData);
  yield takeEvery(countriesActions.GET_COUNTRIES, fetchCountries);
}

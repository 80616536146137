import { put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  GET_SESSIONS_SUCCESS,
  GET_SESSIONS,
  SET_SESSION_LOG,
  GET_SESSIONS_FAILURE,
  SET_SESSION_LOG_SUCCESS,
  SET_SESSION_LOG_FAILURE,
} from "./sessions.action";
import { sessionsRequest, setSessionLogRequest } from "./sessions.api";
import { urlLocations } from "../../routes/urlLocation";
import { isUrlMatch } from "../router/router.utils";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* getSessionsOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.webinars)) {
      yield put({ type: GET_SESSIONS });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchSessions() {
  try {
    yield put(startLoaderAction());

    const { data, status } = yield sessionsRequest();
    if (status === 200) {
      yield put({
        type: GET_SESSIONS_SUCCESS,
        payload: { ...data },
      });
    }
  } catch (error) {
    yield put({ type: GET_SESSIONS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* setSessionLog({ payload }) {
  try {
    yield put(startLoaderAction());

    const { status } = yield setSessionLogRequest({ ...payload });
    if (status === 200) {
      yield put({
        type: SET_SESSION_LOG_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: SET_SESSION_LOG_FAILURE,
    });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* sessionsSaga() {
  yield takeEvery(LOCATION_CHANGE, getSessionsOnLocationChange);
  yield takeEvery(GET_SESSIONS, fetchSessions);
  yield takeEvery(SET_SESSION_LOG, setSessionLog);
}

import styled from 'styled-components'
import { DEVICE } from '../../../constants/media';

const StyledExpertsPage = styled.div`
    padding: 40px 20px 40px 20px;
     
    @media ${DEVICE.laptopL} {
       padding: 60px 20px 40px 90px;
    }
`
export default StyledExpertsPage;
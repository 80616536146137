import { createMatchSelector } from "connected-react-router";
import get from "lodash/get";
import find from "lodash/find";

import { urlLocations } from "../../routes/urlLocation";

export const expertsSelector = state => Object.values(state.experts);

export const exportSelector = state => {
  const getMatch = createMatchSelector(urlLocations.expertsDetails);
  const pathId = get(getMatch(state), "params.id");
  const experts = Object.values(state.experts);
  return find(experts, expert => expert.id.toString() === pathId);
};

import React from "react";
import { connect } from "react-redux";
import { Avatar, Badge, Dropdown, Menu } from "antd";
import { createStructuredSelector } from "reselect";
import { Link } from "react-router-dom";
import { urlLocations, history } from "../../routes/urlLocation";
import DropDownIcon from "../../assets/img/chevron-down.svg";
import NotificationIcon from "../../assets/img/icon-notification.svg";
import AvatarImg from "../../assets/img/avatar.png";
import MobileNav from "../../assets/img/menu-icon.svg";
import MobileGoBack from "../../assets/img/back-icon.svg";
import { getUserInfoSelector } from "../../services/user/user.selector";
import Styled from "./styled/Header.Styled";
import StyledIconNotification from "./styled/StyledIconNotification";
import StyledUserBlock from "./styled/StyledUserBlock";
import StyledMobileNav from "./styled/StyledMobileNav";
import StyledMobileGoBack from "./styled/StyledMobileGoBack";
import { isMobile } from "react-device-detect";

const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to={urlLocations.user}>Profile</Link>
    </Menu.Item>
  </Menu>
);

const Header = ({ user, title, onCollapse }) => (
  <Styled.Header paddingAttr={window.location.pathname !== urlLocations.main}>
    <h1>
      {isMobile && window.location.pathname !== urlLocations.main && (
        <StyledMobileGoBack
          onClick={() => {
            urlLocations.main !== history.location.pathname && history.goBack();
          }}
          bg={MobileGoBack}
        />
      )}
      {title}
    </h1>

    {isMobile && <StyledMobileNav onClick={onCollapse} bg={MobileNav} />}

    <StyledUserBlock>
      <Badge dot>
        <StyledIconNotification>
          <img src={NotificationIcon} alt="" />
        </StyledIconNotification>
      </Badge>

      <Avatar shape="circle" src={user?.profilePic?.url || AvatarImg} />

      <Dropdown overlay={menu}>
        <span className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <span> {`${user.firstName} ${user.lastName}`} </span>
          <img src={DropDownIcon} alt="" />
        </span>
      </Dropdown>
    </StyledUserBlock>
  </Styled.Header>
);

export default connect(
  createStructuredSelector({
    user: getUserInfoSelector,
  })
)(Header);

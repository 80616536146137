import React from "react";
import { Switch } from "react-router";

import { urlLocations } from "./urlLocation";
import RouteWrapper from "./RouterWrapper";
import MainLayout from "../layouts/MainLayout/MainLayout";
import AuthorizeLayout from "../layouts/AuthorizeLayout/AuthorizeLayout";
import Containers from "./containers";

export default () => (
  <>
    <Switch>
      <RouteWrapper
        exact
        path={urlLocations.root}
        layout={MainLayout}
        component={Containers.MainPage}
      />
      <RouteWrapper
        exact
        path={urlLocations.resetPassword}
        layout={MainLayout}
        component={Containers.MainPage}
      />
      <RouteWrapper
        exact
        path={urlLocations.registration}
        layout={MainLayout}
        component={Containers.MainPage}
      />
      <RouteWrapper
        exact
        path={urlLocations.examResult}
        layout={AuthorizeLayout}
        component={Containers.PrewebinarExamResultPage}
        title={"Test Your Knowledge"}
      />
      <RouteWrapper
        exact
        path={urlLocations.favorites}
        layout={AuthorizeLayout}
        component={Containers.FavoritesPage}
        title={"Favorites"}
      />
      <RouteWrapper
        exact
        path={urlLocations.user}
        layout={AuthorizeLayout}
        component={Containers.UserPage}
        title={"Update Profile"}
      />
      <RouteWrapper
        exact
        path={urlLocations.aboutUs}
        layout={AuthorizeLayout}
        component={Containers.AboutUsPage}
        title={"About Digimex"}
      />
      <RouteWrapper
        exact
        path={urlLocations.experts}
        layout={AuthorizeLayout}
        component={Containers.ExpertsPage}
        title={"Experts"}
      />
      <RouteWrapper
        exact
        path={urlLocations.expertsDetails}
        layout={AuthorizeLayout}
        component={Containers.ExpertPage}
        title={"Expert Profile"}
      />
      <RouteWrapper
        exact
        path={urlLocations.exams}
        layout={AuthorizeLayout}
        component={Containers.ExamsPage}
        title={"Test Your Knowledge"}
      />
      <RouteWrapper
        exact
        path={urlLocations.exam}
        layout={AuthorizeLayout}
        component={Containers.ExamPage}
        title={"Test Your Knowledge"}
      />
      <RouteWrapper
        exact
        path={urlLocations.webinarDetails}
        layout={AuthorizeLayout}
        component={Containers.WebinarPage}
        title={"Webinar Details"}
      />
      <RouteWrapper
        exact
        path={urlLocations.webinars}
        layout={AuthorizeLayout}
        component={Containers.WebinarsPage}
        title={"Webinars"}
      />
      <RouteWrapper
        exact
        path={urlLocations.resources}
        layout={AuthorizeLayout}
        component={Containers.ResourcesPage}
        title={"Resources"}
      />
      <RouteWrapper
        exact
        path={urlLocations.newsLetters}
        layout={AuthorizeLayout}
        component={Containers.NewslettersPage}
        title={"Newsletters"}
      />
      <RouteWrapper
        exact
        path={urlLocations.library}
        layout={AuthorizeLayout}
        component={Containers.ArticlesPage}
        title={"Library"}
      />
      <RouteWrapper
        exact
        path={urlLocations.catalogue}
        layout={AuthorizeLayout}
        component={Containers.CataloguePage}
        title={"Catalogue"}
      />
      <RouteWrapper
        exact
        path={urlLocations.sanofi}
        layout={AuthorizeLayout}
        component={Containers.SanofiPage}
        title={"Meet Sanofi"}
      />
      <RouteWrapper
        exact
        path={urlLocations.examSuccess}
        layout={AuthorizeLayout}
        component={Containers.ExamSuccessPage}
        title={"Results"}
      />
      <RouteWrapper
        exact
        path={urlLocations.examFail}
        layout={AuthorizeLayout}
        component={Containers.ExamFailPage}
        title={"Results"}
      />
      <RouteWrapper
        exact
        path={urlLocations.peer2peer}
        layout={AuthorizeLayout}
        component={Containers.Peer2Peer}
        title={"Peer 2 Peer"}
      />
      <RouteWrapper
          exact
          path={urlLocations.skillUp}
          layout={AuthorizeLayout}
          component={Containers.SkillUp}
          title={"SkillUp"}
      />
      <RouteWrapper
        exact
        path={urlLocations.home}
        layout={AuthorizeLayout}
        component={Containers.HomePage}
        title={"Program Sections"}
      />

    </Switch>
  </>
);

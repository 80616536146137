import { SET_POPUP_STATUS } from "./popup.action";
import initialState from "../../store/initialState";

export default (state = initialState.popup, action) => {
  switch (action.type) {
    case SET_POPUP_STATUS:
      return action.payload;
    default:
      return state;
  }
};

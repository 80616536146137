import React from "react";
import { useSelector } from "react-redux";
import { Select as AntSelect } from "antd";

import Select from "../../components/Select/Select";
import { userInterestsSelector } from "../../services/userInterests/userInterests.selector";
import Styled from "./Styled";

const { Option } = AntSelect;

const Peer2PeerSearch = ({ selectInterest, selectedInterests }) => {
  const interests = Object.values(useSelector(userInterestsSelector));
  return (
    <Styled.Search>
      <Select
        value={selectedInterests}
        mode="tags"
        placeholder="Search"
        onChange={selectInterest}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        tagRender={({ label, value }) => {
          return (
            <Styled.Tag color={interests.find((i) => i.id === value)?.color}>
              {label}
            </Styled.Tag>
          );
        }}
      >
        {interests?.map((interest) => (
          <Option
            value={interest.id}
            children={interest.Name}
            key={interest.id}
            color={interest.color}
          />
        ))}
      </Select>
    </Styled.Search>
  );
};

export default Peer2PeerSearch;

import React from 'react';
import styled, {css} from 'styled-components';
import { Button } from 'antd';
import { DEVICE } from '../../../constants/media';
import { isMobileOnly } from "react-device-detect";

const StyledWebinarButton = styled(({ ...props }) => (
	<Button {...props} />
))`
   && {
		  display: flex;
		  align-items: center;
          width: 100%;
		  height: 50px;
          border-radius: 8px;
          margin-bottom: 15px;
          background: var(--darkBlue);
          box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.07);
          font-size: 16px;
		  font-weight: 900;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.38;
		  letter-spacing: -0.09px;
		  text-align: left;
		  color: var(--white);
		  padding-left: 12px;
		  border: none;
		  position: relative;
		  @media ${DEVICE.tablet} {
		     padding-left: 79px;
		  }
		  
		  ${({styledtype}) => {
				if (isMobileOnly && (styledtype === 'register')) {
					return css`
								 text-align: center;
								 display: block;
                                 margin: 0 auto 50px auto;
                                 padding: 16px;
                                 align-items: center;   
			                `}
			}}
		  
		
		  &:hover {
		    border: none;
		  }
		  i {
		     position: absolute;
		     left: 14px;
		     top: 14px;
		     @media ${DEVICE.tablet} {
		        left: 27px;
		     }
		  }
		  a {
			  width: 100%;
			  height: 100%;
		      color: var(--white);
		  }
		  .react-add-to-calendar__dropdown {
			    position: absolute;
			    top: 100%;
			    left: 1%;
			    width: 98%;
			    z-index: 10;
			    padding: 10px 0 10px 8px;
			    background-color: #fff;
			    text-align: left;  
		   }
		   .react-add-to-calendar__dropdown li{
			    padding: 10px  15px 5px 15px;
		  }
		  .react-add-to-calendar__dropdown  li a{
			    color: var(--darkBlue);
			    font-size: 14px;
		  }
    }
    
`

export default StyledWebinarButton;

import React from "react";
import { Form, Row, Select } from "antd";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { isMobile } from "react-device-detect";
import { createStructuredSelector } from "reselect";

import MaltySelect from "../../components/MaltySelect/MaltySelect";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Styled from "./UserPage.styled";
import ChangePasswordModal from "./ChangePassword/ChangePasswordModal/ChangePasswordModal";
import { setPopupStatusAction } from "../../services/popup/popup.action";
import { POPUPS_ID } from "../../constants";
import { userInterestsSelector } from "../../services/userInterests/userInterests.selector";
import SelectField from "../../components/RenderSelect/RenderSelect";
import { specialitiesSelector } from "../../services/specialities/specialities.selector";

const Option = Select.Option;

const spanSize = isMobile && window.innerWidth < 1024 ? 24 : 12;

const UserPageForm = ({
  handleSubmit,
  setPopupStatus,
  userInterests,
  user,
}) => {
  const specialities = useSelector(specialitiesSelector);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row gutter={60}>
          <Styled.Column span={spanSize}>
            <Styled.SubTitle>Main Information</Styled.SubTitle>
            <Form.Item>
              <Field
                name="firstName"
                component={Input}
                placeholder="First name"
              />
            </Form.Item>
            <Form.Item>
              <Field
                name="lastName"
                component={Input}
                placeholder="Last name"
              />
            </Form.Item>
            <Form.Item>
              <Input disabled value={user?.email} />
            </Form.Item>
            <Form.Item>
              <Field name="mobile" component={Input} placeholder="Phone" />
            </Form.Item>
            <Form.Item>
              <Field
                name="scfhs_id"
                component={Input}
                placeholder="SCFHS ID (For KSA only)"
              />
            </Form.Item>
            <Form.Item>
              <Input
                disabled
                type="password"
                suffix={
                  <Styled.Affix
                    onClick={setPopupStatus.bind(
                      null,
                      POPUPS_ID.CHANGE_PASSWORD_POPUP
                    )}
                  >
                    Change
                  </Styled.Affix>
                }
                value={"Password"}
              />
            </Form.Item>
            {!(isMobile && window.innerWidth < 1024) && (
              <Button htmlType="submit">Update</Button>
            )}
          </Styled.Column>
          <Styled.Column span={spanSize}>
            <Styled.SubTitle>Speciality Information</Styled.SubTitle>
            <Form.Item>
              <Field
                name="specialityNew"
                component={SelectField}
                placeholder="Speciality"
              >
                {specialities.map(({ id, title }) => (
                  <Option value={id} key={id} children={title} />
                ))}
              </Field>
            </Form.Item>
            <Form.Item>
              <Field name="hospital" component={Input} placeholder="Works at" />
            </Form.Item>
            <Form.Item>
              <Input disabled value={user?.country?.name} />
            </Form.Item>
            <Form.Item>
              <Input disabled value={`${user?.cmePoints ?? 0} CME points`} />
            </Form.Item>
            <Form.Item>
              <Field
                name="interests"
                placeholder="Interests"
                component={MaltySelect}
              >
                {userInterests &&
                  userInterests?.map((interest) => (
                    <Option value={interest.id} key={interest.id}>
                      {interest.Name}
                    </Option>
                  ))}
              </Field>
            </Form.Item>
          </Styled.Column>
        </Row>
        {isMobile && window.innerWidth < 1024 && (
          <Button htmlType="submit">Update</Button>
        )}
      </form>
      <ChangePasswordModal />
    </>
  );
};

export default compose(
  connect(
    createStructuredSelector({
      userInterests: userInterestsSelector,
    }),
    {
      setPopupStatus: setPopupStatusAction,
    }
  ),
  reduxForm({
    form: "profileForm",
    enableReinitialize: true,
    onSubmit: (values, _, props) => {
      props.updateProfile({ ...values, profilePic: props?.profilePic?.id });
    },
  })
)(UserPageForm);

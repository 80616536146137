import instance from "../root.api";

export const changePasswordRequest = data =>
  instance
    .put("/users/changeMyPassword", { ...data })
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const forgotPasswordRequest = data =>
  instance
    .post("/auth/forgot-password", { ...data })
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const resetPasswordRequest = data =>
  instance
    .post("/auth/reset-password", { ...data })
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

import { put, takeEvery, select } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import pickBy from "lodash/pickBy";

import * as peer2peerActions from "./peer2peer.actions";
import { urlLocations } from "../../routes/urlLocation";
import { isUrlMatch } from "../router/router.utils";
import {
  getPostsRequest,
  createPostRequest,
  togglePostLikeRequest,
  addPostCommentRequest,
  getPostsCountRequest,
  getPendingPostsCountRequest,
} from "./peer2peer.api";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";
import { SET_POPUP_STATUS } from "../popup/popup.action";
import { postsSelector } from "./peer2peer.selector";

function* getPostsOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.peer2peer)) {
      yield put({ type: peer2peerActions.GET_POSTS });
      yield put({ type: peer2peerActions.GET_POSTS_COUNT });
      yield put({ type: peer2peerActions.GET_PENDING_POSTS_COUNT });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchPosts({ payload }) {
  try {
    yield put(startLoaderAction());
    const filteredParams = pickBy(payload);

    const { data } = yield getPostsRequest({
      _limit: 20,
      _start: filteredParams._start || 0,
    });

    yield put({
      type: peer2peerActions.GET_POSTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: peer2peerActions.GET_POSTS_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* fetchPostsCount() {
  try {
    yield put(startLoaderAction());

    const { data } = yield getPostsCountRequest();

    yield put({
      type: peer2peerActions.GET_POSTS_COUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: peer2peerActions.GET_POSTS_COUNT_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* fetchPendingPostsCount() {
  try {
    yield put(startLoaderAction());

    const { data } = yield getPendingPostsCountRequest();

    yield put({
      type: peer2peerActions.GET_PENDING_POSTS_COUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: peer2peerActions.GET_PENDING_POSTS_COUNT_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* createPost({ payload }) {
  try {
    yield put(startLoaderAction());
    const { data } = yield createPostRequest(payload);

    yield put({
      type: peer2peerActions.CREATE_POST_SUCCESS,
      payload: data,
    });
    yield put({
      type: SET_POPUP_STATUS,
      payload: null,
    });
    yield put({ type: peer2peerActions.GET_POSTS });
    yield put({ type: peer2peerActions.GET_PENDING_POSTS_COUNT });
  } catch (error) {
    yield put({ type: peer2peerActions.CREATE_POST_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* togglePostLike({ payload }) {
  try {
    yield put(startLoaderAction());
    const { data } = yield togglePostLikeRequest(payload);

    yield put({
      type: peer2peerActions.TOGGLE_POST_LIKE_SUCCESS,
    });
    const posts = yield select(postsSelector);
    const filteredPosts = posts.filter((post) => post.id !== payload.id);
    yield put({
      type: peer2peerActions.SET_POSTS,
      payload: [...filteredPosts, data],
    });
  } catch (error) {
    yield put({ type: peer2peerActions.TOGGLE_POST_LIKE_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

function* addPostComment({ payload }) {
  try {
    yield put(startLoaderAction());
    const { data } = yield addPostCommentRequest(payload);

    yield put({
      type: peer2peerActions.ADD_POST_COMMENT_SUCCESS,
    });
    const posts = yield select(postsSelector);
    const filteredPosts = posts.filter((post) => post.id !== payload.id);
    yield put({
      type: peer2peerActions.SET_POSTS,
      payload: [...filteredPosts, data],
    });
  } catch (error) {
    yield put({ type: peer2peerActions.ADD_POST_COMMENT_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* peer2peerSaga() {
  yield takeEvery(LOCATION_CHANGE, getPostsOnLocationChange);
  yield takeEvery(peer2peerActions.GET_POSTS, fetchPosts);
  yield takeEvery(peer2peerActions.CREATE_POST, createPost);
  yield takeEvery(peer2peerActions.TOGGLE_POST_LIKE, togglePostLike);
  yield takeEvery(peer2peerActions.ADD_POST_COMMENT, addPostComment);
  yield takeEvery(peer2peerActions.GET_POSTS_COUNT, fetchPostsCount);
  yield takeEvery(
    peer2peerActions.GET_PENDING_POSTS_COUNT,
    fetchPendingPostsCount
  );
}

import styled from 'styled-components';
import { DEVICE } from '../../../../constants/media';

const StyledInterest = styled.div`
	  display: inline-flex;
	  align-items: center;
	  width: 150px;
	  min-height: 50px;
	  border-radius: 14px;
	  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.07);
	  background-color: var(--white);
	  margin-left: 5px;
      margin-right: 5px;
      padding: 15px 15px 15px 20px;
      margin-bottom: 20px;
      min-width: 150px;
	  
	  @media ${DEVICE.tablet} {
         width: 205px;
         min-width: 200px;
	     min-height: 70px;
	     padding: 20px;
      }
	  @media ${DEVICE.laptop} {
	     width: 234px;
	     min-height: 90px;
	     height: 90px;
	     margin-left: 10px;
         margin-right: 10px;
	  }
	  @media ${DEVICE.laptopL} {
	     margin-left: 17px;
         margin-right: 17px;
	  }
	  
	  p {
	      display: inline-block;
	      font-family: var(--fontAvenirHeavy);
		  font-size: 14px;
		  padding-left: 10px;
		  font-weight: 900;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.39;
		  width: calc(100% - 34px);
		  letter-spacing: -0.1px;
		  text-align: left;
		  color: var(--titleCards);
		  
		  @media ${DEVICE.tablet} {
	         font-size: 14px;
	         padding-left: 20px;
	      }
		  @media ${DEVICE.laptopL} {
		     font-size: 18px;
		     padding-left: 20px;
		  }
	  }
`;

export default StyledInterest;
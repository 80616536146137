import styled from 'styled-components';
import { DEVICE } from '../../constants/media';

const Page = styled.div`
      padding: 50px 20px 100px 20px;
         
      @media ${DEVICE.laptopL} {
        padding: 50px 50px 100px 90px;  
      }
`;

const A = styled.a`
  margin-bottom: 100px;
  font-family: var(--fontAvenirHeavy);
`;

const P = styled.p`
  margin-bottom: 20px;
`;

const smallP = styled.p`
  margin-bottom: 20px;
  font-size: 12px;
  font-style: italic;
`;

const SubTitle = styled.div`
  margin-bottom: 10px;
  font-family: var(--fontAvenirHeavy);
`;

const UL = styled.ul`
  list-style: disc;
  padding-left: 15px;
  margin-bottom: 20px;
`;

const NumUL = styled.ul`
  list-style: decimal;
  padding-left: 15px;
  margin-bottom: 20px;
`;

const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -30px;
  & > div{
    text-align: center;
    padding: 0 30px;
  }
`;


export default {
    Page,
    A,
    P,
    smallP,
    SubTitle,
    UL,
    NumUL,
    FooterRow,
}
import React from 'react';
import { Form } from 'antd';
import { reduxForm, Field } from "redux-form";

import Input from "../../../../components/Input/Input";
import Button from "../../../../components/Button/Button";
import Styled from "./styled"
import {forgotPasswordAction} from "../../../../services/password/password.action";


const ForgotPasswordForm = ({ handleSubmit }) => {
    return (
        <form onSubmit={handleSubmit}>
            <Styled.Title>
                Enter your email
            </Styled.Title>
            <Form.Item>
                <Field name="email" component={Input} placeholder="Email" />
            </Form.Item>
            <Button htmlType="submit" >Send</Button>
        </form>
    )
};

export default reduxForm({
    form: "forgotPasswordForm",
    onSubmit: (value, dispatch) => dispatch(forgotPasswordAction(value))
})(ForgotPasswordForm)
import React from 'react';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import isEmpty from "lodash/isEmpty";

import Styled from './FavoritesPage.styled';
import Sessions from "../../components/Sessions/Sessions";
import { favoritesSelector } from "../../services/favorites/favorites.selectors";


const FavoritesPage = ({ favorites }) => (
    <Styled.Page>
        {isEmpty(favorites) ?
            <Styled.PlaceHolder>
                You can add any webinar recording to your favorites to easily find it later
            </Styled.PlaceHolder> :
            <Sessions
                videoKey={null}
                sessionIdKey={'id'}
                sessions={favorites}
            />
        }
    </Styled.Page>
);

export default connect(
    createStructuredSelector({
        favorites: favoritesSelector
    }),
    {}
)(FavoritesPage);
import React from 'react';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link } from 'react-router-dom';

import { examSelector } from "../../services/exams/exams.selector";
import { getWebinareFormatedDate } from "../../services/webinars/webinars.utils";
import ExamQuestions from './ExamPage.Questions/ExamPage.Questions';
import ClockIcon from "../../assets/img/clock-icon.svg";
import Styled from './styled/StyledExamPage';
import Button from "../../components/Button/Button";
import StyledWebinarDate from '../styled/StyledWebinarDate';
import { urlLocations } from "../../routes/urlLocation";

const ExamPage = ({ exam }) => {
    return (
        <Styled.ExamPage>
            <Styled.HeaderRow>
                <div>
                    <Styled.H1>{ exam?.title }</Styled.H1>
                    <StyledWebinarDate>
                        <i><img src={ ClockIcon } alt=""/></i>
                        { getWebinareFormatedDate(exam?.startDate) }
                    </StyledWebinarDate>
                </div>
                <Link to={`${urlLocations.webinars}/${exam?.webinarID}`} >
                    <Button>
                        View Webinar
                    </Button>
                </Link>
            </Styled.HeaderRow>

            <Styled.ExamBody>
                { exam?.questions && <ExamQuestions examType={exam.examType} id={exam.id} questions={exam.questions} /> }
            </Styled.ExamBody>
        </Styled.ExamPage>);
};

export default connect(
    createStructuredSelector({
        exam: examSelector
    })
)(ExamPage)

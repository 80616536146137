import React from 'react';
import { urlLocations } from "../../../routes/urlLocation";
import { Link } from "react-router-dom";
import StyledExam from './styled/StyledExam';
import Button from '../../../components/Button/Button';
import { isMobileOnly } from "react-device-detect";

const Exam = (
    { exam: {
        id,
        title,
        interest: {
            color
        },
        isPassed
    }} = {
        exam: {
            id: null,
            title: "",
            interest: {
                color: ''
            },
            isPassed: false
        }
    }
) => (
    <StyledExam color={color}>
            <h3>
	            {isMobileOnly
		            ?   <Link
                        style={{ pointerEvents: isPassed ? 'none' : 'initial'  }}
                        as="div"  to={`${urlLocations.exams}/${id}`}
                        >{title}</Link>
		            : <span>{title}</span>
	            }
            </h3>
        <Link
            style={{ pointerEvents: isPassed ? 'none' : 'initial'  }}
            as="div"  to={`${urlLocations.exams}/${id}`}
        >
            <Button
                styledtype={ isPassed ? "cancel" : "" }
                style={{ pointerEvents: isPassed ? 'none' : 'initial'  }}
                disabled={isPassed} size="small"
            >
               { isPassed ? 'Already Passed' : 'Take The Test'  }
            </Button>
	        {  (isMobileOnly && isPassed) && <span>Already Passed</span>  }
        </Link>
    </StyledExam>
);


export default Exam;
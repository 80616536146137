import { put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  GET_CATALOGUE,
  GET_CATALOGUE_FAILURE,
  GET_CATALOGUE_SUCCESS,
} from "./catalogue.action";
import { urlLocations } from "../../routes/urlLocation";
import { isUrlMatch } from "../router/router.utils";
import { catalogueRequest } from "./catalogue.api";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* getCatalogueOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.catalogue)) {
      yield put({ type: GET_CATALOGUE });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchCatalogue() {
  try {
    yield put(startLoaderAction());

    const { data, status } = yield catalogueRequest();
    if (status === 200) {
      yield put({
        type: GET_CATALOGUE_SUCCESS,
        payload: { ...data },
      });
    }
  } catch (error) {
    yield put({ type: GET_CATALOGUE_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* catalogueSaga() {
  yield takeEvery(LOCATION_CHANGE, getCatalogueOnLocationChange);
  yield takeEvery(GET_CATALOGUE, fetchCatalogue);
}

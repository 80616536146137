import styled from "styled-components";
import { DEVICE } from "../../../constants/media";

const Footer = styled.div`
  padding: 40px 20px;
  text-align: center;
  color: #727170;
  font-size: 12px;
  margin-top: 50px;

  @media ${DEVICE.tablet} {
    padding: 40px 20px 37px 20px;
  }

  @media ${DEVICE.laptop} {
    padding: 0px 16px 25px 16px;
  }

  img {
    padding: 10px 0px 0px 0px;
  }
`;

export default Footer;

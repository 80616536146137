import { GET_SESSIONS_SUCCESS } from "./sessions.action";
import initialState from "../../store/initialState";

export default (state = initialState.sessions, action) => {
  switch (action.type) {
    case GET_SESSIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

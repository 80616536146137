import { put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import {
  GET_ARTICLES,
  GET_ARTICLES_FAILURE,
  GET_ARTICLES_SUCCESS,
} from "./articles.actions";
import { urlLocations } from "../../routes/urlLocation";
import { isUrlMatch } from "../router/router.utils";
import { articlesRequest } from "./articles.api";
import { finishLoaderAction, startLoaderAction } from "../loader/loader.action";

function* getArticlesOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.library)) {
      const { search } = payload.location;
      const params = Object.fromEntries(new URLSearchParams(search));
      yield put({
        type: GET_ARTICLES,
        ...( params && { payload: { params } } )
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchArticles({ payload }) {
  try {
    yield put(startLoaderAction());
    const { data, status } = yield articlesRequest({ params: payload.params });
    if (status === 200) {
      yield put({
        type: GET_ARTICLES_SUCCESS,
        payload: { ...data },
      });
    }
  } catch (error) {
    yield put({ type: GET_ARTICLES_FAILURE });
    console.log(error);
  } finally {
    yield put(finishLoaderAction());
  }
}

export default function* articlesSaga() {
  yield takeEvery(LOCATION_CHANGE, getArticlesOnLocationChange);
  yield takeEvery(GET_ARTICLES, fetchArticles);
}

import { createMatchSelector } from "connected-react-router";
import get from "lodash/get";
import find from "lodash/find";

import { urlLocations } from "../../routes/urlLocation";
import { EXAM_TYPE } from "../../constants";

export const examsSelector = state => Object.values(state.exams.list);

export const examSelector = state => {
  const getMatch = createMatchSelector(urlLocations.exam);
  const pathId = get(getMatch(state), "params.id");
  const exams = Object.values(state.exams.list);
  return find(exams, exam => exam?.id.toString() === pathId);
};

export const preWebinarExamsSelector = state =>
  Object.values(state.exams.list).filter(
    exam => exam.examType === EXAM_TYPE.PRE_WEBINAR
  );

export const postWebinarExamsSelector = state =>
  Object.values(state.exams.list).filter(
    exam => exam.examType === EXAM_TYPE.POST_WEBINAR
  );

export const getExamResult = state => state.exams.result;

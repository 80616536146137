import MainPage from "../containers/MainPage";
import HomePage from "../containers/HomePage/HomePage";
import WebinarsPage from "../containers/WebinarsPage/WebinarsPage";
import WebinarPage from "../containers/WebinarPage/WebinarPage";
import ArticlesPage from "../containers/ArticlesPage/ArticlesPage";
import ExpertsPage from "../containers/ExpertsPage/ExpertsPage";
import ExpertPage from "../containers/ExpertPage/ExpertPage";
import ExamsPage from "../containers/ExamsPage/ExamsPage";
import ExamPage from "../containers/ExamPage/ExamPage";
import SanofiPage from "../containers/SanofiPage/SanofiPage";
import ExamFailPage from "../containers/ExamFailPage/ExamFailPage";
import ExamSuccessPage from "../containers/ExamSuccessPage/ExamSuccessPage";
import UserPage from "../containers/UserPage/UserPage";
import AboutUsPage from "../containers/AboutUsPage/AboutUsPage";
import PrewebinarExamResultPage from "../containers/PrewebinarExamResultPage/PrewebinarExamResultPage";
import FavoritesPage from "../containers/FavoritesPage/FavoritesPage";
import ResourcesPage from "../containers/ResourcesPage/ResourcesPage";
import CataloguePage from "../containers/CataloguePage/CataloguePage";
import NewslettersPage from "../containers/NewslettersPage/NewslettersPage";
import RegistrationPage from "../containers/RegistrationPage/RegistrationPage";
import Peer2Peer from "../containers/Peer2Peer/Peer2Peer";
import SkillUp from "../containers/SkillUp/SkillUp";

export default {
  MainPage,
  HomePage,
  Peer2Peer,
  WebinarsPage,
  WebinarPage,
  ArticlesPage,
  ExpertsPage,
  ExpertPage,
  ExamsPage,
  ExamPage,
  SanofiPage,
  ExamFailPage,
  ExamSuccessPage,
  UserPage,
  AboutUsPage,
  PrewebinarExamResultPage,
  FavoritesPage,
  ResourcesPage,
  CataloguePage,
  NewslettersPage,
  RegistrationPage,
  SkillUp
};

import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledNavMarker = styled.span`
		  height: 70px;
		  border-radius: 14px;
		  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.07);
		  display: flex;
		  align-items: center;
		  padding: 16px 25px; 
          margin-right: 15px;
		  padding: 16px; 
		  background: ${({colorParam}) => colorParam === 'active' ? 'var(--blueExams)' : 'var(--white)' };
		  color: ${({colorParam}) => colorParam === 'active' ? 'var(--white)' : 'var(--blueExams)' };
		  cursor: pointer;
		  transition: all .3s ease-in;
		  font-size: 14px;
		  width: 160px;
		  &:last-child {
		    margin-right: 0;
		  }
		  
		  @media ${DEVICE.tablet} {
             padding: 16px 25px 20px 20px;
             margin-right: 25px;
             font-size: 14px;
             width: auto;
          }
		
      i {
        display: inline-block;
		width: 30px;
	    height: 30px;
	    min-width: 30px;
	    margin-right: 19px;
		border: solid 4px ${({colorParam}) => colorParam === 'active' ? 'var(--white)' : 'var(--blueExams)' };
		position: relative;
		border-radius: 50%;
		cursor: pointer;
		transition: all .3s ease-in;
		display: none;
		@media ${DEVICE.tablet} {
          display: block;
        }
		
		&:after {
			content:'';
			position: absolute;
			display: inline-block;
			width: 16px;
		    height: 16px;
		    top: 0;
		    border-radius: 50%;
		    top: 3px;
		    left: 3px;
		    transition: all .3s ease-in;
		    opacity: ${({colorParam}) => colorParam === 'active' ? '1' : '0'};
			background:  ${({colorParam}) => colorParam === 'active' ? 'var(--white)' : 'var(--blueExams)' };

	    }	 
     
`
export default StyledNavMarker;
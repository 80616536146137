import React from "react";
import StyledPhoneInput from "./Styled";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneInput = ({ input, ...props }) => (
  <StyledPhoneInput>
    <ReactPhoneInput {...input} {...props} />
  </StyledPhoneInput>
);

export default PhoneInput;

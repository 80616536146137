import React from 'react';
import { connect } from "react-redux";
import TextTruncate from 'react-text-truncate';
import { createStructuredSelector } from "reselect";

import Styled from './styled/StyledSessionCard'
import StyledSessionHref from './styled/StyledSessionHref'
import { setPopupStatusAction } from "../../../services/popup/popup.action";
import SessionVideo from '../Sessions.Video/Sessions.Video';
import { POPUPS_ID } from "../../../constants";
import StarIcon from '../../../assets/img/star_icon.svg';
import FilledStarIcon from '../../../assets/img/star_icon_filled.svg';

import { addFavoriteAction, removeFavoriteAction } from '../../../services/favorites/favorites.actions'
import { favoritesIdsSelector } from '../../../services/favorites/favorites.selectors';
import { isFavorite } from "../../../services/favorites/favorites.utils";

const SessionCard = (
	{
		favoritesIDs,
		session,
		setPopupStatus,
		addFavorites,
		removeFavorites,
}) => {
	return (
	    <Styled.Card key={session.id}>
		    <StyledSessionHref>
			    <div
					onClick={setPopupStatus.bind(null, `${POPUPS_ID.VIDEO_POPUP}_${session.id}`)}
				>
				    <i></i>
				    <img src={session.thumbnail.url} alt=""/>
			    </div>
		    </StyledSessionHref>

			<TextTruncate/>
		    <h4>
				<TextTruncate
					line={3}
					truncateText="…"
					text={ session.title }
				/>
		    </h4>
			<Styled.CardInfoRow>
		    	<p>{ session.duration }</p>
				<Styled.StarIcon
					onClick={
						isFavorite(favoritesIDs, session.id) ?
							removeFavorites.bind(null, {
								videoID: session.id,
								sessionID: parseInt(session.id),
							}):
							addFavorites.bind(null, {
							videoID: session.id,
							sessionID: parseInt(session.id),
							})
					}
					src={isFavorite(favoritesIDs, session.id) ? FilledStarIcon : StarIcon}
					alt=""
				/>
			</Styled.CardInfoRow>
            <SessionVideo
				itemType={session?.itemType}
				sessionId={session.id}
				poster={session.thumbnail.url}
				videoId={session.id}
				url={session.video.url}
			/>
	    </Styled.Card>
    )
};


export default connect(createStructuredSelector({
		favoritesIDs: favoritesIdsSelector
	}), {
		setPopupStatus: setPopupStatusAction,
		addFavorites: addFavoriteAction,
		removeFavorites: removeFavoriteAction
	})(SessionCard);
import React, { useState } from 'react';
import Select from "./styled"
import isArray from "lodash/isArray";

const MaltySelect = ({ children, input: { onBlur, ...rest }, meta }) => {
    const [ selectedValue, setSelectedValue ] = useState([]);

    const handleChange = value => {
        setSelectedValue( isArray(value) ? value: selectedValue )
    };

    return (
        <Select
            mode="multiple"
            size="large"
            onChange={handleChange}
            value={selectedValue}
            {...meta}
            {...rest}
        >
            { children }
        </Select>
    )
};

export default MaltySelect;
import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledQuestionItem = styled.li`
	
      width: 100%;
      min-height: 50px;
	  border-radius: 4px;
	  background-color:  ${({ isSelected}) => (isSelected ? 'var(--bgActiveQuestion)' : 'var(--bgQuestion)')};
	  color:  ${({ isSelected}) => (isSelected ? 'var(--white)' : 'var(--primaryGrey)')};
	  align-items: center;
	  padding: 17px 20px 17px 23px;
	  font-family: var(--fontAvenirRoman);
	  font-size: 13px;
	  font-stretch: normal;
	  line-height: 1.38;
	  letter-spacing: normal;
	  text-align: left;  
	  margin-bottom: 10px;
	  max-width: 1034px;
	  cursor: pointer;
	  transition: all 0.25s ease-in;
	  
	  @media ${DEVICE.tablet} {
         font-size: 16px;
         padding: 14px 56px 14px 26px;
      } 
`
export default StyledQuestionItem;
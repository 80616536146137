import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledSessionCard = styled.div`
	position: relative;
	display: inline-block;
	margin-bottom: 30px;
	height: 180px;
	width: 100%;
	
	@media ${DEVICE.laptop} {
        margin-bottom: 15px;  
	}
	img { 
	    max-height: 180px;  
    }

    i {
	  position: absolute;
	  left: 50%;
	  top: 50%;
	  margin-top: -27px;
	  margin-left: -27px;
	  display: flex;
	  justify-content:center;
	  align-items: center;
	  width: 54px; 
	  height: 54px;
	  border-radius: 50%;
	  background: var(--playBtn);
	  
	  &:after {
	      content: '';
	      margin-left: 10%;
		  width: 0; 
		  height: 0; 
		  border-top: 14px solid transparent;
		  border-bottom: 14px solid transparent;
		  border-left: 20px solid var(--white);
	  }
    }
    
`
export default StyledSessionCard;
import React from 'react';

import PopupWrapper from "../../../hoc/Modal/withPopup";
import { POPUPS_ID } from "../../../constants";
import VideoWrapper from './Sessions.VideoWrapper';


const SessionVideo = ({ url, videoId, poster, sessionId }) => {
    return (
        <PopupWrapper
            poster={poster}
            sessionId={sessionId}
            url={url}
            videoId={videoId}
            destroyOnClose
            popupId={`${POPUPS_ID.VIDEO_POPUP}_${videoId}`}
            centered={true}
            component={VideoWrapper}
        />)
};

export default SessionVideo;
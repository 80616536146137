import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { isMobile } from "react-device-detect";

const StyledButton = styled(({ ...props }) => (
	<Button {...props} />
))`
   && {
          width: ${({ size }) => ((size === 'small')? '175px' : '230px')};
		  height: ${({ size }) => ((size === 'small')? '53px' :'60px')};
		  border-radius: 4px;
		  background-color:  ${({ styledtype }) => ((styledtype === 'cancel')? 'var(--cancelBtn)' :'var(--lightOrange)')}; 
		  font-family: var(--fontAvenirHeavy);
		  font-size: 15px;
		  font-weight: 900;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.5;
		  letter-spacing: normal;
		  color: ${({ styledtype }) => ((styledtype === 'cancel')? 'var(--white)' :'var(--primaryGrey)')} ;
		  border: none;
		  ${({ styledtype }) => ((styledtype === 'cancel' && !isMobile)? 'margin-left: 15px' :'')} ;
		  
		  &:hover {
		    border: none;
		  }
		 
    }
    
`
export default StyledButton;

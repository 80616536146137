import styled from 'styled-components'
import { DEVICE } from '../../../../constants/media';

const StyledExpertsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 60px;
    
    @media ${DEVICE.laptop} {
        margin-left: -10px;
	    margin-right: -10px;
    }
    @media ${DEVICE.laptopL} {
    }
`
export default StyledExpertsList;
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import * as firebase from "firebase/app";
import "firebase/analytics";

import { firebaseConfig } from "./config";
import store from "./store";
import { history } from "./routes/urlLocation";
import Loader from "./components/Loader/Loader";

import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import "./main.css";
import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Loader />
      <ScrollToTop />
      <Routes />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

firebase.initializeApp(firebaseConfig);
firebase.analytics();
serviceWorker.unregister();

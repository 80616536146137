import React from "react";
import { Tabs } from "antd";

import { POST_TYPES } from "./Peer2Peer.AddNewContent";
import Styled from "../Styled";
const { TabPane } = Tabs;

const CreatePostIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.248"
    height="18.44"
    viewBox="0 0 15.248 18.44"
  >
    <g
      id="Icon_Create_a_Post"
      data-name="Icon / Create a Post"
      transform="translate(3571.721 -9227.081)"
    >
      <path
        id="Path_449"
        data-name="Path 449"
        d="M-3569.693,9240.363h3.885l7.945-8.234-3.48-3.607-7.678,7.958Z"
        transform="translate(0)"
        fill="none"
        stroke="#173a56"
        stroke-width="2"
      />
      <path
        id="Path_450"
        data-name="Path 450"
        d="M-3563.562,9231.475l3.129,3.177"
        transform="translate(-0.286 -0.088)"
        fill="none"
        stroke="#173a56"
        stroke-width="2"
      />
      <path
        id="Path_451"
        data-name="Path 451"
        d="M-3573.721,9243.883h15"
        transform="translate(2 0.639)"
        fill="none"
        stroke="#173a56"
        stroke-width="2"
      />
    </g>
  </svg>
);

const IconEvent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g
      id="Icon_Event"
      data-name="Icon / Event"
      transform="translate(-374 -2405)"
    >
      <path
        id="Path_431"
        data-name="Path 431"
        d="M2,0H16a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
        transform="translate(375 2408)"
        fill="none"
        stroke="#a9aebe"
        stroke-width="2"
      />
      <path
        id="Path_428"
        data-name="Path 428"
        d="M-4120.234,8354.359h18"
        transform="translate(4495.234 -5941.359)"
        fill="none"
        stroke="#a9aebe"
        stroke-width="2"
      />
      <path
        id="Path_429"
        data-name="Path 429"
        d="M-4116.7,8344.4v6"
        transform="translate(4496.705 -5939.396)"
        fill="none"
        stroke="#a9aebe"
        stroke-width="2"
      />
      <path
        id="Path_430"
        data-name="Path 430"
        d="M-4116.7,8344.4v6"
        transform="translate(4504.705 -5939.396)"
        fill="none"
        stroke="#a9aebe"
        stroke-width="2"
      />
      <path
        id="Path_432"
        data-name="Path 432"
        d="M-4116.745,8356.642h1.946"
        transform="translate(4496 -5940)"
        fill="none"
        stroke="#a9aebe"
        stroke-width="2"
      />
      <path
        id="Path_435"
        data-name="Path 435"
        d="M-4116.745,8356.642h1.946"
        transform="translate(4504 -5940)"
        fill="none"
        stroke="#a9aebe"
        stroke-width="2"
      />
      <path
        id="Path_434"
        data-name="Path 434"
        d="M-4116.745,8356.642h1.946"
        transform="translate(4500 -5940)"
        fill="none"
        stroke="#a9aebe"
        stroke-width="2"
      />
      <path
        id="Path_436"
        data-name="Path 436"
        d="M-4116.745,8356.642h1.946"
        transform="translate(4500 -5937)"
        fill="none"
        stroke="#a9aebe"
        stroke-width="2"
      />
      <path
        id="Path_433"
        data-name="Path 433"
        d="M-4116.745,8356.642h1.946"
        transform="translate(4496 -5937)"
        fill="none"
        stroke="#a9aebe"
        stroke-width="2"
      />
    </g>
  </svg>
);

const Peer2PeerTabs = ({ onChange, selectedType }) => {
  return (
    <Styled.AddNewTabs onChange={onChange} defaultActiveKey={selectedType}>
      <TabPane
        tab={
          <>
            <CreatePostIcon />
            <span>Make a post</span>
          </>
        }
        key={POST_TYPES.POST}
      />
      <TabPane
        tab={
          <>
            <IconEvent />
            <span>Create a news / event</span>
          </>
        }
        key={POST_TYPES.ANNOUNCEMENT}
      />
    </Styled.AddNewTabs>
  );
};

export default Peer2PeerTabs;

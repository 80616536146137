import { createAction } from "redux-actions";

export const GET_EXPERTS = "GET_EXPERTS";
export const GET_EXPERTS_SUCCESS = "GET_EXPERTS_SUCCESS";
export const GET_EXPERTS_FAILURE = "GET_EXPERTS_FAILURE";

export const ASK_EXPERT = "ASK_EXPERT";
export const ASK_EXPERT_SUCCESS = "ASK_EXPERT_SUCCESS";
export const ASK_EXPERT_FAILURE = "ASK_EXPERT_FAILURE";

export const getExpertsAction = createAction(GET_EXPERTS);
export const askExpertAction = createAction(ASK_EXPERT);

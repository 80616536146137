import styled from 'styled-components';
import { DEVICE } from '../../../constants/media';

const StyledInterestList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-left: -10px;
  margin-right: -10px;
  overflow-x: auto;
  flex-wrap: nowrap;
  
   @media ${DEVICE.laptop} {
		margin-left: -10px;
		margin-right: -10px;
   }
  
   @media ${DEVICE.laptopL} {
	    flex-wrap: wrap;
	    max-width: 1450px;
	    margin-left: -17px;
        margin-right: -17px;
   } 
  
`;

export default StyledInterestList;
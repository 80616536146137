import React from 'react';
import { Form } from "antd";
import { connect } from 'react-redux';
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";

import { contactUsAction } from '../../services/contactUs/contactUs.action'
import StyledInput from '../../components/Input/Input';
import TextArea from "../Textarea/Textarea";
import StyledButton from '../../components/Button/Button';

const FormItem = Form.Item;


const ContactUsForm = ({ handleSubmit }) => (
    <form onSubmit={handleSubmit} >
        <h3>Contact Us</h3>
        <FormItem>
            <Field
                component={ StyledInput }
                placeholder='Subject'
                name='subject'
            />
        </FormItem>
        <FormItem>
            <Field
                component={ TextArea }
                autoSize={{ minRows: 5, maxRows: 10 }}
                placeholder="Question details"
                name='content'
            />
        </FormItem>
        <StyledButton
            size="small"
            htmlType={'submit'}
        >
            Send
        </StyledButton>
    </form>
);

export default compose(
    connect(
        () => ({}),
        {
            contactUs: contactUsAction
        }
    ),
    reduxForm({
        form: 'contactUsForm',
	    onSubmit: ({ subject, content }, dispatch, { contactUs }) => {
            contactUs({ subject,
                content });
        }
    }),

)(ContactUsForm)
import React from 'react';
import { Link } from "react-router-dom";

import StyledResultBlock from '../styled/StyledResultBlock';
import StyledResultNav from "../styled/StyledResultNav";
import { urlLocations } from "../../routes/urlLocation";

const PrewebinarExamResultPage = () => (
    <StyledResultBlock>
        <div>
            <h2> Thanks for your Answers</h2>
            <p>
                Thanks for your time answering the Pre-webinar test,
                looking forward to seeing you in the Webinar,
                also you can watch the recorded sessions at any time.
            </p>
            <StyledResultNav>
                <Link to={urlLocations.main} >Back to Main Page</Link>
            </StyledResultNav>
        </div>
    </StyledResultBlock>
);


export default PrewebinarExamResultPage
import React from 'react';
import StyledInput from './styled/StyledInput';

const Input = ({input,...props}) => (
	<StyledInput
		{...input}
		{...props} />
);

export default Input;



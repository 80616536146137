import styled from 'styled-components';
import { DEVICE } from '../../../../constants/media';

const StyledInterest = styled.div`
	    display: inline-block;
		width: 20px;
	    height: 20px;
		border: solid 3px  ${props => props.color};
		position: relative;
		border-radius: 50%;
		cursor: pointer;
		
		 @media ${DEVICE.tablet} {
	        width: 30px;
	        height: 30px;
	        border: solid 4px  ${props => props.color};
	     }
		 
		&:after {
			content:'';
			position: absolute;
			display: inline-block;
			width: 10px;
		    height: 10px;
		    border-radius: 50%;
		    top: 2px;
		    left: 2px;
		    
		    @media ${DEVICE.tablet} {
	          width: 16px;
		      height: 16px;
		      top: 3px;
		      left: 3px;   
	        }
		    opacity: ${({ isActive}) => (isActive ? 1 : 0)};
			background:  ${props => props.color};
	    }	 
`;

export default StyledInterest;
import React from 'react';
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

import Interest from "./Interest/Interest";
import { getUserInterests } from '../../services/user/user.selector';
import StyledInterestList from './styled/StyledInterestList'

const InterestsList = ({ interests }) =>  {
	return (
	    <StyledInterestList>
	        {!!interests && interests.map( interest =>  <Interest key={interest.id} interest={interest} />)}
        </StyledInterestList>)
};

export default connect(createStructuredSelector({
    interests: getUserInterests
}))(InterestsList)